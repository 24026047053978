import React from "react";
import "./styles.scss";
import {
	WhatsappShareButton,
	FacebookShareButton,
	FacebookMessengerShareButton,
	TwitterShareButton,

	WhatsappIcon,
	FacebookIcon,
	FacebookMessengerIcon,
	TwitterIcon,
} from "react-share";

const ShareBtns = ({game, url}) => {
	return (
		<div className="show-raised">
			<div className="title">
				<span>{game?.summary?.money_raised == 0 ? "No plays yet! Start sharing to start raising." : "Start sharing to start raising"}</span>
			</div>
			<div className="socialIconsBox">
				<WhatsappShareButton url={url} title={`#genderrevealgame\nPlay my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
					<WhatsappIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
				</WhatsappShareButton>
				<FacebookShareButton url={url} hashtag={"#genderrevealgame"} quote={`Play my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
					<FacebookIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
				</FacebookShareButton>
				<FacebookMessengerShareButton appId="1111927622279359" url={url} quote={`Play my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
					<FacebookMessengerIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
				</FacebookMessengerShareButton>
				<TwitterShareButton url={url} hashtag={"#genderrevealgame"} title={`#genderrevealgame\nPlay my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
					<TwitterIcon size={"2.5rem"} round={true} />
				</TwitterShareButton>
			</div>


		</div>
	);
};

export default ShareBtns;