import React, { useState } from "react";
import { useSelector } from "react-redux";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import InputField from "../../../../../components/InputField/InputField";
import { getSeletedGame } from "../../../../../shared/redux/game/gameSlice";
import { useNavigate } from "react-router-dom";
import { BaseAPI } from "../../../../../shared/services/BaseAPI";
import useWindowSize from "../../../../../shared/hooks/use-window-size"
import { app } from "../../../../../shared/config";
import "./success.scss";
import { toast } from "react-toastify";
import { ArrowRightOutlined } from '@ant-design/icons';
import {Helmet} from "react-helmet";

const Success = () => {
	const navigate = useNavigate();
	const game = useSelector(getSeletedGame)
	const initialStateData = { email: "", phone: "" };
	const [preferences, setPreferences] = useState(initialStateData);
	const [infoSumbitted, setInfoSubmitted] = useState(false)
	const {width} = useWindowSize()
	const handleSubmit = async (event) => {
		event.preventDefault();

		const payload = {
			contest_id: game?.contest?.id,
			...preferences
		}

		try{
			const response = await BaseAPI.post(`vote/addVoter`, payload)

			if(response.status === 200){
				setInfoSubmitted(true)
			}
		}
		catch(err){
			setInfoSubmitted(true)
		}

	};

	const handleCopyUrl = (url) => {
		if (navigator.clipboard.writeText(url)) {
			return toast.success("Url copied successfully");
		}
		return toast.error("Could not copy URL");
	};

	return (
		<div className="Success container">
			<Helmet>
				<title>{`GRG - Success ${game?.contest?.id}`}</title>
			</Helmet>
			<div className="box">
				<img
					src="../assets/bears.png"
					alt="Bears"
					width="162.7px"
					height="135.5px"
				/>
				{
					infoSumbitted ? 
					<>
					<br/>
					<br/>
					<h1>Thank you!</h1>
					<br/>
					<br/>
					<p>
						Spread the word! Share this game. &nbsp;

						<ButtonDefault className="backToGame" secondary
							onClick={() => handleCopyUrl(`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`)}
							>
							Copy URL {width > 450 && <>&nbsp;<ArrowRightOutlined /></>}
						</ButtonDefault>
					</p>

					
					<br />
					<button className="noThanksBtn" 
						onClick={() => {
							navigate(`/play-game?gameId=${game?.contest?.id}`);
						}}
					>Go back to the game</button>
					<br />
					<ButtonDefault primary className="prefrenceBtn"
						onClick={() => {
							navigate(`/create-game`);
						}}>Create your own game</ButtonDefault>
					</>
					:
					<>
						<h3 style={{fontSize: width < 450 ? "22px" : "28px", textAlign: width < 450 ? "center" : "center", lineHeight: width < 450 ? "30px" : "40px", letterSpacing: width < 450 ? ".5px" : "1px", width: width < 450 ? "100%" : "40%"}}>Share your phone number to receive baby and game updates.</h3>
						<br />
						<br />
						<form onSubmit={handleSubmit}>
							<InputField
								value={preferences.phone}
								type="tel"
								onChange={(val) => {
									setPreferences({ ...preferences, phone: val.value });
								}}
								name="Phone Number"
								required
							/>
							{/* <InputField
								value={preferences.email}
								onChange={(val) => {
									setPreferences({ ...preferences, email: val.value });
								}}
								name="Enter Email Address"
							/> */}
							<br/>
							<ButtonDefault primary className="prefrenceBtn">Count Me In!</ButtonDefault>
							
						</form>
							<br/>
							<button className="noThanksBtn" onClick={()=>setInfoSubmitted(true)}>No Thanks</button>
					</>
				}
				
			</div>
		</div>
	);
};

export default Success;
