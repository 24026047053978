import { BrowserRouter, Routes, Route } from "react-router-dom";
import {FacebookProvider} from "react-facebook/lib";

import Login from "../modules/auth/pages/login";
import NotFound from "../modules/other/pages/notFound";
import Register from "../modules/auth/pages/registerScreen";
import ForgotPassword from "../modules/auth/pages/forgotPassword/forgotPassword";
import CreateNewPassword from "../modules/auth/pages/forgotPassword/createNewPassword";
import Confirmation from "../modules/other/pages/confirmation"
import CreateGame from "../modules/games/host/page/createGame";
import { DashboardRouter } from "./DashboardRouter";
import { PlayGameRouter } from "./PlayGameRouter";
import ProtectedRoute from '../shared/http/protectedRoutes';
import Release from "../modules/other/pages/release";
import LiveGames from "../modules/other/pages/liveGames";
import Predictor from "../modules/other/pages/predictor";
import WebflowPredictor from "../modules/other/pages/webflowPredictor";
// import LegecyPredictor from "../modules/other/pages/predictor/copy";
import PageTracker from "./pageTracker";

export const AppRouter = () => {
  return (
      <FacebookProvider appId="1111927622279359">
        <BrowserRouter>
          <PageTracker />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/create-new-password" element={<CreateNewPassword />} />
            <Route path="/confirmation" element={<ProtectedRoute><Confirmation /></ProtectedRoute>} />
            <Route path="/create-game" element={<ProtectedRoute referrer="create-game"><CreateGame /></ProtectedRoute>} />
            <Route path="/dashboard/*" element={<ProtectedRoute><DashboardRouter /></ProtectedRoute>} />
            <Route path="/live-games" element={<LiveGames />} />

            <Route path="/predictor" element={<Predictor/>}/>
            <Route path="/webflow/predictor" element={<WebflowPredictor/>}/>
            {/* <Route path="legecy/predictor" element={<LegecyPredictor/>}/> */}

            <Route path="/play-game/*" element={<PlayGameRouter />} />
            <Route path="/release" element={<Release/>}/>

            <Route path="/*" element={<NotFound/>}/>
          </Routes>
        </BrowserRouter>
      </FacebookProvider>
  );
};
