
import PropTypes from 'prop-types';

const StepLabel = ({ number, label, active = false, complete, onClick }) => {
  return (
    <button className={"step-label" + (active? ' active' : '')} onClick={ () => { onClick(number) } }>
      <span>{ complete ? <img src={'../assets/check.png'} alt="*" /> : number }</span>
      <label>{ label }</label>
    </button>
  );
};

StepLabel.prototype = {
  number: PropTypes.number,
  label: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default StepLabel;