import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: null,
        refreshToken: null,
    },
    reducers: {
        setAccessToken:(state,action) => {
            state.accessToken = action.payload.accessToken;
        },
        resetAuthState: (state) => {
            state.accessToken = null
            state.refreshToken = null
        },
    },
});


export const getAccessToken = (state) => state.auth.accessToken;
export const getRefreshToken = (state) => state.auth.refreshToken;

export const { setAccessToken, resetAuthState } = authSlice.actions;

export default authSlice.reducer;
