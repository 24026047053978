import "./styles.scss"
import React, {useState, useEffect} from 'react';
import { Form, InputNumber, Button  } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { stopEventPropagation } from "../../../../../shared/utils/helper";

const donationsAmount = [
    {id: 0, value: "10"},
    {id: 1, value: "15"},
    {id: 2, value: "20"},
]
const DonationBtns = ({setDonationMoney, form, donationMoney, required=true, tooltip=false, errorMessage, forEditGame=false}) => {
    const rules = () => {
        const rule = []

        rule.push({
            validator: (_, value) => {
                if (!value && !donationMoney) {
                    return Promise.reject("* Please select minimum donation per guess of gender.")
                }

                return Promise.resolve(true)
            }
        })
        return rule
    }

    return(
        <div className="donationBtnContainer">
            <Form.Item
                label={<><span style={{marginRight: "4px", color: "#ff4d4f", fontSize: "14px", fontFamily: "SimSun, sans-serif"}}>*</span> Donation Per Vote</>}
                name={"donation_per_vote"}
                rules={rules()}
                className="formItemDonationBtn"
                tooltip={tooltip && {
                    title: tooltip,
                    icon: <InfoCircleFilled style={{color: "#463649"}}/>,
                }}
            >
                <InputNumber 
                    min={5} 
                    placeholder={"$ Enter Custom"} 
                    precision={2} 
                    onChange={(v) => {setDonationMoney(null)}}
                    onPressEnter={(e) => stopEventPropagation(e)}
                />
            </Form.Item>
            <div className={classNames({"btnsOfMoney": !forEditGame}, {"btnsOfMoneyForUpdate": forEditGame})}>
                {
                    donationsAmount?.map((d, i) => (
                        <Button 
                            key={i} 
                            className={classNames("donationBtn", {"buttonNotSelected": !(donationMoney?.id == d.id)}, {"buttonSelected": donationMoney?.id == d.id})}
                            onClick={() => {setDonationMoney(d); form.resetFields(["donation_per_vote"])}}
                        >
                            {`$ ${d.value}`}
                        </Button>
                    ))
                }
            </div>
            
        </div>
    );
}

export default DonationBtns;