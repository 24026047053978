class GoogleAnalytic {
    pageview = (url) => {
        if (typeof window !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "GTMevent",
                event_name: "pageview",
                page_path: url
            });
        }
    }

    event = (eventName, params) => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: eventName,
                ...params
            });
        }
    };

    // event = ({ action, params }) => {
    //     if (typeof window !== undefined) {
    //         window.gtag('event', action, params)
    //     }
    // }

    // event = ({ event_name, params }) => {
        // if (typeof window !== undefined) {
        //     window.dataLayer = window.dataLayer || [];
        //     window.dataLayer.push({
        //         event: "GTMevent",
        //         event_name: event_name,
        //         ...params
        //     })
        // }
    // }

    // ecommerceEvent = ({ ecommerce, params }) => {
    //     if (typeof window !== undefined) {
    //         window.dataLayer = window.dataLayer || [];
    //         window.dataLayer.push({
    //             event: "purchase",
    //             ecommerce: ecommerce,
    //             ...params
    //         })
    //     }
    // }
}

export const GoogleAnalytics = new GoogleAnalytic()