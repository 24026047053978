import "./TextAreaField.scss";
import PropTypes from "prop-types";

const TextAreaField = ({
  id,
  name = "",
  value = "",
  placeholder = "",
  onChange,
  rows = "5",
  required = false
}) => {
  if (id === undefined) {
    id = name.replace(/\s/g, "_").toLowerCase();
  }
  const handleChange = (event) => {
    onChange({ id, value: event.target.value});
  };

  return (
    <div className="text-area-field">
      {name && (
        <label htmlFor={id}>
          {name}
          {required && <sup>*</sup>}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        rows={rows}
        required={required}
      ></textarea>
    </div>
  );
};

TextAreaField.prototype = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.string,
};

export default TextAreaField;
