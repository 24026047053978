import classNames from "classnames";

import "./VoteButtons.scss";

const VoteButtons = ({ price, vote, setVote, gender = "girl" }) => {
  const classBtn = classNames({
    VoteButtons: true,
    girl: gender === "girl",
    boy: gender === "boy",
  });

  const addToGender = () => {
    setVote(vote + 1);
  };

  const subtractToGender = () => {
    if (vote > 1) {
      setVote(vote - 1);
    }
  };

  return (
    <div className={classBtn}>
      <button className="minus" disabled={vote === 1} onClick={subtractToGender}>
        -
      </button>
      <button className="vote">
        <span className="vote-number">{vote}</span>
        <label>Play</label>
        <small>${price * vote}</small>
      </button>
      <button className="plus" onClick={addToGender}>
        +
      </button>
    </div>
  );
};

export default VoteButtons;
