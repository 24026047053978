import React, { useState, useEffect } from 'react';
import Layout from '../../../../../shared/components/layout';
import { Col, Row, Typography } from 'antd';
import Card from "../../../../../shared/components/card";
import GenderCard from "../../components/genderCard";
import CashOutCard from "../../components/cashOutCard";
import VotingDetailsCard from "../../components/votingDetails";
import ShareGame from "../../../shared/components/shareGame";
import './endGame.scss';
import { useSelector } from 'react-redux';
import { getSeletedGame, setSeletedGame } from '../../../../../shared/redux/game/gameSlice';
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useWindowSize from "../../../../../shared/hooks/use-window-size";
import { BaseAPI } from '../../../../../shared/services/BaseAPI';
import {Helmet} from "react-helmet";

const { Title, Paragraph } = Typography;

const EndGame = () => {
	const dispatch = useDispatch()
	const game = useSelector(getSeletedGame)
	const [isLoading, setIsLoading] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const { width } = useWindowSize()
	const launchAt = new Date('2022-11-01')

	const handleChooseGender = async (gender) => {
		setIsModalVisible(false)
		setIsLoading(true)

		const payload = {
			baby_gender: gender
		}
		try{
			const response = await BaseAPI.post(`game/winner/${game?.contest?.id}`, payload)

			if (response.status === 200) {
				const res = await BaseAPI.get(`game/${game?.contest?.id}`)
				if(res.status === 200){
					dispatch(setSeletedGame({ selectedGame: res?.data?.data }))
				}
			}
		}
		catch(err){
			toast.error("There is an error. Please try later.", { position: "top-center" })
		}
		setIsLoading(false)
	}


	// const endGame = async () => {
	// 	try{
	// 		const res = await BaseAPI.get(`game/end/${game?.contest?.id}`)
	// 		if(res.status === 200){
	// 			toast.success("Contest ended successfully, Now, no more votes can be cast.", { position: "top-center" })
	// 		}
	// 	}
	// 	catch(err){
	// 	}
	// }

	// useEffect(() => {
	// 	if (!game?.contest?.voting_ended) {
	// 		endGame()
	// 	}
	// }, [])

	return (
		<>
			<Layout>
				<Helmet>
					<title>{`GRG - End Game`}</title>
				</Helmet>
				<Card>
					<Row>
						<Col span={24} className="endGameContainer">
							<Row justify="space-between" align="middle">
								<Col><Title className="heading">End Game & Cash Out</Title></Col>
							</Row>
							<Row justify="space-between" align="middle">
								<Col><Paragraph className="subHeading">Select the gender of the child to complete the game{width <= 450 ? " " : <br />}and determine the winner</Paragraph></Col>
								{/* <Col><ButtonDefault className="deleteBtn">Delete Game</ButtonDefault></Col> */}
							</Row>

							<Row gutter={[24, 8]} >
								<Col xs={24} sm={24} md={8} lg={8} xl={8}>
									<GenderCard
										game={game}
										handleChooseGender={handleChooseGender}
										isVisible={isModalVisible}
										setIsVisible={setIsModalVisible}
									/>
								</Col>
								<Col xs={24} sm={24} md={16} lg={16} xl={16}>
									<CashOutCard
										game={game}
										isLoading={isLoading}
									/>
								</Col>
							</Row>

							<Row gutter={[24, 8]}>
								{
									new Date(game?.contest?.created_at) < launchAt ?
									game?.vote_counts?.length != 0 &&
									<Col xs={24} sm={24} md={16} lg={16} xl={16}>
										<VotingDetailsCard game={game} />
									</Col>
									:
									<Col xs={24} sm={24} md={16} lg={16} xl={16}>
										<VotingDetailsCard game={game} />
									</Col>
								}
								<Col xs={24} sm={24} md={8} lg={8} xl={8}>
									<Card className="shareGame">
										<ShareGame
											game={game}
											title="Other"
											fromEndGame={true}
										/>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>

				</Card>
			</Layout>
		</>
	)
};

export default EndGame;