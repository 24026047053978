import React from 'react';
import { Row, Col, Alert } from 'antd';
import "./banner.scss";

const Banner = ({type, title, description, action, closable= false}) => {
    return(
        <>
            <Row>
                <Col span={24} className="bannerContainer">
                    <Alert 
                        message={title}
                        description={description}
                        type={type} 
                        banner 
                        closable={closable}
                        action={
                            action && action
                        }
                        className="banner"
                        />
                </Col>
            </Row>
        </>
    )
}


export default Banner;