import "./styles.scss"
import React from 'react';
import { Form, Input  } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const CustomInput = ({label, name, placeholder, minLength=false, tooltip=false, required=false, errorMessage}) => {
    
    const rules = () => {
        const rule = []

        if(required){
            rule.push({ required: true, message: errorMessage })
        }

        if(minLength){
            rule.push({
                validator: (_, value) => {
                    if (!value) {
                        return Promise.reject(false)
                    }
                    if (isNaN(value) || value.trim() === "") {
                        return Promise.reject('Phone number must be numeric.')
                    }
                    if ((value.trim()).length < minLength) {
                        return Promise.reject(`Phone number length must greater than ${minLength-1}.`)
                    }
                    return Promise.resolve(true)
                }
            })
        }

        return rule
    }

    return(
        <Form.Item
            label={label}
            name={name}
            rules={rules()}
            className="formItem"
            tooltip={tooltip && {
                title: tooltip,
                icon: <InfoCircleFilled style={{color: "#463649"}}/>,
            }}
        >
            <Input className='input' placeholder={placeholder}/>
        </Form.Item>
    );
}

export default CustomInput;