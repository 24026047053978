import React from 'react'
import PropTypes from 'prop-types';

const BackgroundHearts = ({
  className = "bg",
  bg = "#FCE4F9",
  hearts = "#E9C6FC",
  children,
}) => {
  return (
    <div
      className={className}
      style={{ backgroundColor: bg, minHeight: "100vh", overflow: "hidden" }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 381 270.93333"
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        <g id="layer1" transform="translate(26.974242,24.438835)">
          <g
            clipPath="url(#clip0_80_2905)"
            id="g1022"
            transform="matrix(0.26458333,0,0,0.26458333,-19.414718,-19.759591)"
          >
            <g opacity="0.7" id="g900">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 631.19,164.72 c -1.988,0 -5.964,0 -7.952,-4.18 1.988,0 3.976,4.18 5.964,0 1.988,0 3.976,0 5.964,0 1.988,0 3.976,0 5.964,0 0,-4.18 0,-4.18 0,-8.36 1.989,0 3.977,0 5.965,-4.18 0,0 0,0 1.988,0 -1.988,0 -3.976,4.18 -1.988,8.36 0,4.18 1.988,4.18 3.976,4.18 -3.976,0 -5.964,-4.18 -9.941,-4.18 -1.988,0 -3.976,0 -5.964,0 0,0 0,0 1.988,0 -3.976,8.36 -5.964,4.18 -5.964,8.36 z"
                fill={hearts}
                id="path4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 593.416,160.543 c 1.989,0 5.965,0 7.953,0 h -1.988 c 1.988,0 3.976,4.18 5.964,4.18 1.988,0 3.976,0 5.964,0 3.977,0 7.953,-4.18 11.929,-4.18 1.988,0 1.988,0 1.988,-4.18 l -1.988,4.18 c -3.976,0 -7.952,0 -9.941,0 -1.988,0 -3.976,0 -5.964,0 -5.964,0 -13.916,-4.18 -19.881,4.18 h -1.988 c -7.952,4.181 -13.917,0 -21.869,-4.18 -1.988,0 -3.976,0 -3.976,4.18 -1.988,-4.18 -5.964,-4.18 -7.952,0 -1.989,0 -3.977,4.181 -3.977,8.361 1.988,-4.18 0,-8.361 -1.988,-8.361 -1.988,0 -3.976,0 -5.964,0 0,0 -1.988,0 -1.988,-4.18 1.988,0 5.964,0 5.964,-4.18 1.988,4.18 1.988,4.18 3.976,4.18 1.988,0 3.977,0 3.977,-4.18 h 1.988 l 1.988,4.18 c 1.988,0 3.976,0 5.964,-4.18 0,0 -1.988,4.18 0,4.18 3.976,-4.18 7.952,4.18 13.917,4.18 h 1.988 c 0,0 0,0 0,4.181 3.976,-4.181 9.94,-8.361 15.904,-8.361 z"
                fill={hearts}
                id="path6"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 661.011,164.722 c -1.988,0 -5.964,0 -7.952,0 1.988,0 3.976,0 7.952,0 z"
                fill={hearts}
                id="path8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -86.5117,302.664 h -1.9881 c 0,0 0,0 -1.9881,0 -1.9881,4.181 -3.9762,4.181 -3.9762,4.181 -1.988,0 -1.988,0 -3.9761,0 -1.9878,0 -1.9878,0 -3.9758,0 0,0 0,0 -1.989,0 -1.988,0 -5.964,0 -7.952,0 0,0 0,0 -1.988,0 h 1.988 c 0,-4.181 0,-4.181 0,-4.181 0,0 1.988,0 1.988,-4.18 0,0 0,0 0,-4.18 0,0 0,0 -1.988,0 h -3.976 c 0,0 0,0 0,4.18 0,0 0,0 -1.988,0 h -1.988 c -7.953,8.361 -9.941,8.361 -9.941,8.361 1.988,0 5.965,0 5.965,-4.181 0,-4.18 -1.989,-4.18 -1.989,-4.18 h -1.988 c -1.988,0 -1.988,0 -3.976,0 h 1.988 c 1.988,0 5.965,0 7.953,0 h 1.988 1.988 c 1.988,0 3.976,0 5.964,0 0,0 0,0 0,4.18 0,0 0,0 0,4.181 h 5.964 c 0,0 1.988,0 3.977,0 0,-4.181 0,-4.181 -1.989,-4.181 0,0 0,0 1.989,0 h 1.988 c 0,0 1.9877,0 3.9758,0 h 1.9881 c 0,0 0,0 1.9881,0 h -1.9881 -1.9881 c 0,0 1e-4,4.181 1.9881,4.181 0,0 0,0 1.9881,0 1.9881,0 3.9762,0 3.9762,0 h 1.9881 c 0,0 1.9881,0 3.9762,0 v -4.181 c 0,0 0,0 0,4.181 -5.9643,-4.181 -3.9762,-4.181 -3.9762,-4.181 z"
                fill={hearts}
                id="path10"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -110.368,265.045 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path12"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -116.332,265.047 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path14"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -92.476,260.866 c -1.9881,0 -1.9881,0 -3.9762,0 -1.9881,0 -1.9881,0 -3.9758,0 -1.988,0 -3.977,0 -7.953,4.18 1.988,0 1.988,0 3.976,0 1.989,0 1.989,0 3.977,0 3.9758,-4.18 5.9639,-4.18 7.952,-4.18 z"
                fill={hearts}
                id="path16"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -88.4992,265.045 c -1.9881,0 -3.9762,0 -5.9643,0 h -1.9881 c 1.9881,0 5.9643,0 7.9524,0 z"
                fill={hearts}
                id="path18"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -122.297,273.405 c 3.976,0 5.964,0 7.952,0 l -3.976,8.361 c 0,0 0,0 -1.988,4.18 0,0 0,0 -1.988,0 0,0 1.988,0 1.988,-4.18 0,0 0,0 1.988,0 h 3.976 c 0,0 0,0 0,4.18 0,0 0,0 0,4.18 0,4.18 0,4.18 0,8.36 v -4.18 -4.18 c 0,0 0,-4.18 1.988,-4.18 0,0 0,4.18 1.989,4.18 0,0 0,4.18 1.988,4.18 l -1.988,-4.18 c 0,0 0,0 0,-4.18 0,0 0,0 0,4.18 l -1.989,-4.18 c 0,4.18 1.989,4.18 1.989,8.36 0,0 0,-4.18 -1.989,-4.18 0,0 0,0 0,4.18 0,0 0,0 0,4.18 0,0 0,4.18 -1.988,4.18 h -1.988 c 0,0 0,0 -1.988,0 1.988,0 1.988,-4.18 1.988,-4.18 l 1.988,-4.18 c 0,0 0,0 0,4.18 v -4.18 h 1.988 c 0,0 0,0 1.989,0 h 1.988 c 0,0 0,0 -1.988,0 0,0 0,0 -1.989,0 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 0,0 0,0 1.988,0 h -1.988 -1.988 c -1.988,-25.081 -1.988,-25.081 -3.976,-20.901 0,0 -1.988,0 -3.976,0 0,0 1.988,0 3.976,0 z m 5.964,0 c 1.988,-4.18 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path20"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -110.368,265.045 c 0,4.18 0,4.18 0,0 1.988,4.18 1.988,8.361 3.976,8.361 0,0 0,0 -1.988,-4.181 0,4.181 -1.988,0 -1.988,-4.18 z"
                fill={hearts}
                id="path22"
              />
              <path
                d="m -102.416,252.503 c -1.989,0 -3.977,0 -3.977,0 -1.988,0 -1.988,4.18 -1.988,4.18 0,0 0,0 0,4.18 0,0 0,0 0,-4.18 v 4.18 l 1.988,-4.18 c 0,-4.18 1.988,-4.18 3.977,-4.18 z"
                fill={hearts}
                id="path24"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -102.417,265.045 c 1.988,0 3.9764,0 5.9645,0 h -1.9881 c -3.9764,0 -3.9764,0 -3.9764,0 z"
                fill={hearts}
                id="path26"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -128.262,281.765 c 0,0 1.988,0 1.988,-4.18 0,0 1.988,0 1.988,-4.181 1.988,0 1.988,-4.18 3.977,-4.18 h 1.988 1.988 c 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 h -1.989 c -3.976,12.541 -3.976,12.541 -5.964,12.541 z"
                fill={hearts}
                id="path28"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -110.368,273.404 c 0,4.18 0,4.18 1.988,4.18 0,0 0,0 -1.988,-4.18 z"
                fill={hearts}
                id="path30"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -130.249,265.045 c 3.976,0 5.964,0 7.952,0 1.988,0 3.976,0 5.964,0 -1.988,0 -1.988,0 -3.976,0 -1.988,4.18 -5.964,0 -9.94,0 z"
                fill={hearts}
                id="path32"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 497.988,336.107 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path34"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 499.976,327.749 c 0,-4.18 -1.988,-4.18 0,0 z"
                fill={hearts}
                id="path36"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 497.988,323.565 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path38"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 492.024,319.386 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path40"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 499.977,311.026 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path42"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 505.94,306.846 c -1.988,0 -1.988,4.181 -1.988,4.181 0,0 1.988,0 1.988,-4.181 -1.988,4.181 0,4.181 0,0 0,4.181 0,4.181 0,0 z"
                fill={hearts}
                id="path44"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 509.917,327.749 c -1.988,0 -1.988,0 0,0 -1.988,0 -1.988,0 -1.988,0 0,0 0,0 -1.988,0 0,0 0,0 -1.989,0 h -1.988 c 0,0 0,0 -1.988,0 0,0 0,0 -1.988,-4.18 0,0 0,0 1.988,0 0,0 0,0 1.988,0 0,0 0,0 1.988,0 h 1.989 1.988 c 0,0 -1.988,0 -1.988,4.18 0,0 0,0 1.988,0 z M 496,323.568 c 0,-4.18 0,-4.18 0,0 -1.988,-4.18 0,-4.18 0,0 z m 9.941,0.001 c -1.988,0 -1.988,0 0,0 z m -7.953,0 c 0,4.18 0,4.18 0,0 z m 0,0 c 0,4.18 0,4.18 0,0 z m 9.941,0 c 0,4.18 0,4.18 0,0 z m -7.953,0 c -1.988,0 -1.988,0 0,0 z m 1.988,0 c -1.988,0 -1.988,0 0,0 z m 3.977,-4.18 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path46"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 507.928,336.107 c -1.988,0 -1.988,-4.18 -1.988,-4.18 0,4.18 0,4.18 1.988,4.18 -1.988,-4.18 -1.988,-4.18 -1.988,-4.18 0,0 0,0 -1.988,-4.18 0,0 0,0 1.988,0 0,8.36 0,8.36 1.988,8.36 z"
                fill={hearts}
                id="path48"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 505.94,336.106 c -1.988,0 -1.988,-4.18 0,0 -1.988,0 -1.988,-4.18 -1.988,-4.18 0,4.18 0,4.18 1.988,4.18 z"
                fill={hearts}
                id="path50"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 499.977,331.926 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path52"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 497.988,331.927 c 0,-4.18 -1.988,-4.18 0,0 -1.988,-4.18 -1.988,-4.18 0,0 -1.988,-4.18 0,-4.18 0,0 0,-4.18 0,-4.18 0,0 -1.988,-4.18 -1.988,-4.18 0,0 z"
                fill={hearts}
                id="path54"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 494.012,323.568 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path56"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 497.988,319.389 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path58"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 501.964,319.386 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path60"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 496,319.386 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path62"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 494.012,315.206 -1.988,-4.18 c 1.988,4.18 1.988,4.18 1.988,4.18 z"
                fill={hearts}
                id="path64"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 497.988,311.026 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path66"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 499.977,327.747 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path68"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 499.976,319.389 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path70"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -138.202,745.761 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path72"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,741.58 c 0,4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path74"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,741.58 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path76"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -134.226,741.577 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path78"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -154.107,741.577 c -1.988,0 -1.988,0 -1.988,0 0,0 0,0 -1.988,0 0,-4.18 1.988,-4.18 3.976,0 -3.976,0 -5.965,0 -5.965,0 0,0 0,0 -1.988,0 h 1.988 1.989 c 1.988,-4.18 3.976,-4.18 3.976,0 z"
                fill={hearts}
                id="path80"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -140.19,737.4 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path82"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -138.202,737.4 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path84"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -146.154,733.216 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path86"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -140.191,733.216 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path88"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,733.216 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path90"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -144.166,733.218 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path92"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -144.166,733.218 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path94"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -146.154,729.037 c 0,4.181 0,4.181 0,0 z"
                fill={hearts}
                id="path96"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -158.083,729.037 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path98"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -150.13,729.037 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path100"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -154.107,729.037 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path102"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -152.118,729.037 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path104"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -156.094,729.039 c 1.988,-4.18 0,-4.18 0,0 1.988,-4.18 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path106"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -152.119,729.039 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path108"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -158.083,720.679 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path110"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -144.166,720.679 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path112"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -126.273,720.679 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path114"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -154.107,720.679 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path116"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -126.273,716.5 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path118"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -156.094,716.5 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path120"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -128.262,716.5 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path122"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -138.202,708.137 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path124"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -162.06,708.137 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path126"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -140.19,708.137 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path128"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -140.19,703.958 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path130"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -142.179,699.778 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path132"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -146.154,699.776 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path134"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -134.226,691.416 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path136"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -124.285,678.878 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path138"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,745.759 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path140"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,741.58 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path142"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -142.179,741.58 c -1.988,0 -1.988,0 -3.976,0 h -1.988 c 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 h 1.988 c 1.988,0 3.976,0 7.952,0 -1.988,0 -1.988,0 0,0 z m -3.976,0 h 1.988 c -1.988,0 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path144"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -146.154,741.58 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path146"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -140.191,741.58 c -1.988,0 -1.988,0 0,0 -1.988,0 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path148"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -140.19,733.219 c -1.988,-4.18 0,-4.18 0,0 -1.988,-4.18 -1.988,-4.18 0,0 -1.988,-4.18 0,-4.181 0,-4.181 -1.988,0 -1.988,10e-4 0,4.181 -1.988,-4.18 -1.988,-4.181 -1.988,-4.181 0,4.181 0,4.181 1.988,4.181 0,0 0,0 0,-4.181 0,0 0,10e-4 0,4.181 l 1.988,-0.001 c 0,0 0,0.001 0,-4.18 l 3.976,10e-4 1.988,-10e-4 h 1.988 c 0,0 0,0 -1.988,0 0,0 0,10e-4 -1.988,10e-4 0,0 0,0 0,4.18 0,0 0,0 0,4.18 0,0 0,0 -1.988,0 l 1.988,4.18 c 0,0 0,0 -1.988,0 0,0 0,0 1.988,0 v 4.18 c 0,0 0,0 0,4.18 -3.976,-20.901 -3.976,-20.9 -5.964,-16.72 1.988,-4.18 1.988,-4.18 0,0 1.988,0 1.988,4.18 0,0 1.988,4.18 1.988,4.18 1.988,4.18 -1.988,0 -1.988,0 -1.988,-4.18 z m 0,4.18 c 0,-4.18 0,-4.18 0,0 z m 0,0 c 1.988,4.18 1.988,0 0,0 0,-4.18 0,-4.18 0,0 z m 1.988,-4.181 c 0,4.18 0,4.18 0,0 z m -3.976,0.001 c -1.989,-4.18 -1.989,-4.18 0,0 z m 1.988,-4.181 c -1.988,0 -1.988,0 0,0 z m -3.977,-4.18 c 0,4.18 0,4.18 0,0 z m 3.977,4.18 c 0,-4.18 0,-4.18 0,0 z m -1.988,-4.18 c -1.989,0 -1.989,0 0,0 z m 3.976,-8.36 c -1.988,-4.18 -1.988,-4.18 0,0 -1.988,0 -1.988,-4.18 0,0 z m -5.965,0 c -1.988,0 -1.988,0 0,0 z m 3.977,0 c 1.988,0 1.988,0 0,0 z m 5.964,-4.18 c 1.988,4.18 1.988,0 0,0 1.988,4.18 1.988,4.18 0,0 1.988,0 1.988,0 1.988,0 0,4.18 0,4.18 -1.988,0 1.988,0 1.988,0 0,0 z m 0,0 c 1.988,4.18 1.988,4.18 0,0 1.988,4.18 1.988,0 0,0 z m 0,0 c 1.988,0 1.988,0 0,0 z m -1.988,0 c 1.988,0 1.988,0 0,0 -1.988,0 -1.988,0 -1.988,0 z m 0,0 h 1.988 c -1.988,0 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path150"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -144.167,741.58 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path152"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -146.154,741.58 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path154"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -136.214,737.4 c -1.988,0 -1.988,0 -1.988,0 1.988,4.18 1.988,0 1.988,0 z"
                fill={hearts}
                id="path156"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -136.213,741.581 c 1.988,0 1.988,0 1.988,0 1.988,0 1.987,0 3.976,-4.18 -3.977,0 -3.976,-10e-4 -5.964,4.18 1.988,-4.181 1.988,-4.181 0,0 z"
                fill={hearts}
                id="path158"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -152.119,729.038 c 1.988,-4.18 1.988,-4.18 0,0 1.988,-4.18 1.988,-4.18 1.988,-4.18 l -1.988,4.18 c 1.988,0 1.988,0 0,0 1.988,-4.18 1.988,-4.18 0,0 -1.988,0 -1.988,0 0,0 1.988,0 1.988,0 0,0 1.988,4.18 1.988,4.18 1.988,4.18 0,-4.18 0,-4.18 -1.988,-4.18 1.988,4.18 1.988,4.18 1.988,4.18 0,-4.18 0,-4.18 -1.988,-4.18 1.988,4.18 1.988,4.18 1.988,4.18 h 1.989 c 0,0 0,0 -1.989,0 0,0 0,0 1.989,0 v 4.181 c 0,0 0,0 0,-4.181 0,0 0,0 -1.989,0 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 0,0 0,-4.18 1.988,-4.18 -1.988,0 -1.988,0 0,0 -1.988,4.18 -1.988,4.18 -1.988,-10e-4 0,0 0,10e-4 1.988,10e-4 z m 3.977,4.18 c -1.989,0 -1.989,0 0,0 z m -3.977,0 c 0,-4.18 0,-4.18 0,0 z m 1.988,0 c -1.988,-4.18 -1.988,0 0,0 -1.988,-4.18 -1.988,-4.18 0,0 z m -1.988,-4.18 c 0,4.18 0,4.18 0,0 z m 0,0 c -1.988,0 -1.988,0 0,0 z m 1.988,-4.18 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path160"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -158.083,737.4 h -1.988 c 0,0 0,0 1.988,0 z"
                fill={hearts}
                id="path162"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -160.071,737.397 c 0,-4.181 0,-4.181 0,0 z m 0,-4.181 c 0,4.181 0,4.181 0,0 z"
                fill={hearts}
                id="path164"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -138.202,733.218 c 1.988,-4.18 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path166"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -132.238,733.218 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path168"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -136.213,729.037 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path170"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.142,729.038 c 0,-4.18 0,-4.18 0,0 0,-4.18 -0.001,-4.179 -0.001,-8.359 l 0.001,4.179 c 1.988,0 1.988,0 0,4.18 1.988,-4.18 1.988,-4.18 0,0 1.988,-4.18 1.988,-4.179 1.988,-4.179 0,0 0,4.179 -1.988,4.179 1.988,0 1.988,0 0,0 z m 1.988,-4.179 c -1.988,0 -1.988,0 0,0 z m 0,-4.18 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path172"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -158.083,720.679 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path174"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -156.095,720.679 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path176"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -156.095,720.679 c -1.988,0 -1.988,10e-4 -1.988,10e-4 0,0 0,-4.18 -1.988,-4.18 0,0 0,0 0,4.18 0,0 0,0 0,4.18 1.988,-4.18 1.988,-8.361 3.976,-4.181 -1.988,0 -1.988,0 0,0 0,-4.18 0,-4.18 0,0 1.989,-4.18 1.989,-4.18 0,0 1.989,0 1.988,10e-4 1.988,10e-4 0,-4.18 0.001,-4.181 -1.988,-10e-4 1.989,0 1.988,10e-4 1.988,10e-4 l -1.988,-10e-4 c 1.989,0 1.988,10e-4 1.988,10e-4 -1.988,0 -1.988,-10e-4 -1.988,-10e-4 z"
                fill={hearts}
                id="path178"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -152.118,720.679 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path180"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -156.095,712.317 c 0,-4.18 0,-4.18 0,0 -1.988,0 -1.988,0 -1.988,0 1.988,-4.18 1.988,-4.18 1.988,0 -1.988,0 -1.988,0 -1.988,0 h -1.988 c 0,0 0,0 1.988,0 h 1.988 c -1.988,-4.18 0,-4.18 0,0 0,-4.18 0,-4.18 0,0 0,-4.18 1.988,-4.18 0,0 1.988,-4.18 1.988,-4.18 0,0 z m 1.988,-4.18 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path182"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -162.06,708.138 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path184"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -138.202,708.138 c -1.988,0 -1.988,0 0,0 -1.988,0 -1.988,-4.18 0,0 z"
                fill={hearts}
                id="path186"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -162.06,703.959 c 0,-4.181 0,-4.181 0,-4.181 z"
                fill={hearts}
                id="path188"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -152.118,703.959 c -1.988,0 -1.988,0 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path190"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -138.202,703.958 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path192"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -138.202,703.959 c -1.988,0 -1.988,0 0,0 -1.988,4.181 -1.988,4.181 -1.988,4.181 0,-4.181 0,-4.181 1.988,-4.181 -1.988,0 -1.988,0 0,0 1.988,-4.18 1.988,-4.18 0,0 1.988,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path194"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -140.19,699.775 c 1.988,-4.18 0,-4.18 0,0 1.988,-4.18 1.988,-4.18 1.988,-4.18 0,-4.18 0,0 -1.988,4.18 1.988,0 1.988,-4.18 1.988,-4.18 0,0 0,0 -1.988,4.18 1.988,0 1.988,0 0,0 0,4.18 0,4.18 0,0 0,4.18 -1.988,4.18 0,0 -1.988,4.18 -1.988,4.18 0,0 0,4.18 -1.988,4.18 0,0 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path196"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -120.309,703.958 c 1.988,0 0,-4.18 0,0 z"
                fill={hearts}
                id="path198"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,699.779 c -1.988,0 -1.988,0 -1.988,0 0,0 0,0 -1.988,0 1.988,-4.18 1.988,-4.18 3.976,0 -1.988,0 -1.988,0 0,0 z m 0,0 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path200"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,699.776 c 0,-4.18 0,-4.18 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path202"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -160.071,695.599 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path204"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -158.083,691.415 c -1.988,4.18 -1.988,0 0,0 -1.988,4.18 -1.988,4.18 0,0 z"
                fill={hearts}
                id="path206"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -154.107,691.416 c -1.988,0 -1.988,0 0,0 -1.988,0 -1.988,-4.18 0,0 z"
                fill={hearts}
                id="path208"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -116.333,691.416 c -1.988,0 -1.988,0 0,0 -1.988,-4.18 -1.988,0 0,0 -1.988,-4.18 -1.988,-4.18 0,0 0,-4.18 0,-4.18 0,-4.18 -1.988,0 -1.988,0 0,4.18 z"
                fill={hearts}
                id="path210"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -136.213,691.417 c 1.988,-4.18 0,-4.18 0,0 1.988,-4.18 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path212"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -134.226,691.416 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path214"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -120.309,683.057 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path216"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -122.298,683.059 c 0,-4.18 0,-4.18 0,0 1.988,-4.18 0,-4.18 0,0 1.988,-4.18 1.988,-4.18 0,0 0,-4.18 0,-4.18 0,0 1.988,-4.18 1.988,-4.18 0,0 1.988,-4.18 1.988,0 0,0 1.988,0 1.988,0 0,0 z m 1.988,-10e-4 c -1.988,0 -1.988,0 0,0 z m 0,0 c -1.988,0 -1.988,0 0,0 z m -1.988,-4.18 c 1.988,4.18 1.988,4.18 0,0 1.988,4.18 1.988,0 0,0 z m 0,4.181 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path218"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -120.309,678.878 c 1.988,4.18 1.988,4.18 0,0 1.988,4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path220"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -154.107,724.858 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path222"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -154.107,724.86 c 1.988,-4.18 1.988,-4.18 0,0 1.988,-4.18 0,-4.18 0,0 0,-4.18 0,-4.18 0,0 1.988,0 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path224"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -154.107,708.137 c 1.989,0 1.988,0 1.988,0 z"
                fill={hearts}
                id="path226"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -162.06,708.138 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path228"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -144.166,699.779 c 0,-4.18 0,-4.18 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path230"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -148.143,699.776 c -1.988,-4.18 0,-4.18 0,0 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path232"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 376.715,461.509 c 1.988,0 0,-4.18 0,0 z"
                fill={hearts}
                id="path234"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 376.715,461.511 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path236"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 372.738,474.051 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path238"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 372.738,448.971 c 0,4.181 0,4.181 0,0 z"
                fill={hearts}
                id="path240"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 368.762,474.051 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path242"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 364.786,465.693 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path244"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 362.798,453.151 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path246"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 380.69,478.23 c -1.988,0 -1.988,0 0,0 1.989,0 1.989,0 0,0 z"
                fill={hearts}
                id="path248"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 378.702,465.69 c 0,4.18 0,4.18 0,0 0,4.18 -1.988,4.18 -1.988,4.18 h -1.988 c 0,0 0,0 -1.988,0 0,0 0,0 1.988,0 0,0 0,0 -1.988,0 0,0 0,0 1.988,0 v 4.18 c 0,0 0,0 1.988,0 0,0 0,0 0,-4.18 0,0 0,0 1.988,0 h -1.988 c 0,-8.36 0,-8.36 1.988,-4.18 -1.988,-4.18 -1.988,-4.18 0,0 -1.988,-4.18 -1.988,-4.18 -1.988,-4.18 0,4.18 0,0 1.988,4.18 -1.988,-4.18 -1.988,-4.18 -1.988,0 0,0 0,0 1.988,0 -1.988,0 -1.988,0 0,0 0,-4.18 1.988,-4.18 0,0 0,-4.18 0,-4.18 0,0 z m 0,4.18 c 0,-4.18 0,-4.18 0,0 -1.988,0 -1.988,0 0,0 z m -1.988,0 c 1.988,0 1.988,0 0,0 z m -1.988,0 c 0,4.18 0,4.18 0,0 1.988,0 1.988,0 0,0 z m 0,-4.18 c 0,4.18 0,4.18 0,0 z m 0,-4.18 c 1.988,0 1.988,0 0,0 z m -5.964,8.36 c 1.988,0 1.988,0 0,0 z m 0,0 c 0,4.18 1.988,4.18 0,0 z"
                fill={hearts}
                id="path250"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 376.714,478.23 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path252"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 372.738,453.151 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path254"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 374.727,478.23 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path256"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 370.75,457.33 c -1.988,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path258"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 374.727,474.051 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path260"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 605.346,611.997 c 0,4.18 1.988,0 0,0 0,4.18 -1.988,4.18 0,0 z"
                fill={hearts}
                id="path262"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 603.357,637.077 h -1.988 c -1.988,0 -3.976,0 -5.964,-4.18 1.988,0 3.976,0 7.952,4.18 z"
                fill={hearts}
                id="path264"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 587.453,628.715 c -3.976,0 -5.964,-10e-4 -7.952,-4.181 1.988,4.18 5.964,4.181 7.952,4.181 z"
                fill={hearts}
                id="path266"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 597.393,649.614 c -1.988,0 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path268"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 589.441,649.614 c 0,0 -1.988,0 -3.976,0 1.988,0 1.988,0 3.976,0 z"
                fill={hearts}
                id="path270"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 585.465,649.614 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path272"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 575.524,641.256 c 0,0 -1.988,0 -3.976,0 1.988,0 1.988,0 3.976,0 z"
                fill={hearts}
                id="path274"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 571.548,637.077 c 0,0 -3.976,4.18 -5.964,4.18 0,-4.18 1.988,-4.18 5.964,-4.18 z"
                fill={hearts}
                id="path276"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 561.607,632.898 c -1.988,0 1.988,0 1.988,0 1.988,-4.18 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path278"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 559.619,624.534 c 0,4.18 0,4.18 0,0 0,4.18 -1.988,4.18 0,0 z"
                fill={hearts}
                id="path280"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 561.608,637.077 c 1.988,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path282"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 551.667,624.534 c -1.988,0 -1.988,0 0,0 -1.988,0 -3.977,0 0,0 z"
                fill={hearts}
                id="path284"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 551.666,628.713 c -1.988,0 -5.964,0 -7.952,0 3.976,0 5.964,0 7.952,0 z"
                fill={hearts}
                id="path286"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 543.714,628.715 h -1.988 c -1.988,-4.18 0,-4.18 1.988,0 z"
                fill={hearts}
                id="path288"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 533.774,620.355 c 1.988,0 1.988,0 0,0 0,4.18 0,4.18 -1.988,4.18 0,0 0,0 0,4.18 0,-4.18 -3.976,-8.36 1.988,-8.36 z"
                fill={hearts}
                id="path290"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 517.869,599.455 c -1.988,0 1.988,0 0,0 -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path292"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 519.858,607.813 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path294"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 527.81,616.176 c 0,4.18 1.988,4.18 0,0 0,4.18 -1.989,4.18 0,0 z"
                fill={hearts}
                id="path296"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 533.774,628.715 c -1.988,0 1.988,-10e-4 1.988,-4.181 1.989,0 0,0 1.989,0 -1.989,4.18 -1.989,4.181 -3.977,4.181 z"
                fill={hearts}
                id="path298"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 519.858,607.813 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path300"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 533.774,624.534 c 0,4.18 -1.988,4.18 0,0 -1.988,4.18 -1.988,4.18 0,0 -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path302"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 533.773,637.077 c 0,4.18 1.988,4.18 0,0 z"
                fill={hearts}
                id="path304"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 593.417,645.436 c -1.988,0 -1.988,0 -3.976,0 1.988,0 3.976,0 7.952,-4.18 0,4.18 -1.988,4.18 -3.976,4.18 z"
                fill={hearts}
                id="path306"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 561.607,620.357 c 0,0 -1.988,0 -3.976,0 1.988,-4.18 3.976,-4.18 3.976,0 z"
                fill={hearts}
                id="path308"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 529.798,603.635 c 0,0 1.988,-4.18 3.976,-4.18 -1.988,4.18 -3.976,4.18 -3.976,4.18 z"
                fill={hearts}
                id="path310"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 525.822,611.997 c -1.989,4.18 -1.989,0 0,0 -1.989,0 -3.977,4.18 0,0 z"
                fill={hearts}
                id="path312"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 539.739,637.077 h -1.988 c -1.988,0 0,0 1.988,0 z"
                fill={hearts}
                id="path314"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 569.56,607.813 c -1.988,4.18 -1.988,0 0,0 0,4.18 -3.976,4.18 -1.988,4.18 -3.977,0 -7.953,0 -11.929,0 0,0 0,0 0,-4.18 1.988,0 1.988,0 1.988,0 1.988,0 3.976,0 7.953,0 0,0 1.988,0 3.976,0 z"
                fill={hearts}
                id="path316"
              />
              <path
                d="m 319.059,465.693 c 5.965,0 13.917,0 21.869,0 5.965,0 19.881,4.18 25.846,0 0,0 -17.893,0 -19.881,0 h 1.988 c -9.941,-4.18 -19.881,0 -29.822,0 z"
                fill={hearts}
                id="path318"
              />
              <path
                d="m 90.4287,273.404 c -11.9286,0 -461.2377,8.36 0,0 z"
                fill={hearts}
                id="path320"
              />
              <path
                d="m 213.691,628.713 c -13.917,0 -463.226,8.361 0,0 z"
                fill={hearts}
                id="path322"
              />
              <path
                d="m -92.4763,311.026 c -23.8567,0 -45.7257,0 -69.5837,0 19.881,0 198.8098,4.18 212.7265,0 -3.9762,0 -133.2023,0 -143.1428,0 -25.8457,0 3.9762,0 0,0 z"
                fill={hearts}
                id="path324"
              />
              <path
                d="m 521.845,616.176 c -25.845,0 1.988,0 0,0 z"
                fill={hearts}
                id="path326"
              />
              <path
                d="m 452.262,616.176 c 17.893,0 178.928,0 208.75,0 -21.869,0 -131.215,0 -139.167,0 -23.857,0 -47.714,0 -69.583,0 z"
                fill={hearts}
                id="path328"
              />
              <path
                d="m -120.309,273.405 c 0,-4.181 -7.952,0 -7.952,0 0,0 -7.953,0 -5.965,0 -3.976,0 -7.952,-4.181 -9.94,-4.181 0,4.181 5.964,4.181 9.94,4.181 5.965,0 -15.904,0 3.977,0 0,4.181 7.952,0 9.94,0 -1.988,4.18 0,4.18 0,0 0,0 1.988,4.18 1.988,0 -1.988,0 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path330"
              />
              <path
                d="m 221.643,766.66 c 11.928,0 21.869,0 33.797,0 h 3.976 c 1.988,0 -1.988,0 -3.976,0 0,0 5.964,0 7.953,0 3.976,0 11.928,0 15.904,0 1.988,0 1.988,0 1.988,4.18 1.988,-4.18 7.953,0 9.941,0 1.988,0 3.976,0 1.988,0 h 1.988 c -1.988,0 -1.988,0 -3.976,0 h 1.988 c 5.964,0 11.929,0 15.905,0 0,0 3.976,0 1.988,0 5.964,0 15.905,0 23.857,0 1.988,0 1.988,0 1.988,0 1.988,0 0,0 1.988,0 23.857,0 49.703,0 73.56,0 3.976,0 7.952,0 13.916,0 5.965,0 13.917,0 19.881,0 13.917,0 29.822,0 43.738,0 1.988,0 1.988,0 3.976,0 23.858,0 47.715,0 71.572,0 1.988,0 0,0 1.988,0 23.857,0 47.714,0 71.571,0 5.965,0 11.929,0 19.881,0 v -4.18 c -1.988,0 -3.976,0 -5.964,0 -5.964,0 -11.929,0 -17.893,0 h -1.988 c -9.94,0 -21.869,0 -33.798,0 0,0 0,0 -1.988,0 -5.964,0 -15.904,0 -19.881,0 0,0 -1.988,0 -3.976,0 -1.988,0 -5.964,0 -7.952,0 1.988,0 -3.976,0 -3.976,0 -19.881,0 -37.774,0 -57.655,0 13.917,0 27.833,0 41.75,0 h 1.988 c -17.893,0 -37.774,0 -55.667,0 -1.988,0 -7.952,0 -9.94,0 1.988,0 5.964,0 7.952,0 -3.976,0 -15.904,0 -15.904,0 -3.977,0 -5.965,0 -7.953,0 1.988,0 -1.988,0 0,0 -13.916,0 -29.821,0 -45.726,0 13.917,0 27.833,0 39.762,0 1.988,0 1.988,0 3.976,0 -3.976,0 -11.929,0 -11.929,0 3.977,0 7.953,0 13.917,0 -5.964,0 -13.917,0 -21.869,0 1.988,0 5.964,0 7.952,0 -3.976,0 -7.952,0 -11.928,0 -7.952,0 -15.905,-4.18 -23.857,0 -5.964,0 -11.929,0 -19.881,0 -3.976,0 -9.941,0 -13.917,0 -11.928,0 -21.869,0 -33.797,0 -9.941,0 -19.881,0 -29.822,0 -3.976,0 -9.94,0 -13.916,0 -7.953,0 -15.905,0 -25.846,0 -1.988,0 -3.976,0 -3.976,0 -9.94,0 -21.869,0 -33.797,0 -7.953,0 -15.905,0 -25.846,0 5.965,0 5.965,0 5.965,0 z"
                fill={hearts}
                id="path332"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 464.19,770.839 c 1.988,0 3.976,0 3.976,0 -1.988,0 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path334"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 486.059,770.839 c -1.988,0 -1.988,0 -1.988,0 -1.988,0 -3.977,0 -5.965,0 h -1.988 c 1.988,0 1.988,0 3.977,0 -3.977,0 -7.953,0 -11.929,0 h -1.988 c 1.988,0 3.976,0 5.964,0 1.988,0 5.964,0 7.953,0 5.964,0 9.94,0 13.916,0 0,0 0,0 1.988,0 -1.988,0 -3.976,0 -3.976,0 1.988,0 3.976,0 5.964,0 5.965,0 13.917,0 19.881,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 -7.952,4.18 -9.94,0 1.988,0 3.976,0 5.964,0 -3.976,0 -9.941,0 -13.917,0 1.988,0 3.976,0 3.976,0 0,0 1.989,-4.18 1.989,0 -1.989,4.18 -3.977,0 -5.965,0 -1.988,0 -3.976,0 -5.964,0 -1.988,4.18 -1.988,0 -3.976,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path336"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 44.7027,770.84 c 0,-4.18 1.9881,-4.18 0,0 3.9762,-4.18 7.9524,0 11.9286,0 -1.9881,0 -3.9762,0 -3.9762,0 -3.9762,0 -5.9643,0 -7.9524,0 z"
                fill={hearts}
                id="path338"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 50.6661,766.659 h -1.9882 c 0,0 0,4.181 1.9882,0 z"
                fill={hearts}
                id="path340"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 160.012,770.839 c 1.988,0 1.988,0 3.976,0 0,0 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path342"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -32.8335,770.839 c 1.9881,0 1.9881,0 3.9762,0 0,0 -1.9881,0 -3.9762,0 z"
                fill={hearts}
                id="path344"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 175.917,770.839 h 1.988 c -1.988,0 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path346"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 78.4995,766.659 c 0,0 1.9881,0 3.9762,0 -1.9881,0 -1.9881,0 -3.9762,0 z"
                fill={hearts}
                id="path348"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -3.0119,770.839 c 1.98807,0 0,-4.18 0,0 1.98807,0 1.98807,0 0,0 z"
                fill={hearts}
                id="path350"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 183.869,770.839 h 1.988 c 0,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path352"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 82.4757,766.659 c 1.9881,0 5.9644,-4.179 7.9525,10e-4 H 86.452 c 0,0 -1.9882,-10e-4 -3.9763,-10e-4 z"
                fill={hearts}
                id="path354"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -1.02446,766.66 c 3.9762,-4.18 7.95241,-4.18 11.92856,-4.18 -1.98807,0 -5.96428,0 -7.95238,4.18 h 1.98811 c -1.98809,4.181 -3.976192,0 -5.96429,0 z"
                fill={hearts}
                id="path356"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 16.8689,770.839 c 1.9881,0 3.9762,0 7.9524,0 -1.9881,0 -3.9762,0 -7.9524,0 z"
                fill={hearts}
                id="path358"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 203.75,766.659 c 1.988,0 1.988,0 0,0 0,4.181 1.988,4.181 0,0 z"
                fill={hearts}
                id="path360"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 106.333,766.659 c 3.976,0 7.953,0 11.929,0 -1.988,0 -5.964,0 -7.953,0 -1.988,0 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path362"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 211.702,770.839 c 1.988,0 1.988,-4.18 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path364"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 144.107,766.66 c -1.988,0 -3.976,0 -7.952,0 -1.988,0 -5.964,0 -5.964,-4.18 3.976,4.18 7.952,4.18 13.916,4.18 z"
                fill={hearts}
                id="path366"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 233.572,770.839 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path368"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 237.547,775.02 c 0,-4.18 0,-4.18 0,0 0,-4.18 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path370"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 380.69,770.84 c 5.964,-4.181 11.929,-4.181 17.893,-4.181 0,0 -1.988,0 -1.988,4.181 h -1.988 c -1.988,0 -3.977,0 -7.953,0 0,0 -3.976,0 -5.964,0 z"
                fill={hearts}
                id="path372"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 394.607,766.659 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path374"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 406.535,775.018 c 0,4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path376"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 400.571,762.477 c 0,-4.18 0,-4.18 0,0 1.988,0 0,-4.18 0,0 z"
                fill={hearts}
                id="path378"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 400.571,762.476 c 1.988,-4.18 1.988,0 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path380"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 406.535,758.296 c 1.988,0 0,0 1.988,0 0,4.18 -1.988,4.18 -1.988,0 z"
                fill={hearts}
                id="path382"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 408.524,762.476 c 0,-4.18 1.988,0 1.988,0 0,0 0,4.18 1.988,4.18 0,-4.18 -1.988,-8.36 -3.976,-4.18 z"
                fill={hearts}
                id="path384"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 418.464,775.018 c 0,0 1.988,-4.18 1.988,0 0,0 0,-4.18 -1.988,0 z"
                fill={hearts}
                id="path386"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 420.452,770.839 c 1.988,0 1.988,0 3.976,0 0,0 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path388"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 422.441,762.48 h 1.988 c 0,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path390"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 428.405,770.839 h 1.988 c 0,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path392"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 428.405,766.659 c 1.988,0 3.976,0 3.976,4.181 l -1.988,-10e-4 c 0,-4.18 0,10e-4 -1.988,-4.18 z"
                fill={hearts}
                id="path394"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 434.369,770.839 c 1.988,0 3.976,0 5.964,0 0,0 0,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 z"
                fill={hearts}
                id="path396"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 430.392,749.938 c 1.988,0 1.988,0 0,0 1.988,4.18 1.988,4.18 0,0 z"
                fill={hearts}
                id="path398"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 446.297,770.839 c 1.988,0 1.988,0 1.988,0 z"
                fill={hearts}
                id="path400"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 450.273,775.02 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path402"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 470.154,800.097 c 0,0 3.976,0 1.988,4.181 0,0 -3.976,0 -1.988,-4.181 z"
                fill={hearts}
                id="path404"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 382.678,766.66 c -1.988,-4.18 -1.988,0 0,0 z"
                fill={hearts}
                id="path406"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 384.667,758.296 c 0,4.18 -1.988,4.18 0,0 z"
                fill={hearts}
                id="path408"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 382.678,762.476 c -1.988,0 -1.988,0 -1.988,0 1.988,-4.18 1.988,0 1.988,0 z"
                fill={hearts}
                id="path410"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 380.69,762.48 c -1.988,0 -1.988,4.18 -3.976,0 0,0 0,0 1.988,0 z"
                fill={hearts}
                id="path412"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 460.214,770.839 c 1.988,0 1.988,0 3.976,0 -1.988,0 -3.976,0 -3.976,0 z"
                fill={hearts}
                id="path414"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 378.702,762.48 c 0,0 -1.988,0 -3.976,0 1.988,0 1.988,0 3.976,0 z"
                fill={hearts}
                id="path416"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 466.178,770.839 c 1.988,0 0,-4.18 0,0 z"
                fill={hearts}
                id="path418"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 372.738,766.659 c -1.988,0 -7.952,0 -9.94,0 1.988,0 5.964,0 9.94,0 z"
                fill={hearts}
                id="path420"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 474.131,770.839 c 1.988,0 1.987,0 3.975,0 0,0 -1.987,0 -3.975,0 z"
                fill={hearts}
                id="path422"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 362.798,770.84 c -3.976,0 -5.965,0 -9.941,0 1.988,-4.18 5.965,0 9.941,0 -1.988,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path424"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 356.834,766.659 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path426"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 358.821,762.48 c 0,0 -1.988,0 -3.976,0 0,0 1.988,0 3.976,0 z"
                fill={hearts}
                id="path428"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 472.143,749.939 c 7.952,-4.18 13.917,-4.18 21.869,-4.18 1.988,0 3.976,0 5.964,0 1.988,0 3.977,0 5.965,0 3.976,0 5.964,0 7.952,0 -1.988,0 -5.964,0 -7.952,0 -1.988,0 -5.965,0 -7.953,0 -1.988,0 -3.976,0 -5.964,0 -5.964,0 -9.941,4.18 -15.905,4.18 -1.988,0 -1.988,0 -3.976,0 3.976,-4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path430"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 344.904,770.841 c -1.988,0 -3.976,0 -3.976,0 l -1.988,-10e-4 c 0,0 0,0 -1.988,0 -1.988,0 -3.976,10e-4 -5.965,10e-4 0,0 -1.988,0 -3.976,0 0,0 0,0 1.988,0 1.988,0 3.977,-10e-4 5.965,-10e-4 1.988,0 1.988,0 3.976,0 1.988,-4.18 3.976,-4.18 5.964,10e-4 1.988,0 1.988,0 1.988,0 0,4.18 3.976,0 3.976,0 h -1.988 c -1.988,0 -3.976,0 -5.964,0 0,0 0,0 1.988,0 z"
                fill={hearts}
                id="path432"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 394.607,703.958 c -1.988,4.18 -5.965,4.18 -7.953,4.18 -1.988,0 -3.976,4.18 -5.964,4.18 0,-4.18 3.976,-4.18 3.976,-8.36 3.976,0 5.964,-4.18 9.941,0 z"
                fill={hearts}
                id="path434"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 348.88,762.477 h -1.988 c 0,-4.18 1.988,-4.18 1.988,0 z"
                fill={hearts}
                id="path436"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 356.833,745.76 c -1.988,0 -3.976,0 -5.964,-4.18 -3.977,4.18 -5.965,4.18 -7.953,8.36 0,0 0,0 0,4.18 h 1.988 c -1.988,4.18 -1.988,0 -3.976,0 0,0 1.988,0 1.988,-4.18 h -1.988 c 3.976,-4.18 7.952,-4.18 11.929,-8.36 0,4.18 1.988,4.18 3.976,4.18 z"
                fill={hearts}
                id="path438"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 376.714,716.496 c 1.988,0 1.988,-4.18 3.976,-4.18 -1.988,0 -3.976,4.18 -3.976,4.18 z"
                fill={hearts}
                id="path440"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 501.964,770.839 c 0,4.18 1.988,4.18 0,0 z"
                fill={hearts}
                id="path442"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 501.964,770.839 c 1.989,0 1.989,0 0,0 z"
                fill={hearts}
                id="path444"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 350.869,745.756 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path446"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 505.94,766.659 c 1.988,0 1.988,0 1.988,0 0,0 0,4.181 -1.988,0 z"
                fill={hearts}
                id="path448"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 334.964,762.476 c 0,-4.18 -1.988,0 0,0 z"
                fill={hearts}
                id="path450"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 507.928,766.659 c 1.989,0 5.965,0 7.953,0 -1.988,0 -3.976,0 -7.953,0 z"
                fill={hearts}
                id="path452"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 315.083,783.381 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path454"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 285.262,812.64 c -1.988,4.18 -3.976,4.18 -5.964,4.18 0,0 3.976,-4.18 5.964,-4.18 z"
                fill={hearts}
                id="path456"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 277.309,821 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path458"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 462.202,666.336 c 0,-4.18 -1.988,0 0,0 z"
                fill={hearts}
                id="path460"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 277.309,812.641 c 0,-4.18 1.988,-4.18 1.988,-4.18 z"
                fill={hearts}
                id="path462"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 297.19,783.378 c 0,-4.18 0,-4.18 0,0 -1.988,-4.18 0,-4.18 0,-8.36 1.988,0 0,4.18 0,8.36 z"
                fill={hearts}
                id="path464"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 281.285,808.458 c 0,-4.18 0,-4.18 0,0 0,-4.18 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path466"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 497.988,716.496 c 1.988,0 3.976,-4.181 5.964,0 -1.988,4.18 -3.976,0 -5.964,0 z"
                fill={hearts}
                id="path468"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 175.917,745.759 c 0,4.18 1.988,4.18 0,0 z"
                fill={hearts}
                id="path470"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 569.559,816.821 c -1.988,-4.18 -1.988,-4.18 0,0 -1.988,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path472"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 472.143,666.336 h 1.988 c 0,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path474"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 567.571,804.277 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path476"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 527.809,745.759 c 5.965,0 13.917,4.18 19.881,8.36 -5.964,0 -9.94,-4.18 -13.916,-4.18 -1.988,0 -3.976,-4.18 -5.965,-4.18 z"
                fill={hearts}
                id="path478"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 543.714,766.659 c 1.988,0 5.964,0 7.952,0 -3.976,0 -5.964,0 -7.952,0 z"
                fill={hearts}
                id="path480"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 561.607,783.381 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path482"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 265.381,795.92 c 0,-4.18 0,-4.18 0,0 1.988,0 1.988,-4.18 0,0 z"
                fill={hearts}
                id="path484"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -78.56,762.48 h 1.9881 1.9881 c -1.9881,0 -1.9881,0 -3.9762,0 z"
                fill={hearts}
                id="path486"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 553.654,762.48 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path488"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 563.595,779.197 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path490"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 547.69,754.117 c 1.988,0 3.977,0 7.953,4.18 -1.988,0 -3.976,-4.18 -7.953,-4.18 z"
                fill={hearts}
                id="path492"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 565.583,775.018 c 1.988,0 1.988,0 1.988,0 0,4.18 0,0 -1.988,0 z"
                fill={hearts}
                id="path494"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -76.5718,766.659 c 1.9881,0 1.9881,0 0,0 z"
                fill={hearts}
                id="path496"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 567.571,770.839 c 1.988,0 1.988,0 3.976,0 h -1.988 c 0,0 -1.988,4.18 -1.988,0 z"
                fill={hearts}
                id="path498"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -72.5953,770.84 c 1.9881,0 5.9643,0 5.9643,-4.181 -1.9881,0 -3.9762,0 -5.9643,4.181 0,-4.181 0,-4.181 0,-4.181 0,0 0,0 1.9881,0 1.9881,0 3.9762,0 5.9643,0 1.9881,0 1.9881,4.181 3.9762,4.181 -1.9881,0 -3.9762,0 -5.9643,0 0,0 -1.9881,4.18 0,4.18 -1.9881,0 -1.9881,0 -1.9881,-4.18 -1.9881,0 -1.9881,0 -3.9762,0 z"
                fill={hearts}
                id="path500"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -136.215,762.48 c -3.976,0 -1.988,0 0,0 -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path502"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 346.892,649.615 1.988,-4.18 c 0,4.18 -1.988,4.18 -1.988,4.18 z"
                fill={hearts}
                id="path504"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 255.44,770.84 c -1.988,0 -1.988,0 0,0 0,-4.18 -1.988,-4.18 0,0 z"
                fill={hearts}
                id="path506"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 251.464,770.839 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path508"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 259.417,754.118 c -1.989,0 -1.989,-4.18 -3.977,-4.18 h 1.988 c 1.989,4.18 1.989,4.18 1.989,4.18 z"
                fill={hearts}
                id="path510"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -172,762.48 h 1.988 c 0,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path512"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 239.536,758.297 c 3.976,-4.18 5.964,0 7.952,0 0,0 0,0 -1.988,0 0,0 0,0 0,4.18 0,0 -1.988,-4.18 -5.964,-4.18 z"
                fill={hearts}
                id="path514"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 243.512,758.296 1.988,10e-4 c 0,0 0,-10e-4 -1.988,-10e-4 z"
                fill={hearts}
                id="path516"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 589.44,770.841 c 1.988,-4.18 5.964,-4.181 7.952,-8.361 0,8.361 -5.964,4.181 -7.952,8.361 z"
                fill={hearts}
                id="path518"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 601.369,770.84 c 1.988,0 1.988,0 3.976,-4.181 1.988,0 3.977,0 3.977,0 -1.989,0 -1.989,4.181 -3.977,4.181 0,-4.181 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path520"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 599.38,766.66 c 1.989,0 1.989,0 0,0 z"
                fill={hearts}
                id="path522"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 601.369,766.657 c 1.988,0 3.976,0 3.976,0 1.988,0 3.977,0 5.965,-4.18 1.988,0 3.976,0 5.964,0 3.976,0 7.952,-10e-4 11.929,-4.181 -3.977,0 -7.953,4.181 -11.929,4.181 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -3.977,0 -5.965,0 0,4.18 -1.988,4.18 -3.976,4.18 z"
                fill={hearts}
                id="path524"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 251.464,724.858 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path526"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 599.38,758.298 c 0,-4.18 0,-4.18 0,0 1.989,-4.18 0,-4.18 0,0 1.989,0 1.989,0 0,0 z"
                fill={hearts}
                id="path528"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 581.488,716.496 h 1.988 c 0,0 -1.988,-4.181 -1.988,0 z"
                fill={hearts}
                id="path530"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 625.225,766.66 c 0,0 1.988,-4.18 3.977,-4.18 -1.989,4.18 -3.977,4.18 -3.977,4.18 z"
                fill={hearts}
                id="path532"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 601.369,737.396 c 0,0 3.976,0 5.964,0 -3.976,0 -3.976,0 -5.964,0 z"
                fill={hearts}
                id="path534"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 595.404,729.039 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path536"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 629.202,762.477 c 1.988,0 5.964,-4.181 7.952,-4.181 -1.988,4.18 -5.964,4.181 -7.952,4.181 z"
                fill={hearts}
                id="path538"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 595.405,720.679 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path540"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 601.369,729.039 v -4.18 c 0,0 1.988,0 1.988,-4.18 0,0 0,0 0,4.18 -3.976,0 -1.988,4.18 -1.988,4.18 z"
                fill={hearts}
                id="path542"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 581.488,699.779 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path544"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 617.274,754.118 c 1.988,-4.18 3.976,0 7.952,0 1.988,0 1.988,0 3.976,0 h 1.988 c -1.988,0 -3.976,0 -5.964,0 -1.988,0 -3.976,0 -3.976,0 -1.988,0 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path546"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 639.142,766.659 h 1.988 c 0,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path548"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -108.381,791.739 h 1.988 c 0,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path550"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 639.143,741.58 c -1.988,0 -3.976,4.18 -5.965,4.18 1.989,-4.18 3.977,-4.18 5.965,-4.18 z"
                fill={hearts}
                id="path552"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 629.202,741.576 c 0,-4.18 3.976,0 5.964,0 -1.988,0 -3.976,-4.18 -5.964,0 z"
                fill={hearts}
                id="path554"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -116.334,766.656 c 1.989,-4.18 0,-4.18 0,0 1.989,0 1.989,-4.18 0,0 z"
                fill={hearts}
                id="path556"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -106.393,779.197 c 1.988,0 0,-4.18 0,0 z"
                fill={hearts}
                id="path558"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -144.167,716.495 c -1.988,0 0,4.18 0,0 z"
                fill={hearts}
                id="path560"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 605.345,703.958 c -1.988,0 0,4.18 0,0 z"
                fill={hearts}
                id="path562"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 605.344,708.139 c 1.989,-4.18 1.989,-4.18 0,0 1.989,0 1.989,0 0,0 z"
                fill={hearts}
                id="path564"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -108.381,762.48 c 0,4.18 0,4.18 0,0 -1.988,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path566"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 150.071,770.839 c 1.989,0 5.965,0 7.953,0 h 1.988 c -3.976,0 -7.952,0 -9.941,0 z"
                fill={hearts}
                id="path568"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 148.083,766.656 c 7.952,-4.18 15.905,-4.18 23.857,-4.18 7.952,0 15.905,0 23.857,0 7.952,-4.18 15.905,0 23.857,0 h 1.988 c 1.988,0 1.988,0 3.976,0 9.941,0 19.881,0 29.822,0 3.976,0 7.952,0 11.928,0 -23.857,4.18 -45.726,0 -67.595,0 h -1.988 c -9.94,4.18 -19.881,0 -31.809,0 -7.953,4.18 -13.917,4.18 -17.893,4.18 z"
                fill={hearts}
                id="path570"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 392.619,749.939 c 0,0 1.988,0 1.988,-4.18 0,4.18 1.988,4.18 3.977,0 5.964,4.18 13.916,0 19.881,4.18 h -1.988 c 3.976,0 7.952,4.18 9.94,0 h 1.988 c -3.976,0 -9.941,0 -13.917,0 H 412.5 c -1.988,0 -3.976,0 -5.964,0 -3.976,0 -5.964,-4.18 -9.94,0 0,0 1.988,0 1.988,4.18 0,4.18 -1.989,-4.18 -5.965,-4.18 z"
                fill={hearts}
                id="path572"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 603.356,695.596 c -1.988,4.18 -1.988,4.181 -1.988,4.181 1.988,0 1.988,-0.001 1.988,-4.181 0,4.18 0,4.181 0,8.361 0,0 0,4.18 1.988,4.18 0,0 -1.988,0 0,4.18 0,4.18 0,8.36 -1.988,12.54 -1.988,4.18 0,4.18 -1.988,8.361 0,0 0,0 0,-4.181 0,0 1.988,0 1.988,4.181 -1.988,0 -1.988,0 -1.988,4.18 h 1.988 c 0,0 0,-4.18 1.988,-4.18 -1.988,4.18 0,8.36 -1.988,12.54 0,4.18 0,4.18 0,8.36 1.988,4.18 1.988,4.18 1.988,8.36 0,0 0,-4.18 -1.988,-4.18 -1.988,0 -3.976,4.18 -5.964,0 v -4.18 c 0,-4.18 0,-4.18 1.988,-8.36 0,4.18 0,4.18 0,8.36 0,0 0,0 -1.988,0 0,4.18 3.976,4.18 5.964,4.18 -1.988,0 0,-4.18 -1.988,-8.36 0,-4.18 0,-4.18 0,-8.36 0,-4.18 0,-4.18 1.988,-8.36 0,0 0,0 1.988,0 0,-4.181 1.989,-12.541 0,-16.721 -1.988,0 0,-4.18 -1.988,-4.18 h -1.988 c 0,0 0,0 0,-4.18 h 1.988 c 0,0 0,0 0,4.18 0,-8.36 1.988,-16.721 1.988,-20.901 1.989,0 0,-4.18 1.988,-4.18 -5.964,4.18 -3.976,4.18 -3.976,8.36 z"
                fill={hearts}
                id="path574"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 599.381,741.58 c -1.988,0 -1.988,0 0,0 -1.988,4.18 -1.988,4.18 -1.988,8.36 0,-4.18 1.988,-8.36 1.988,-8.36 z"
                fill={hearts}
                id="path576"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 595.404,741.58 c 0,4.18 -1.988,0 0,0 z"
                fill={hearts}
                id="path578"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 597.393,737.396 c 0,0 -1.988,4.18 0,4.18 1.988,0 1.988,-4.18 0,-4.18 z"
                fill={hearts}
                id="path580"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 597.393,720.679 c 0,4.18 0,4.18 0,0 0,4.18 1.988,4.18 0,0 z"
                fill={hearts}
                id="path582"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 599.38,716.495 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path584"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 601.368,699.779 c 0,4.18 -1.988,4.18 0,8.36 0,-4.18 0,-4.18 0,-8.36 z"
                fill={hearts}
                id="path586"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 599.38,699.779 c -1.988,4.18 0,4.18 0,0 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path588"
              />
              <path
                d="m 573.535,770.838 c 1.988,0 5.964,0 7.952,0 1.988,0 3.977,0 5.965,0 3.976,0 9.94,0 13.916,0 1.988,0 3.976,0 3.976,0 3.977,0 5.965,0 9.941,0 h 1.988 c 1.988,0 3.976,0 5.964,4.18 h 1.988 v 4.18 c 1.989,0 0,4.18 1.988,4.18 v -4.18 c 0,0 0.001,0 -1.988,-4.18 3.977,0 1.989,8.36 0,12.54 v 4.18 c 1.989,-8.36 1.989,-20.9 5.965,-25.08 0,-4.18 1.988,-4.18 3.976,-4.18 1.988,0 3.976,0 5.964,0 5.964,0 13.917,0 19.881,0 v -4.181 c 0,0 0,0 -1.988,0 0,0 0,0 1.988,0 -3.976,0 -9.94,4.181 -13.917,0 0,0 0,0 1.989,0 h 1.988 c 1.988,0 3.976,10e-4 7.952,-4.18 -3.976,0 -9.94,0 -13.917,4.18 h 1.988 c 0,0 0,0 -1.988,0 1.988,4.181 5.965,4.181 9.941,4.181 -9.941,0 -19.881,0 -27.834,0 -1.988,0 -1.988,4.18 -3.976,4.18 -1.988,0 -5.964,0 -7.952,0 -1.988,0 -5.964,0 -7.952,0 0,0 0,0 -1.989,0 3.977,4.18 7.953,0 9.941,0 -1.988,0 -5.964,0 -7.952,4.18 -1.989,0 -5.965,0 -7.953,0 -1.988,0 -3.976,0 -3.976,-4.18 0,4.18 -1.988,4.18 -3.976,4.18 -1.988,0 -3.976,0 -5.964,0 -1.989,0 -3.977,0 -5.965,0 -1.988,0 -3.976,0 -5.964,0 0,-4.18 1.988,-4.18 1.988,-4.18 -1.988,4.18 -5.964,4.18 -9.94,0 1.988,4.18 5.964,4.18 7.952,4.18 z"
                fill={hearts}
                id="path590"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 621.249,779.198 c 1.988,0 3.976,8.36 7.953,8.36 -3.977,-4.18 -5.965,-8.36 -7.953,-8.36 -1.988,-4.18 -3.976,-4.18 -5.964,-4.18 1.988,4.18 3.976,0 5.964,4.18 z"
                fill={hearts}
                id="path592"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 623.237,775.018 c -1.988,0 -1.988,0 -3.976,0 1.988,-4.18 1.988,0 3.976,0 z"
                fill={hearts}
                id="path594"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 56.6309,766.66 c 1.9881,-4.18 3.9765,-10e-4 5.9646,-10e-4 5.9642,0 9.9397,0 15.904,0 -3.9762,0 -9.9401,10e-4 -15.9044,4.181 -1.9881,0 -3.9761,-4.18 -5.9642,-4.18 z"
                fill={hearts}
                id="path596"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -12.9522,766.66 c -3.9762,0 -5.9643,4.181 -7.9524,0 0,0 0,0 -1.9881,0 -1.9881,0 -3.9762,0 -5.9643,0 3.9762,-4.18 7.9524,0 9.9405,-4.18 3.9762,4.18 5.9643,4.18 5.9643,4.18 z"
                fill={hearts}
                id="path598"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 191.821,766.659 c 1.988,0 1.988,0 1.988,0 0,4.181 -1.988,4.181 -1.988,0 z"
                fill={hearts}
                id="path600"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 6.9287,766.66 c 1.98809,0 1.98808,0 1.98808,0 3.97622,0 7.95242,0 11.92862,0 1.9881,0 3.9761,0 5.9642,0 5.9643,-4.18 11.9286,0 17.8929,0 -3.9762,0 -7.9524,0 -9.9405,0 1.9881,4.18 3.9762,0 7.9524,0 -5.9643,4.18 -11.9286,0 -17.8929,0 -1.988,-4.18 -3.9761,0 -7.9523,0 -1.9881,0 -5.9643,0 -7.95242,0 0,4.18 1e-5,4.18 -1.98808,0 z"
                fill={hearts}
                id="path602"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 26.8092,766.659 c -1.9881,0 -1.9881,0 0,0 z"
                fill={hearts}
                id="path604"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 28.7981,766.659 c -1.9881,0 -1.9885,10e-4 -3.9766,10e-4 0,0 1.9885,-10e-4 3.9766,-10e-4 z"
                fill={hearts}
                id="path606"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 12.893,770.839 h -1.9881 c 0,0 1.9881,0 3.9762,0 0,0 0,0 -1.9881,0 z"
                fill={hearts}
                id="path608"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 92.4164,766.659 c 1.988,0 3.9761,0 5.9642,0 0,4.181 3.9764,0 3.9764,0 h 1.988 c -1.988,0 -3.976,0 -3.976,0 0,0 -3.9765,0 -7.9526,0 z"
                fill={hearts}
                id="path610"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 120.25,766.659 c 1.988,0 3.976,0 5.964,0 -3.976,0 -5.964,0 -5.964,0 z"
                fill={hearts}
                id="path612"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 211.702,745.759 h 1.988 c 0,4.18 0,4.18 -1.988,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path614"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -102.417,766.659 c 3.9764,0 7.9526,0 11.9288,0 -1.9881,0 -5.9643,0 -7.9524,0 0,0 -1.9884,0 -3.9764,0 z"
                fill={hearts}
                id="path616"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 402.559,766.656 c -1.988,0 -1.988,0 -3.976,0 5.964,0 11.929,-4.18 17.893,0 1.988,0 3.976,0 5.964,0 -1.988,0 -3.976,0 -3.976,0 -3.976,0 -7.952,0 -11.928,0 h 1.988 c -1.988,-4.18 -3.976,-4.18 -5.965,0 z"
                fill={hearts}
                id="path618"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 408.524,779.197 h 1.988 c 1.988,0 3.976,0 5.964,0 -1.988,0 -3.976,4.18 -3.976,4.18 0,-4.18 -1.988,-4.18 -3.976,-4.18 z"
                fill={hearts}
                id="path620"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 440.333,770.839 c 1.988,0 1.988,0 3.976,0 -1.988,4.18 -3.976,4.18 -3.976,0 z"
                fill={hearts}
                id="path622"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 440.333,770.839 h 1.988 c 1.988,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path624"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 450.273,770.839 c 3.977,0 5.965,0 9.941,0 -1.988,4.18 -3.976,0 -5.964,0 -1.989,0 -3.977,0 -3.977,0 z"
                fill={hearts}
                id="path626"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 452.262,770.839 c 1.988,0 5.964,0 7.952,0 -1.988,0 -1.988,0 -3.976,0 0,4.18 -1.988,4.18 -3.976,0 z"
                fill={hearts}
                id="path628"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 444.309,749.938 c 0,0 3.977,0 5.965,0 0,0 -1.988,0 -3.976,0 -1.989,4.18 -1.989,4.18 -1.989,0 -1.988,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path630"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 376.714,766.66 c 0,0 -1.988,-10e-4 -3.976,-10e-4 0,0 0,10e-4 1.988,-4.179 0,4.18 1.988,4.18 1.988,4.18 z"
                fill={hearts}
                id="path632"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 311.107,762.478 h -1.988 c 1.988,4.18 7.953,0 11.929,0 0,0 0,0 1.988,0 0,0 0,0 1.988,0 0,0 1.988,0 1.988,4.18 0,4.18 -1.988,4.18 -3.976,0 -1.988,0 -3.976,0 -3.976,0 0,0 0,0 1.988,0 -1.988,0 -3.976,0 -7.953,0 0,0 0,0 -1.988,0 h -1.988 c -1.988,0 -3.976,0 -3.976,0 -1.988,0 -1.988,0 -3.976,0 -1.988,0 -3.976,0 -7.953,0 h -1.988 c 0,0 0,0 0,4.18 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 -1.988,0 -3.976,0 -3.976,0 0,0 0,0 -1.988,0 -1.988,0 -5.965,0 -7.953,0 h -1.988 c -1.988,0 -1.988,0 -3.976,0 0,0 1.988,0 1.988,-4.18 -3.976,0 -7.952,0 -11.928,-4.18 1.988,-4.181 3.976,0 5.964,0 -1.988,4.18 -1.988,0 -3.976,0 h 1.988 c 0,0 0,0 1.988,0 0,0 0,0 -1.988,-4.181 h 1.988 v 4.181 c 1.988,0 3.976,0 3.976,0 1.988,0 1.988,4.18 1.988,0 l -1.988,-4.181 c 1.988,0 1.988,4.181 3.976,4.181 5.965,0 7.953,4.18 13.917,4.18 3.976,0 7.952,-4.18 11.928,-4.18 3.977,0 3.977,-4.181 7.953,0 1.988,0 1.988,4.18 1.988,4.18 h -1.988 c 1.988,0 1.988,0 3.976,0 0,-4.18 -1.988,0 -1.988,-4.18 1.988,0 1.988,0 1.988,0 0,-4.181 -1.988,-4.181 -1.988,-4.181 0,0 0,0 0,4.181 -1.988,0 0,-4.18 -1.988,-8.361 v 4.18 c 1.988,4.181 3.976,4.181 3.976,8.361 1.988,0 1.988,0 3.976,0 0,-4.18 1.988,0 1.988,-4.18 z"
                fill={hearts}
                id="path634"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 297.191,766.659 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path636"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 297.19,766.659 c 0,4.181 0,4.181 0,0 z"
                fill={hearts}
                id="path638"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 295.202,766.659 c 0,4.181 0,4.181 0,0 z"
                fill={hearts}
                id="path640"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 338.94,754.118 c 0,4.18 -1.988,0 -3.976,0 0,0 1.988,-4.18 3.976,0 z"
                fill={hearts}
                id="path642"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 293.214,787.56 c 1.989,0 -1.988,0 0,0 1.989,0 1.989,-4.18 0,0 z"
                fill={hearts}
                id="path644"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -90.4877,770.84 c -1.9881,0 -1.988,0 -3.9761,0 1.9881,0 5.9642,0 7.9523,0 0,0 0,0 1.9881,0 0,0 0,-4.181 1.9881,-4.181 1.9881,0 0,4.181 0,4.181 h -1.9881 -1.9881 c -1.9881,0 -1.9881,0 -3.9762,0 z"
                fill={hearts}
                id="path646"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 563.595,791.74 c -1.988,0 -1.988,-4.18 -1.988,-4.18 1.988,0 1.988,0 1.988,4.18 z"
                fill={hearts}
                id="path648"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 565.583,745.759 h 1.988 c 1.988,0 0,4.18 -1.988,0 z"
                fill={hearts}
                id="path650"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 251.464,770.839 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path652"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 579.499,758.297 c 1.989,0 3.977,-4.18 5.965,0 -3.976,0 -3.976,0 -5.965,0 z"
                fill={hearts}
                id="path654"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 575.523,762.477 c 1.988,0 3.976,-10e-4 5.964,-4.181 -1.988,0 -3.976,4.181 -5.964,4.181 z"
                fill={hearts}
                id="path656"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 597.392,766.66 c -1.988,0 0,-4.18 -1.988,-4.18 1.988,0 1.988,4.18 1.988,4.18 z"
                fill={hearts}
                id="path658"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 589.44,737.396 c 0,0 1.988,0 3.976,0 -1.988,0 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path660"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 619.261,766.659 h 1.988 c 0,0 0,0 1.988,0 h -1.988 c 0,0 0,4.181 -1.988,0 z"
                fill={hearts}
                id="path662"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -56.6908,770.84 c 0,-4.181 0,-4.181 0,0 11.9286,-4.181 23.8572,-4.181 37.7738,-4.181 -1.9881,0 -3.9761,0 -5.9642,0 h -1.9881 c -1.9881,0 -3.9762,0 -7.9524,0 -3.9762,0 -7.9524,0 -11.9286,0 h 1.9881 c -3.9762,0 -7.9524,0 -11.9286,4.181 z"
                fill={hearts}
                id="path664"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 386.654,762.477 c 1.988,0 1.988,-4.181 3.976,-4.181 3.976,0 5.964,0 9.941,0 0,0 0,0 0,4.18 -1.988,-4.18 -1.988,10e-4 -3.977,10e-4 -1.988,0 -3.976,4.18 -3.976,4.18 0,0 0,0 -1.988,0 -1.988,-4.18 -3.976,-4.18 -3.976,-4.18 z"
                fill={hearts}
                id="path666"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 511.905,770.841 c 15.905,-4.181 29.821,-4.181 45.726,0 1.988,0 5.964,0 7.952,0 0,0 -1.988,0 -1.988,4.18 0,0 1.988,0 1.988,4.18 0,0 0,-4.18 -1.988,0 0,0 0,-4.18 -1.988,-4.18 -1.988,0 -3.976,0 -7.952,0 -9.941,0 -19.881,0 -27.833,0 -1.988,0 -3.977,0 -5.965,0 h 1.988 c -3.976,-4.18 -5.964,-4.18 -9.94,-4.18 z"
                fill={hearts}
                id="path668"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 450.273,670.516 c 3.977,0 5.965,-4.18 7.953,-4.18 1.988,0 5.964,0 7.952,0 -1.988,0 -1.988,4.18 -3.976,4.18 -1.988,0 -1.988,0 -3.976,4.18 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 0,-4.18 -1.989,-4.18 -3.977,-4.18 z"
                fill={hearts}
                id="path670"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -122.298,766.66 c -13.917,0 -29.822,0 -43.738,0 h -3.976 c 5.964,0 11.928,0 15.904,4.18 1.988,0 3.977,0 3.977,-4.18 7.952,0 19.881,-4.18 27.833,0 z"
                fill={hearts}
                id="path672"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -114.345,766.657 c 0,-4.181 1.988,0 0,0 0,-4.181 1.988,-4.181 1.988,0 0,-4.181 0,0 -1.988,0 1.988,0 1.988,0 1.988,0 1.988,0 0,0 -1.988,0 0,0 0,4.18 -1.988,4.18 -1.988,0 -3.977,0 -3.977,0 0,0 0,0 0,-4.18 0,0 0,0 -1.988,0 1.988,0 1.988,0 3.977,0 1.988,0 3.976,0 3.976,0 z"
                fill={hearts}
                id="path674"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -120.309,624.535 c -1.988,-4.18 -1.988,0 -3.976,0 0,0 1.988,0 3.976,0 z"
                fill={hearts}
                id="path676"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -128.262,624.537 c -1.988,0 -1.988,0 -1.988,-4.18 h -1.989 c 0,0 -1.988,0 -3.976,0 h -3.976 c -1.988,0 -1.988,-4.18 -3.976,0 -1.988,0 -1.988,0 -3.976,-4.18 0,0 -1.988,0 -1.988,4.18 v -4.18 c -1.988,0 -3.977,0 -5.965,0 0,0 -1.988,0 -1.988,4.18 0,0 0,0 -1.988,-4.18 1.988,0 1.988,0 3.976,0 h 1.988 v -4.18 c 0,0 0,4.18 1.989,4.18 0,0 0,0 1.988,0 1.988,0 1.988,0 3.976,0 1.988,0 1.988,0 3.976,0 h 3.976 c 0,0 0,0 1.988,0 h 3.976 v -4.18 c 1.989,8.36 1.989,8.36 3.977,12.54 z"
                fill={hearts}
                id="path678"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -152.119,624.534 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path680"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -158.084,624.534 h -1.988 c 0,0 0,0 1.988,0 z"
                fill={hearts}
                id="path682"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -160.071,624.534 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path684"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -166.036,624.535 c 1.988,0 3.976,0 3.976,0 0,0 0,0 -1.988,0 -1.988,0 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path686"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -112.358,624.535 c -1.988,0 -1.988,0 -3.976,0 0,0 0,0 -1.988,0 h -1.988 c 0,-4.18 1.988,0 1.988,0 h 1.988 c 1.988,0 3.976,0 3.976,0 z"
                fill={hearts}
                id="path688"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -172,624.535 c 1.988,0 1.988,0 3.976,0 0,0 0,0 0,-4.18 0,4.18 0,4.18 -3.976,4.18 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path690"
              />
              <path
                d="m 386.655,620.355 c -3.976,0 -7.953,0 -11.929,0 0,0 0,0 -1.988,0 -5.964,0 -13.917,0 -19.881,0 -3.976,0 -9.94,0 -11.928,0 h -1.989 c 0,0 -3.976,0 -5.964,0 h -1.988 c -11.928,0 -23.857,0 -35.786,0 7.953,0 17.893,0 25.846,0 h 1.988 c -11.929,0 -23.857,0 -33.798,0 -1.988,0 -9.94,0 -9.94,0 -1.988,0 -3.977,0 -5.965,0 1.988,0 -1.988,0 0,0 -7.952,0 -19.881,0 -27.833,0 7.952,0 15.905,0 25.845,0 h 1.988 c -3.976,0 -9.94,0 -13.916,0 1.988,0 3.976,0 3.976,0 -1.988,0 -3.976,0 -7.952,0 -3.977,0 -9.941,0 -15.905,0 -3.976,0 -7.953,0 -11.929,0 -1.988,0 -5.964,0 -7.952,0 -7.953,0 -13.917,0 -19.881,0 -5.964,0 -11.929,0 -17.893,0 -1.988,0 -5.964,0 -7.952,0 -3.977,0 -9.941,0 -15.905,0 -1.988,0 -1.988,0 -1.988,0 -5.964,0 -13.917,0 -19.881,0 -5.964,0 -9.941,0 -15.905,0 h 1.988 c 7.953,0 13.917,0 19.881,0 h 3.976 c 3.977,0 7.953,0 9.941,0 1.988,0 5.964,0 5.964,0 h 1.988 1.988 c 3.976,0 7.953,0 9.941,0 h 1.988 c 3.976,0 9.94,0 13.917,0 13.916,0 31.809,0 45.726,0 3.976,0 5.964,0 7.952,0 3.976,0 7.952,0 11.929,0 9.94,0 17.892,0 25.845,0 1.988,0 1.988,0 1.988,0 13.917,0 29.821,0 43.738,0 13.917,0 29.821,0 43.738,0 5.964,0 11.929,0 17.893,0 1.988,0 0,0 -3.976,0 z"
                fill={hearts}
                id="path692"
              />
              <path
                d="m 406.536,620.356 c 7.952,0 13.917,0 19.881,0 h 3.976 c 3.976,0 7.953,0 9.941,0 1.988,0 5.964,0 5.964,0 h 1.988 1.988 c 3.976,0 7.953,0 9.941,0 h 1.988 c 3.976,0 9.94,0 13.916,0 13.917,0 31.81,0 45.727,0 3.976,0 5.964,0 7.952,0 3.976,0 7.952,0 11.929,0 9.94,0 17.892,0 25.845,0 1.988,0 1.988,0 1.988,0 13.917,0 29.821,0 43.738,0 11.928,0 25.845,0 37.774,0 v -4.18 c -5.965,0 -11.929,0 -19.881,0 -3.976,0 -9.941,0 -11.929,0 h -1.988 c 0,0 -3.976,0 -5.964,0 h -1.988 c -11.929,0 -23.857,0 -35.786,0 7.952,0 17.893,0 25.845,0 h 1.988 c -11.928,0 -23.857,0 -33.797,0 -1.988,0 -9.941,0 -9.941,0 -1.988,0 -3.976,0 -5.964,0 1.988,0 -1.988,0 0,0 -7.952,0 -19.881,0 -27.833,0 7.952,0 15.904,0 25.845,0 h 1.988 c -3.976,0 -9.941,0 -13.917,0 1.988,0 3.977,0 3.977,0 -1.989,0 -3.977,0 -7.953,0 -3.976,0 -9.94,0 -15.905,0 -3.976,0 -7.952,0 -11.928,0 -1.988,0 -5.964,0 -7.953,0 -7.952,0 -13.916,0 -19.881,0 -5.964,0 -11.928,0 -17.892,0 -1.988,0 -5.965,0 -7.953,0 -3.976,0 -9.94,0 -15.905,0 -1.988,0 -1.988,0 -1.988,0 -5.964,0 -13.916,0 -19.881,0 1.989,4.18 -3.976,4.18 -7.952,4.18 z"
                fill={hearts}
                id="path694"
              />
              <path
                d="m 106.334,620.355 c -3.976,0 -7.9525,0 -11.9287,0 0,0 -1e-4,0 -1.9882,0 -5.9642,0 -13.9166,0 -19.8809,0 -3.9762,0 -9.9405,0 -11.9286,0 h -1.988 c 0,0 -3.9762,0 -5.9643,0 h -1.9881 c -11.9286,0 -23.8572,0 -35.7857,0 7.9524,0 17.8928,0 25.8452,0 h 1.9881 c -11.9286,0 -23.8571,0 -33.79759,0 -1.9881,0 -9.94049,0 -9.94049,0 -1.9881,0 -3.9762,0 -5.96428,0 1.98808,0 -1.9881,0 0,0 -7.95234,0 -19.88094,0 -27.83334,0 7.9524,0 15.9048,0 25.84525,0 h 1.98809 c -3.97624,0 -9.94044,0 -13.91664,0 1.9881,0 3.9762,0 3.9762,0 -1.9881,0 -3.9762,0 -7.9524,0 -3.9762,0 -9.9405,0 -15.9048,0 -3.9762,0 -7.9523,0 -11.9285,0 -1.9881,0 -5.9643,0 -7.9524,0 -7.9524,0 -13.9167,0 -19.881,0 -5.9642,0 -11.9285,0 -17.8928,0 -1.9881,0 -5.9641,0 -7.9521,0 -3.976,0 -9.941,0 -15.905,0 -1.988,0 -1.988,0 -1.988,0 -5.964,0 -13.917,0 -19.881,0 -5.964,0 -9.941,0 -15.905,0 h 1.988 c 7.953,0 13.917,0 19.881,0 h 3.976 c 3.977,0 7.953,0 9.941,0 1.988,0 5.964,0 5.964,0 h 1.988 1.988 c 3.977,0 7.953,0 9.941,0 h 1.988 c 3.9761,0 9.9404,0 13.9166,0 13.9166,0 31.8095,0 45.7261,0 3.9762,0 5.9643,0 7.9524,0 3.9762,0 7.9524,0 11.9286,0 9.9405,0 17.89282,0 25.8452,0 1.98809,0 1.98811,0 1.98811,0 13.91669,0 29.82139,0 43.73809,0 13.9166,0 29.8214,0 43.738,0 5.9643,0 11.9289,0 17.8929,0 1.988,4.18 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path696"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 384.667,616.177 h 1.988 c 1.989,0 5.965,0 9.941,0 1.988,0 3.976,0 3.976,0 0,0 1.988,0 3.976,0 0,0 1.988,0 3.976,0 0,-4.181 5.965,0 9.941,0 1.988,0 1.988,0 3.976,0 0,0 0,0 1.988,0 h 1.988 1.988 c 1.988,0 1.989,0 3.977,0 1.988,0 3.976,0 5.964,0 l -1.989,-10e-4 c -1.988,0 -3.975,10e-4 -7.952,10e-4 0,0 0,0 -1.988,0 h -1.988 -3.976 c -1.988,0 -7.952,0 -7.952,0 -5.965,-4.181 -13.917,0 -19.881,0 -5.965,4.18 -5.965,4.18 -7.953,0 1.988,4.18 0,4.18 0,0 -1.988,0 -1.988,0 -1.988,0 0,4.18 1.988,4.18 1.988,0 1.988,4.18 0,4.18 0,0 1.988,4.18 -1.988,4.18 -3.976,4.18 -3.976,0 -7.952,-4.18 -5.964,-4.18 3.976,0 7.952,0 9.94,0 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path698"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 460.215,620.356 c 0,0 1.988,0 3.976,0 1.988,0 3.976,0 3.976,0 -1.988,0 -3.976,0 -5.964,0 0,0 -3.976,0 -1.988,0 -1.988,0 -3.977,0 -5.965,0 -1.988,0 -3.976,0 -3.976,0 h -1.988 c -1.988,0 -3.976,0 -5.964,0 -1.988,0 0,0 -1.988,0 0,0 -1.988,0 -3.977,0 0,0 0,0 1.989,0 0,0 1.988,-4.18 5.964,0 1.988,0 1.988,0 3.976,0 0,0 0,0 1.988,0 0,0 0,0 -1.988,0 h 1.988 c 1.988,0 1.988,0 3.976,0 0,0 3.977,0 5.965,0 z"
                fill={hearts}
                id="path700"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 629.203,164.722 c 1.988,4.18 1.988,0 3.976,0 -1.988,0 -3.976,0 -3.976,0 z"
                fill={hearts}
                id="path702"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 637.155,164.723 c 1.988,0 3.976,0 3.976,0 h 1.988 c 0,0 0,0 1.988,0 h 1.988 c 1.988,0 1.988,0 1.988,0 1.988,0 1.988,0 3.977,0 1.988,0 1.988,0 3.976,0 0,0 0,0 1.988,0 1.988,0 0,4.181 1.988,4.181 v -4.181 c 0,0 1.988,-4.18 0,-4.18 0,0 -1.988,0 -1.988,4.18 -1.988,0 -1.988,0 -3.976,-4.18 0,4.18 -1.988,0 -3.977,0 h -3.976 c -1.988,0 -1.988,0 -3.976,0 h -1.988 c -1.988,4.18 -1.988,4.18 -3.976,4.18 z"
                fill={hearts}
                id="path704"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 623.239,164.722 c 0,0 0,0 1.988,0 h 1.988 c 0,4.18 -1.988,0 -1.988,0 z"
                fill={hearts}
                id="path706"
              />
              <path
                d="m 120.251,164.722 c 3.976,0 7.952,0 11.928,0 0,0 0,0 1.988,0 5.965,0 13.917,0 19.881,0 3.976,0 9.941,0 11.929,0 h 1.988 c 0,0 3.976,0 5.964,0 h 1.988 c 11.929,0 23.857,0 35.786,0 -7.952,0 -17.893,0 -25.845,0 h -1.988 c 11.928,0 23.857,0 33.797,0 1.988,0 9.941,0 9.941,0 1.988,0 3.976,0 5.964,0 -1.988,0 1.988,0 0,0 7.952,0 19.881,0 27.833,0 -7.952,0 -15.904,0 -23.857,0 h -1.988 c 3.976,0 9.941,0 13.917,0 -1.988,0 -3.976,0 -3.976,0 1.988,0 3.976,0 7.952,0 3.976,0 9.94,0 15.905,0 3.976,0 7.952,0 11.928,0 1.988,0 5.965,0 7.953,0 7.952,0 13.916,0 19.881,0 5.964,0 11.928,0 17.893,0 1.988,0 5.964,0 7.952,0 3.976,0 9.94,0 15.905,0 1.988,0 1.988,0 1.988,0 5.964,0 13.916,0 19.881,0 5.964,0 9.94,0 15.904,0 h -1.988 c -7.952,0 -13.916,0 -19.881,0 h -3.976 c -3.976,0 -7.952,0 -9.94,0 -1.988,0 -5.965,0 -5.965,0 h -1.988 -1.988 c -3.976,0 -7.952,0 -9.94,0 h -1.988 c -3.977,0 -9.941,0 -13.917,0 -13.917,0 -31.809,0 -45.726,0 -3.976,0 -5.964,0 -7.953,0 -3.976,0 -7.952,0 -11.928,0 -9.941,0 -17.893,0 -25.845,0 -1.988,0 -1.988,0 -1.988,0 -13.917,0 -29.822,0 -43.739,0 -13.916,0 -29.821,0 -43.738,0 -5.964,0 -11.928,0 -17.892,0 -3.977,0 0,0 1.988,0 z"
                fill={hearts}
                id="path708"
              />
              <path
                d="m -172,164.722 c 1.988,0 3.976,0 3.976,0 3.976,0 7.953,0 11.929,0 0,0 0,0 1.988,0 5.964,0 13.917,0 19.881,0 3.976,0 9.94,0 11.928,0 h 1.988 c 0,0 3.977,0 5.965,0 h 1.988 c 11.928,0 23.857,0 35.7855,0 -7.9523,0 -17.8928,0 -25.8455,0 h -1.988 c 11.9288,0 23.8574,0 33.7978,0 1.9881,0 9.9405,0 9.9405,0 1.9881,0 3.9762,0 5.9643,0 -1.9881,0 1.9881,0 0,0 7.9524,0 19.8809,0 27.8333,0 -7.9524,0 -15.9047,0 -25.8452,0 h -1.9881 c 3.9762,0 9.9404,0 13.9166,0 -1.9881,0 -3.9761,0 -3.9761,0 1.9881,0 3.9761,0 7.9523,0 3.9762,0 9.9405,0 15.9048,0 3.9762,0 7.9524,0 11.92857,0 1.9881,0 5.96427,0 7.95237,0 7.95237,0 13.91666,0 19.88096,0 5.9643,0 11.9285,0 17.8928,0 1.9881,0 5.9643,0 7.9524,0 3.9762,0 9.9404,0 15.9047,0 1.9881,0 1.9881,0 1.9881,0 5.9643,0 13.9167,0 19.881,0 5.9642,0 9.9405,0 15.9048,0 h -1.9882 c -7.9523,0 -13.9166,0 -19.8809,0 h -3.9762 c -3.9762,0 -7.9524,0 -9.9405,0 -1.9881,0 -5.9642,0 -5.9642,0 h -1.9881 -1.9882 c -3.9761,0 -7.9523,0 -9.9404,0 h -1.9881 c -3.9762,0 -9.9405,0 -13.9167,0 -13.9166,0 -31.80946,0 -45.7261,0 -3.9762,0 -5.9643,0 -7.9524,0 -3.9762,0 -7.9524,0 -11.9286,0 -9.9404,0 -17.8928,0 -25.8452,0 -1.9881,0 -1.9881,0 -1.9881,0 -13.9167,0 -29.8214,0 -43.738,0 -13.917,0 -29.821,0 -43.738,0 -7.953,0 -11.929,0 -17.893,0 z"
                fill={hearts}
                id="path710"
              />
              <path
                d="m 400.571,164.722 c 3.976,0 7.952,0 11.929,0 0,0 0,0 1.988,0 5.964,0 13.916,0 19.881,0 3.976,0 9.94,0 11.928,0 h 1.988 c 0,0 3.976,0 5.965,0 h 1.988 c 11.928,0 23.857,0 35.785,0 -7.952,0 -17.892,0 -25.845,0 h -1.988 c 11.929,0 23.857,0 33.798,0 1.988,0 9.94,0 9.94,0 1.988,0 3.976,0 5.964,0 -1.988,0 1.988,0 0,0 7.953,0 19.881,0 27.834,0 -7.953,0 -15.905,0 -25.846,0 h -1.988 c 3.977,0 9.941,0 13.917,0 -1.988,0 -3.976,0 -3.976,0 1.988,0 3.976,0 7.952,0 3.976,0 9.941,0 15.905,0 3.976,0 7.952,0 11.929,0 1.988,0 5.964,0 7.952,0 7.952,0 13.917,0 19.881,0 5.964,0 11.928,0 17.893,0 1.988,0 5.964,0 7.952,0 3.976,0 9.941,0 15.905,0 1.988,0 1.988,0 1.988,0 5.964,0 13.917,0 19.881,0 1.988,0 3.976,0 5.964,0 -3.976,0 -9.94,0 -13.916,0 h -3.977 c -3.976,0 -7.952,0 -9.94,0 -1.988,0 -5.964,0 -5.964,0 h -1.988 -1.989 c -3.976,0 -7.952,0 -9.94,0 h -1.988 c -3.976,0 -9.941,0 -13.917,0 -13.916,0 -31.809,0 -45.726,0 -3.976,0 -5.964,0 -7.952,0 -3.976,0 -7.953,0 -11.929,0 -9.94,0 -17.893,0 -25.845,0 -1.988,0 -1.988,0 -1.988,0 -13.917,0 -29.822,0 -43.738,0 -13.917,0 -29.822,0 -43.738,0 -5.965,0 -11.929,0 -17.893,0 0,0 3.976,0 5.964,0 z"
                fill={hearts}
                id="path712"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 34.7623,160.542 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path714"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 38.7387,164.726 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path716"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 122.239,168.906 h -1.989 c -1.988,0 -5.964,0 -9.94,0 -1.988,0 -3.976,0 -3.976,0 0,0 -1.988,0 -3.976,0 0,0 -1.988,0 -3.9765,0 0,4.18 -5.9644,0 -9.9405,0 -1.9881,0 -1.9881,0 -3.9762,0 0,0 0,0 -1.9881,0 h -1.9881 -1.9881 c -1.9881,0 -1.9881,0 -3.9762,0 -1.9881,0 -3.9762,0 -5.9643,0 h 1.9881 c 1.9881,0 3.9762,0 7.9524,0 0,0 0,0 1.9881,0 h 1.9881 3.9762 c 1.988,0 7.9523,0 7.9523,0 5.9648,4.18 13.9168,0 19.8808,0 5.964,-4.18 5.964,-4.18 7.953,0 1.988,0 1.988,0 1.988,0 0,-4.18 0,-4.18 -1.988,0 0,-4.18 -1.989,-4.18 0,0 -1.989,-4.18 1.988,-4.18 3.976,-4.18 3.976,0 7.952,4.18 5.964,4.18 -3.976,0 -7.952,0 -9.94,0 z"
                fill={hearts}
                id="path718"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 46.6904,164.726 c 0,0 -1.9882,0 -3.9763,0 -1.988,0 -3.9761,0 -3.9761,0 1.9881,0 3.9762,0 5.9643,0 0,0 3.9762,0 1.9881,0 1.9881,0 3.9761,0 5.9642,0 1.9881,0 3.9762,0 3.9762,0 h 1.9881 c 1.9881,0 3.9762,0 5.9643,0 1.9881,0 0,0 1.9881,0 0,0 1.9881,0 3.9762,0 0,0 0,0 -1.9881,0 0,0 -1.9881,4.18 -5.9643,0 -1.9881,0 -1.9881,0 -3.9762,0 0,0 0,0 -1.9881,0 0,0 0,0 1.9881,0 h -1.9881 c -1.9881,0 -1.9881,0 -3.9762,0 0,0 -3.9761,0 -5.9642,0 z"
                fill={hearts}
                id="path720"
              />
              <path
                d="m 482.084,469.872 c 1.988,0 3.976,0 5.964,0 3.976,-4.18 7.953,0 11.929,0 1.988,0 5.964,-4.18 7.952,0 0,0 0,0 1.988,0 0,-4.18 1.988,0 1.988,0 h 1.988 c 5.965,0 13.917,0 19.881,0 -3.976,0 -9.94,0 -13.916,0 5.964,0 11.928,0 19.881,0 1.988,0 5.964,4.18 5.964,0 1.988,0 1.988,0 3.976,0 3.976,0 9.941,0 15.905,0 -3.976,0 -9.941,0 -13.917,0 0,0 0,0 -1.988,0 1.988,-4.18 3.976,0 3.976,0 -1.988,0 -1.988,0 -3.976,0 1.988,0 5.964,0 7.953,0 h -1.989 c 1.989,0 1.989,0 3.977,0 1.988,0 5.964,-4.18 7.952,0 1.988,0 3.976,0 5.964,0 1.988,0 3.977,0 3.977,0 3.976,0 7.952,0 11.928,0 3.976,0 5.964,0 9.941,0 1.988,0 3.976,0 3.976,0 1.988,0 5.964,0 7.952,0 h 1.988 c 3.976,0 7.953,0 11.929,0 1.988,0 5.964,0 7.952,0 -3.976,0 -7.952,0 -11.928,0 h -1.988 c 0,0 0,0 1.988,0 h -1.988 c -1.989,0 -3.977,4.18 -5.965,0 0,0 0,0 0,4.18 0,-4.18 -1.988,0 -3.976,0 0,0 -1.988,0 0,-4.18 0,0 0,0 1.988,0 -1.988,0 -3.976,0 -5.964,0 -1.988,-4.18 -5.964,0 -7.953,0 0,0 0,0 0,4.18 0,0 0,0 0,-4.18 -7.952,0 -17.892,0 -25.845,0 -1.988,0 -3.976,0 -3.976,4.18 -1.988,0 -3.976,0 -7.952,0 -5.965,0 -9.941,0 -13.917,0 h -1.988 c -7.952,0 -15.905,0 -25.845,0 -7.953,0 -15.905,0 -23.857,0 -3.977,0 -5.965,0 -9.941,0 -5.964,0 -3.976,0 -1.988,-4.18 z"
                fill={hearts}
                id="path722"
              />
              <path
                d="m 525.821,478.231 c -1.988,0 -3.976,0 -5.964,0 -3.976,4.18 -7.953,0 -11.929,0 -1.988,0 -5.964,4.18 -7.952,0 0,0 0,0 -1.988,0 0,4.18 -1.988,0 -1.988,0 h -1.988 c -5.965,0 -13.917,0 -19.881,0 3.976,0 9.94,0 13.916,0 -5.964,0 -11.928,0 -19.881,0 -1.988,0 -5.964,-4.18 -5.964,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 -9.941,0 -15.905,0 3.976,0 9.941,0 13.917,0 0,0 0,0 1.988,0 -1.988,4.18 -3.976,0 -3.976,0 1.988,0 1.988,0 3.976,0 -1.988,0 -5.964,0 -7.953,0 h 1.989 c -1.989,0 -1.989,0 -3.977,0 -1.988,0 -5.964,4.18 -7.952,0 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -3.976,0 -3.976,0 -3.977,0 -7.953,0 -11.929,0 -3.976,0 -5.964,0 -9.941,0 -1.988,0 -3.976,0 -3.976,0 -1.988,0 -5.964,0 -7.952,0 h -1.988 c -3.976,0 -7.953,0 -11.929,0 -1.988,0 -5.964,0 -7.952,0 3.976,0 7.952,0 11.928,0 h 1.989 c 0,0 0,0 -1.989,0 h 1.989 c 1.988,0 3.976,-4.18 5.964,0 0,0 0,0 0,-4.18 0,4.18 1.988,0 3.976,0 0,0 1.988,0 0,4.18 0,0 0,0 -1.988,0 1.988,0 3.976,0 5.964,0 1.988,4.18 5.965,0 7.953,0 0,0 0,0 0,-4.18 0,0 0,0 0,4.18 7.952,0 17.892,0 25.845,0 1.988,0 3.976,0 3.976,-4.18 1.988,0 3.976,0 7.952,0 5.965,0 9.941,0 13.917,0 h 1.988 c 7.952,0 15.905,0 25.845,0 7.953,0 15.905,0 23.858,0 3.976,0 5.964,0 9.94,0 5.964,0 3.976,0 1.988,4.18 z"
                fill={hearts}
                id="path724"
              />
              <path
                d="m 482.084,478.231 c -1.988,0 -3.976,0 -5.964,0 -3.976,4.18 -7.953,0 -11.929,0 -1.988,0 -5.964,4.18 -7.952,0 0,0 0,0 -1.988,0 0,4.18 -1.988,0 -1.988,0 h -1.988 c -5.965,0 -13.917,0 -19.881,0 3.976,0 9.94,0 13.916,0 -5.964,0 -11.928,0 -19.881,0 -1.988,0 -5.964,-4.18 -5.964,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 -9.941,0 -15.905,0 3.976,0 9.941,0 13.917,0 0,0 0,0 1.988,0 -1.988,4.18 -3.976,0 -3.976,0 1.988,0 1.988,0 3.976,0 -1.988,0 -5.965,0 -7.953,0 h 1.988 c -1.988,0 -1.988,0 -3.976,0 -1.988,0 -5.964,4.18 -7.952,0 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -3.977,0 -3.977,0 -3.976,0 -7.952,0 -11.928,0 -3.976,0 -5.964,0 -9.941,0 -1.988,0 -3.976,0 -3.976,0 -1.988,0 -5.964,0 -7.952,0 h -1.988 c -3.977,0 -7.953,0 -11.929,0 -1.988,0 -5.964,0 -7.952,0 3.976,0 7.952,0 11.928,0 h 1.988 c 0,0 0,0 -1.988,0 h 1.988 c 1.989,0 3.977,-4.18 5.965,0 0,0 0,0 0,-4.18 0,4.18 1.988,0 3.976,0 0,0 1.988,0 0,4.18 0,0 0,0 -1.988,0 1.988,0 3.976,0 5.964,0 1.988,4.18 5.964,0 7.953,0 0,0 -10e-4,0 -10e-4,-4.18 0,0 10e-4,0 10e-4,4.18 7.952,0 17.892,0 25.845,0 1.988,0 3.976,0 3.976,-4.18 1.988,0 3.976,0 7.952,0 5.965,0 9.941,0 13.917,0 h 1.988 c 7.952,0 15.905,0 25.845,0 7.953,0 15.905,0 23.857,0 3.977,0 5.965,0 9.941,0 3.976,0 1.988,0 1.988,4.18 z"
                fill={hearts}
                id="path726"
              />
              <path
                d="m 329,474.051 c -3.976,0 -7.952,0 -11.928,0 0,0 0,0 -1.988,0 -5.965,0 -13.917,0 -19.881,0 -3.976,0 -9.941,0 -11.929,0 h -5.964 -1.988 c -11.929,0 -23.857,0 -33.798,0 7.953,0 17.893,0 25.845,0 h 1.989 c -11.929,0 -21.87,0 -33.798,0 -1.988,0 -9.941,0 -9.941,0 -1.988,0 -3.976,0 -5.964,0 1.988,0 -1.988,0 0,0 -7.952,0 -17.893,0 -27.833,0 7.952,0 15.905,0 23.857,0 h 1.988 c -1.988,0 -5.964,0 -5.964,0 1.988,0 5.964,0 7.952,0 -3.976,0 -7.952,0 -13.916,0 1.988,0 3.976,0 3.976,0 -1.988,0 -3.976,0 -7.953,0 -3.976,0 -9.94,0 -13.916,0 -3.976,0 -7.953,0 -11.929,0 -1.988,0 -5.964,0 -7.952,0 -7.953,0 -13.917,0 -19.881,0 -5.964,0 -11.929,0 -17.893,0 -1.988,0 -5.964,0 -7.952,0 -3.977,0 -9.941,0 -15.905,0 -1.988,0 -1.988,0 -1.988,0 -5.9646,0 -13.9169,0 -19.8812,0 -5.9643,0 -9.9405,0 -15.9048,0 h 1.9881 c 5.9643,0 13.9167,0 19.8809,0 h 3.9762 c 3.9762,0 7.9528,0 9.9408,0 1.988,0 5.964,0 5.964,0 h 1.988 1.988 c 3.976,0 7.953,0 9.941,0 h 1.988 c 3.976,0 9.94,0 13.916,0 13.917,0 29.822,0 45.727,0 3.976,0 5.964,0 7.952,0 3.976,0 7.952,0 11.929,0 7.952,0 17.892,0 25.845,0 1.988,0 1.988,0 1.988,0 13.916,0 29.821,0 43.738,0 13.917,0 29.821,0 43.738,0 5.964,0 11.929,0 17.893,0 -3.976,0 -7.953,0 -9.941,0 z"
                fill={hearts}
                id="path728"
              />
              <path
                d="m -168.024,474.052 h -1.988 -1.988 c 5.964,0 9.94,0 15.905,0 h 1.988 c 5.964,0 9.94,0 15.905,0 1.988,0 5.964,0 7.952,0 5.964,0 11.929,0 17.893,0 7.952,0 13.9165,0 19.8808,0 1.9881,0 5.9643,0 7.9524,0 3.9762,0 7.9524,0 11.9285,0 5.9643,0 9.9405,0 13.9167,0 1.9881,0 3.9762,0 7.9524,0 -1.9881,0 -3.9762,0 -3.9762,0 3.9762,0 9.9404,0 13.9166,0 -1.9881,0 -5.9642,0 -7.9523,0 h 5.9643 -1.9881 c -7.9524,0 -15.9048,0 -23.8572,0 9.9405,0 19.881,0 27.8333,0 1.9881,0 3.9762,0 5.9643,0 0,0 7.9524,0 9.9405,0 11.9286,0 21.86904,0 33.79761,0 0,0 -10e-6,0 -1.98811,0 -7.95238,0 -17.8928,0 -25.8452,0 11.92856,0 23.8571,0 33.7976,0 0,0 3.9762,0 1.9881,0 1.9881,0 3.9762,0 3.9762,0 1.9881,0 1.988,0 1.988,0 3.9762,0 7.9524,0 11.9286,0 5.9643,0 13.9167,0 19.881,0 0,0 0,0 1.9881,0 3.9761,0 7.9523,0 11.9285,0 1.9881,0 5.9643,0 9.9405,-4.18 -5.9643,0 -11.9286,0 -17.8929,0 -13.9166,0 -29.8214,0 -43.738,0 -13.9167,0 -29.8215,0 -43.7381,0 -1.9881,0 -1.9881,0 -1.9881,0 -7.9524,0 -17.8928,0 -25.8452,0 -3.9762,0 -7.9524,0 -11.9286,0 -1.9881,0 -3.9762,0 -7.9524,0 -13.9166,0 -29.8216,0 -45.7266,0 -3.976,0 -9.94,0 -13.916,0 h -1.988 c -1.988,0 -5.965,0 -9.941,0 h -1.988 c 1.988,0 0,0 1.988,0 h -1.988 c 0,0 0,0 -1.988,0 -1.988,0 -5.964,0 -5.964,0 -1.988,0 -5.965,0 -9.941,0 0,4.18 0,4.18 -1.988,4.18 h -1.988 c 1.988,0 3.976,0 1.988,0 z"
                fill={hearts}
                id="path730"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 372.738,474.051 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path732"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 366.774,474.051 c 1.988,0 1.988,4.18 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path734"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 378.703,478.231 c -1.988,0 -1.988,0 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path736"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 374.726,478.23 c -1.988,4.18 -3.976,0 -5.964,0 3.976,0 3.976,4.18 5.964,0 0,4.18 0,4.18 0,0 0,4.18 1.988,4.18 1.988,4.18 0,0 0,0 1.988,0 h -1.988 c 0,-4.18 0,-4.18 -1.988,-4.18 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path738"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 352.857,478.231 h 1.988 c -1.988,0 -3.976,0 -5.964,0 h 1.988 c 0,-4.18 0,0 1.988,0 z"
                fill={hearts}
                id="path740"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 368.762,478.23 c -3.976,0 -7.952,0 -11.928,0 1.988,0 3.976,0 5.964,0 1.988,0 3.976,0 5.964,0 z"
                fill={hearts}
                id="path742"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 629.202,457.33 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path744"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 625.225,465.688 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path746"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 629.202,478.227 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path748"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 627.214,478.23 c 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path750"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 635.167,457.33 c 0,0 -1.988,4.18 -3.976,4.18 1.988,-4.18 1.988,-4.18 3.976,-4.18 z"
                fill={hearts}
                id="path752"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 643.119,469.868 c 0,-4.18 -1.988,0 0,0 0,-4.18 0,-4.18 0,0 -1.988,0 -3.976,0 -3.976,-4.18 0,0 0,0 -1.988,0 1.988,8.36 3.976,8.36 5.964,4.18 -1.988,0 -1.988,0 0,0 1.988,0 1.988,0 0,0 1.988,4.18 1.988,4.18 1.988,4.18 0,0 0,0 1.988,0 h 3.976 c 0,0 0,0 0,4.181 h 1.988 c 0,0 0,-10e-4 1.988,-4.181 0,0 0,0 -1.988,0 0,0 0,0 0,4.181 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 h -1.988 c -1.988,-8.361 -1.988,-8.361 -3.976,-8.361 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path754"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 641.131,469.868 c -1.988,0 -1.988,0 0,0 0,-4.18 -1.988,0 0,0 z"
                fill={hearts}
                id="path756"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 629.202,469.868 c -1.988,0 -1.988,0 0,0 -1.988,0 -3.976,0 -3.976,0 0,0 0,0 -1.988,0 0,0 0,0 1.988,0 0,0 0,0 -1.988,0 -1.988,0 -3.977,0 -3.977,0 0,0 0,0 -1.988,0 h 1.988 c 0,0 0,0 1.989,0 0,0 0,0 -1.989,0 0,4.18 0,0 -1.988,0 0,0 0,0 -1.988,0 h 1.988 c 0,0 0,0 -1.988,0 0,-4.18 0,-4.18 1.988,-4.18 h -1.988 c 0,0 0,0 0,4.18 v 4.18 h -1.988 c 0,0 0,0 0,4.18 h -1.988 -3.976 c 0,4.181 -1.988,0 -3.976,0 h 3.976 c 0,0 1.988,0 3.976,0 h 1.988 c 1.988,0 1.988,0 1.988,0 h 1.988 c 1.988,0 1.988,0 1.988,0 h 1.989 c 0,0 0,0 1.988,0 1.988,0 1.988,0 3.976,0 h 1.988 c 0,0 0,0 1.988,0 0,0 0,0 0,4.181 0,0 0,0 1.988,0 0,0 0,0 1.988,0 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 0,0 0,0 0,-4.181 0,0 0,0 0,-4.18 0,0 0,-4.18 -1.988,-4.18 v 8.36 c 0,0 0,0 1.988,0 0,0 0,0 -1.988,0 1.988,-12.54 1.988,-8.36 0,-8.36 z"
                fill={hearts}
                id="path758"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 635.166,469.867 c 0,4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path760"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 657.035,474.047 c 1.989,-4.18 0,-4.18 0,0 1.989,-4.18 1.989,-4.18 0,0 1.989,0 1.989,0 1.989,0 v -4.18 c 0,0 0,0 -1.989,4.18 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path762"
              />
              <path
                d="m 661.012,302.667 c -5.964,0 -11.929,0 -19.881,0 5.964,4.18 11.929,0 19.881,0 z"
                fill={hearts}
                id="path764"
              />
              <path
                d="m 480.096,315.207 c 3.976,0 7.952,0 11.928,0 h 1.989 c 0,0 0,0 -1.989,0 h 1.989 c 1.988,0 3.976,0 5.964,0 0,0 0,0 0,4.18 0,-4.18 1.988,0 3.976,0 0,0 1.988,0 0,-4.18 0,0 0,0 -1.988,0 1.988,0 3.976,0 5.964,0 1.988,-4.18 5.964,0 7.953,0 0,0 0,0 0,4.18 0,0 0,0 0,-4.18 7.952,0 17.892,0 25.845,-4.18 1.988,0 3.976,0 3.976,0 1.988,0 3.976,0 7.952,0 5.965,0 9.941,0 13.917,0 h 1.988 c 7.953,0 15.905,0 25.845,0 7.953,0 15.905,0 23.858,0 3.976,0 7.952,0 11.928,0 1.988,0 3.976,0 5.964,0 h 1.988 c 1.989,0 1.989,0 3.977,0 0,0 0,0 1.988,0 0,0 0,0 1.988,0 1.988,0 3.976,0 5.964,0 v -4.18 c -1.988,0 -1.988,0 -1.988,0 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -1.988,0 -1.988,0 -1.988,0 -3.977,0 -3.977,0 -5.964,0 -13.916,0 -19.88,0 -3.977,0 -7.953,0 -11.929,0 -1.988,0 -5.964,-4.18 -7.952,0 0,0 0,0 -1.989,0 0,-4.18 -1.988,0 -1.988,0 h -1.988 c -5.964,0 -13.916,0 -19.881,0 3.976,0 9.941,0 13.917,0 -5.964,0 -13.917,0 -19.881,0 5.964,0 3.976,0 3.976,0 1.988,0 1.988,0 3.976,0 -1.988,0 -5.964,4.18 -5.964,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 -9.941,0 -15.905,0 3.976,0 9.941,0 13.917,0 0,0 0,0 1.988,0 -1.988,-4.18 -3.976,0 -3.976,0 1.988,0 3.976,0 3.976,0 -1.988,0 -5.964,0 -7.952,0 h 1.988 c -1.988,0 -1.988,0 -3.977,0 -1.988,0 -5.964,-4.18 -7.952,0 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -3.976,0 -3.976,0 -3.977,0 -7.953,0 -11.929,0 -3.976,0 -5.964,0 -9.94,0 -1.989,0 -3.977,0 -3.977,0 -1.988,0 -5.964,0 -7.952,0 h -1.988 c -3.976,0 -7.952,0 -11.929,0 -3.976,4.18 -7.952,4.18 -9.94,8.36 z"
                fill={hearts}
                id="path766"
              />
              <path
                d="m 249.476,311.027 c 3.976,0 7.953,0 11.929,0 h 1.988 c 0,0 0,0 -1.988,0 h 1.988 c 1.988,0 3.976,0 5.964,0 0,0 1.988,0 3.976,0 0,0 0,0 -1.988,0 1.988,0 3.976,0 5.964,0 1.989,0 5.965,0 7.953,0 7.952,0 17.893,0 25.845,0 1.988,0 3.976,0 3.976,0 1.988,0 3.976,0 7.953,0 5.964,0 9.94,0 13.916,0 h 1.988 c 7.953,0 15.905,0 25.846,0 7.952,0 15.904,0 23.857,0 3.976,0 7.952,0 11.928,0 1.988,0 3.976,0 5.965,0 h 1.988 c 1.988,0 1.988,0 3.976,0 0,0 0,0 1.988,0 0,0 0,0 1.988,0 1.988,0 5.964,0 7.952,0 0,0 1.988,0 3.977,-4.181 h 1.988 -5.965 c -7.952,0 -13.916,0 -21.869,0 5.965,0 13.917,0 19.881,0 1.988,0 1.988,0 3.977,0 -1.989,0 -1.989,0 -1.989,0 h -1.988 c -1.988,0 -1.988,0 -1.988,0 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -1.988,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 -5.965,0 -13.917,0 -19.881,0 -3.976,0 -7.953,0 -11.929,0 -1.988,0 -5.964,0 -7.952,0 0,0 0,0 -1.988,0 h -3.977 c -5.964,0 -13.916,0 -19.88,0 3.976,0 9.94,0 13.916,0 -5.964,0 -11.928,0 -19.881,0 -1.988,0 -5.964,0 -5.964,0 -1.988,0 -1.988,0 -3.976,0 -3.976,0 -9.941,0 -15.905,0 3.976,0 9.941,0 13.917,0 0,0 0,0 1.988,0 -1.988,0 -3.976,0 -3.976,0 1.988,0 3.976,0 3.976,0 -1.988,0 -5.964,0 -7.953,0 h 1.988 c -1.988,0 -1.988,0 -3.976,0 -1.988,0 -5.964,0 -7.952,0 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -3.977,0 -3.977,0 -3.976,0 -7.952,0 -11.928,0 -3.976,0 -5.964,0 -9.941,0 -1.988,0 -3.976,0 -3.976,0 -1.988,0 -5.964,0 -7.952,0 h -1.988 c -3.976,0 -7.953,0 -11.929,0 3.976,4.181 0,4.181 -1.988,4.181 z"
                fill={hearts}
                id="path768"
              />
              <path
                d="m 102.358,311.027 c 3.976,0 7.952,0 11.928,0 h 1.988 c 0,0 0,0 -1.988,0 h 1.988 c 1.988,0 3.976,0 5.965,0 0,0 1.988,0 3.976,0 0,0 0,0 -1.988,0 1.988,0 3.976,0 5.964,0 1.988,0 5.964,0 7.952,0 7.953,0 17.893,0 25.846,0 1.988,0 3.976,0 3.976,0 1.988,0 3.976,0 7.952,0 5.964,0 9.941,0 13.917,0 h 1.988 c 7.952,0 15.905,0 25.845,0 7.953,0 15.905,0 23.857,0 3.976,0 7.953,0 11.929,0 1.988,0 3.976,0 5.964,0 h 1.988 c 1.988,0 1.988,0 3.976,0 0,0 0,0 1.989,0 0,0 -10e-4,0 1.988,0 1.988,0 5.964,0 7.952,0 0,0 1.988,0 3.976,-4.181 h 1.988 -5.964 c -7.952,0 -13.917,0 -21.869,0 5.964,0 13.917,0 19.881,0 1.988,0 1.988,0 3.976,0 -1.988,0 -1.988,0 -1.988,0 h -1.988 c -1.988,0 -1.988,0 -1.988,0 -1.988,0 -3.976,0 -5.964,0 -1.989,0 -1.989,0 -1.989,0 -1.988,0 -3.976,0 -3.976,0 -5.964,0 -13.916,0 -19.881,0 -3.976,0 -7.952,0 -11.928,0 -1.988,0 -5.965,0 -7.953,0 0,0 0,0 -1.988,0 h -3.976 c -5.964,0 -13.917,0 -19.881,0 3.976,0 9.941,0 13.917,0 -5.965,0 -11.929,0 -19.881,0 -1.988,0 -5.964,0 -5.964,0 -1.989,0 -1.989,0 -3.977,0 -3.976,0 -9.94,0 -15.904,0 3.976,0 9.94,0 13.916,0 0,0 0,0 1.988,0 -1.988,0 -3.976,0 -3.976,0 1.988,0 3.976,0 3.976,0 -1.988,0 -5.964,0 -7.952,0 h 1.988 c -1.988,0 -1.988,0 -3.976,0 -1.988,0 -5.964,0 -7.953,0 -1.988,0 -3.976,0 -5.964,0 -1.988,0 -3.976,0 -3.976,0 -3.976,0 -7.952,0 -11.929,0 -3.976,0 -5.964,0 -9.94,0 -1.988,0 -3.976,0 -3.976,0 -1.988,0 -5.965,0 -7.953,0 h -1.988 c -3.976,0 -7.952,0 -11.928,0 1.988,4.181 0,4.181 -1.988,4.181 -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path770"
              />
              <path
                d="m -172,306.847 c 1.988,0 3.976,0 3.976,0 0,0 3.976,0 5.964,0 h 1.989 1.988 c -1.988,0 0,0 -1.988,0 0,0 0,0 1.988,0 3.976,0 7.952,0 9.94,0 h 1.988 c 3.976,0 9.941,0 13.917,0 13.917,0 29.821,0 43.738,0 3.9762,0 5.9643,0 9.9405,0 3.9761,0 7.9523,0 11.9285,0 7.9524,0 17.8929,0 25.8452,0 h 1.9881 c 13.9167,0 27.8333,0 41.74999,0 13.91661,0 27.83331,0 41.75001,0 5.9642,0 13.9166,0 19.8809,0 3.9762,0 7.9524,0 11.9286,0 1.9881,0 1.9881,0 3.9762,0 1.988,0 3.9762,0 5.9642,0 h 3.9762 c 5.9643,0 9.9406,0 15.9046,0 1.988,0 3.976,0 5.964,-4.18 1.989,0 1.989,0 3.977,0 h -9.941 c -11.9284,0 -23.857,0 -35.7855,0 11.9285,0 21.869,0 33.7975,0 3.976,0 3.976,0 5.964,0 -1.988,0 -3.976,0 -3.976,0 -1.988,0 -1.988,0 -3.976,0 h 1.988 c -1.988,0 -1.988,0 -3.976,0 0,0 0,0 -1.9882,0 -1.9881,0 -5.9643,0 -9.9404,0 -1.9881,0 -3.9763,0 -3.9763,0 -1.9881,0 -5.9642,0 -5.9642,0 0,0 0,0 -1.9881,0 -11.9286,0 -21.8691,0 -33.7976,0 0,0 0,0 -1.9881,0 -5.9643,0 -13.9167,0 -19.8809,0 -3.9762,0 -7.9524,0 -11.9286,0 H 0.964218 c -11.928618,0 -21.869018,0 -33.797618,0 7.9524,0 15.9047,0 25.84521,0 h 1.98811 c -11.92852,0 -21.86902,0 -33.79762,0 -1.9881,4.18 -5.9643,4.18 -5.9643,4.18 1.9881,0 3.9762,0 5.9643,0 -1.9881,0 -9.9404,0 -9.9404,0 -1.9881,0 -3.9762,0 -5.9643,0 -7.9524,0 -17.8929,0 -27.8333,0 7.9523,0 15.9047,0 23.8571,0 h 1.9881 c -1.9881,0 -5.9643,0 -5.9643,0 1.9881,0 5.9643,0 7.9524,0 -3.9762,0 -7.9524,0 -11.9286,0 1.9881,0 1.9881,0 3.9762,0 -1.9881,0 -3.9762,0 -7.9524,0 -3.9762,0 -7.9524,0 -13.9166,0 -3.9762,0 -7.9524,0 -11.9286,0 -1.9881,0 -3.9766,0 -7.9526,0 -5.964,0 -11.928,0 -19.881,0 -5.964,0 -11.928,0 -15.904,0 -3.977,0 -5.965,0 -9.941,0 -3.976,0 -9.94,0 -13.917,0 h -1.988 c -1.988,0 -3.976,0 -5.964,0 z"
                fill={hearts}
                id="path772"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -152.119,302.668 c -1.988,0 -5.964,0 -7.952,0 h -1.989 c -1.988,0 -3.976,0 -3.976,0 -1.988,0 -1.988,0 -1.988,0 h -1.988 c 0,0 0,0 -1.988,0 v 4.179 l 1.988,0.001 c 5.964,0 9.941,0 13.917,4.18 1.988,-4.18 3.976,0 3.976,0 1.988,0 5.964,0 7.952,0 h -1.988 c 1.988,-4.18 5.965,-4.18 7.953,-4.18 -1.988,0 -1.988,0 -3.977,0 h -1.988 c -1.988,0 -1.988,0 -3.976,-4.18 1.988,0 3.976,0 5.964,0 0,0 0,0 0,-4.18 -1.988,0 -1.988,0 -3.976,0 -1.988,0 -3.976,0 -3.976,0 0,4.18 0,4.18 -1.988,4.18 z"
                fill={hearts}
                id="path774"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -164.047,298.484 c -1.988,0 -3.976,0 -5.964,-4.18 v 4.18 c 3.976,0 7.952,0 11.928,0 1.988,0 3.976,0 3.976,0 -1.988,0 -3.976,0 -5.964,0 -3.976,0 -3.976,0 -3.976,0 z"
                fill={hearts}
                id="path776"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 519.858,306.846 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path778"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 519.857,306.844 c -1.988,-4.18 -1.988,-4.18 0,0 -1.988,-4.18 -1.988,-4.18 -1.988,-4.18 0,-4.18 0,0 1.988,4.18 -1.988,-4.18 -1.988,-4.18 0,0 -1.988,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path780"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 523.833,315.207 c -1.988,-4.18 -1.988,-4.18 -1.988,-4.18 v -4.181 c 1.988,0 1.988,4.181 1.988,8.361 1.989,0 0,-4.18 1.989,-4.18 h 1.988 c 0,0 0,0 1.988,0 1.988,0 1.988,0 3.976,0 0,0 0,0 0,4.18 0,0 0,0 -1.988,4.18 -1.988,0 -1.988,0 -3.976,0 h -1.988 c 1.988,-8.36 0,-8.36 -1.989,-4.18 z"
                fill={hearts}
                id="path782"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 533.774,302.664 c 0,-4.18 -1.988,-4.18 -1.988,-8.36 1.988,0 1.988,0 1.988,8.36 0,-4.18 1.988,-4.18 0,0 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path784"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 547.691,315.203 c -1.988,-4.18 -1.988,-4.18 -1.988,-4.18 -1.988,0 -3.976,0 -5.964,0 -1.989,0 -1.989,0 -3.977,0 0,-4.18 0,-4.18 0,-4.18 0,0 0,0 1.988,0 0,4.18 1.988,0 3.977,0 0,0 1.988,4.18 3.976,0 0,0 0,0 1.988,0 -1.988,4.18 0,4.18 0,8.36 0,-4.18 1.988,-4.18 1.988,-8.36 1.988,0 3.976,0 3.976,-4.18 0,0 0,4.18 0,8.36 h -1.988 c 0,0 0,0 -1.988,0 -1.988,0 0,8.36 -1.988,8.36 0,0 1.988,0 0,-4.18 1.988,4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path786"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -116.333,285.947 c 0,0 0,-4.18 -1.988,-4.18 1.988,0 0,0 1.988,4.18 z"
                fill={hearts}
                id="path788"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -118.321,294.306 c 0,-4.18 0,-4.18 1.988,-8.36 0,4.18 -1.988,8.36 -1.988,8.36 z"
                fill={hearts}
                id="path790"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -108.381,277.586 c -1.988,0 -3.976,0 -5.964,0 h 1.988 c 1.989,0 3.976,-4.18 3.976,-4.18 l 1.989,-4.181 c 0,0 0,0 0,-4.18 v 4.18 c -1.989,4.181 -1.989,4.181 -1.989,8.361 z"
                fill={hearts}
                id="path792"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 496,336.108 c 0,-4.18 0,-4.18 0,0 0,-4.18 0,-4.18 0,-4.18 0,0 0,10e-4 0,-4.179 0,0 1.988,-0.001 1.988,-4.181 1.988,0 3.977,4.18 1.988,8.36 0,4.18 -1.988,4.18 -3.976,4.18 z"
                fill={hearts}
                id="path794"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -116.333,260.863 1.988,4.18 v 4.181 c 0,0 0,-10e-4 0,-4.181 -1.988,-4.18 -3.976,-4.18 -1.988,-4.18 -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path796"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -120.309,260.866 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path798"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -110.369,260.863 v -4.18 c 0,0 -1.988,0 -1.988,-4.18 1.988,0 1.988,4.18 1.988,8.36 z"
                fill={hearts}
                id="path800"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -110.368,260.867 c 1.988,0 0,-4.181 0,0 z"
                fill={hearts}
                id="path802"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -116.332,273.405 c 1.988,0 1.988,0 0,0 5.964,-4.18 7.952,4.18 3.976,8.36 0,0 0,0 -1.988,0 -1.988,-4.18 -1.988,-4.18 -1.988,-8.36 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path804"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m -104.404,269.227 v -4.18 c 0,0 0,0 0,4.18 z"
                fill={hearts}
                id="path806"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 480.096,340.289 c -1.989,0 -1.989,0 0,0 z"
                fill={hearts}
                id="path808"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 237.548,306.846 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path810"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 223.631,306.846 c 1.988,0 1.988,0 0,0 1.988,0 1.988,0 1.988,0 z"
                fill={hearts}
                id="path812"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 217.667,306.848 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path814"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 209.714,302.667 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path816"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 213.691,302.667 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path818"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 478.107,327.749 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path820"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 227.607,298.488 c -1.988,0 -1.988,0 0,0 -1.988,-4.18 -1.988,0 0,0 z"
                fill={hearts}
                id="path822"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 110.309,302.667 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path824"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 476.119,311.026 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path826"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 110.31,298.488 c 0,4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path828"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 112.298,298.485 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path830"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 472.143,336.105 c 0,4.18 1.988,0 0,0 z"
                fill={hearts}
                id="path832"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 478.107,331.926 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path834"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 476.119,331.927 c -1.988,0 -1.988,0 0,0 0,-4.181 -1.988,0 0,0 z"
                fill={hearts}
                id="path836"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 227.607,306.846 c 1.989,0 1.988,0 3.977,0 h -1.988 -1.989 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path838"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 482.083,327.748 c 0,-4.18 0,-4.18 0,0 0,-4.18 0,-4.18 0,-4.18 1.988,0 1.988,0 0,4.18 1.988,-4.18 1.988,0 0,0 -1.988,4.18 -1.988,0 -1.988,0 0,0 0,0 1.988,0 -1.988,0 -1.988,0 0,0 -1.988,0 -3.976,0.001 -3.976,0.001 0,0 0,-0.001 1.988,-0.001 0,0 0,0 1.988,0 z"
                fill={hearts}
                id="path840"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 221.643,306.846 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path842"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 219.655,306.848 c 0,-4.18 -1.988,-4.18 0,0 z"
                fill={hearts}
                id="path844"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 203.75,302.668 c 0,-4.18 0,-4.18 0,0 -1.988,0 -1.988,0 -1.988,0 0,-4.18 0,-4.18 1.988,0 -1.988,0 -1.988,-4.18 -3.976,0 0,0 0,0 0,-4.18 0,0 1.988,0 3.976,0 -1.988,0 -1.988,0 0,4.18 0,-4.18 1.988,-4.18 0,0 1.988,-4.18 1.988,-4.18 0,0 3.976,0 3.976,0 3.976,0 -1.988,-4.18 -1.988,0 -3.976,0 1.988,-4.18 1.988,0 0,0 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path846"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 478.107,319.389 c -1.988,0 -1.988,0 0,0 z"
                fill={hearts}
                id="path848"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 90.4285,302.667 c 3.9762,0 7.9524,0 9.9405,4.18 0,0 0,0 1.988,0 h -1.988 c -1.9881,0 -3.9762,0 -5.9642,0 0,0 -1.9882,0 -3.9763,-4.18 0,4.18 1.9881,4.18 0,0 z"
                fill={hearts}
                id="path850"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 478.107,315.207 c -1.988,0 -1.988,0 -1.988,-4.18 -1.988,0 -3.976,0 -3.976,0 0,0 0,0 -1.989,0 0,0 -1.988,0 -3.976,0 h -5.964 c 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 0,0 0,-4.181 -1.988,0 h -3.977 c 0,0 0,0 -1.988,0 h -1.988 c 0,0 0,0 1.988,0 h -1.988 c 0,0 0,0 0,-4.181 0,0 0,0 1.988,0 0,0 0,0 -1.988,0 h 1.988 c 0,0 0,0 -1.988,0 h 1.988 1.988 c 0,0 1.988,0 3.977,0 0,0 0,0 1.988,0 h 1.988 c 0,0 0,0 1.988,0 0,0 1.988,0 3.976,0 h 1.988 c 0,0 0,0 1.988,0 0,0 0,0 1.988,0 0,0 0,0 1.988,0 0,0 0,0 1.989,0 h 1.988 c 0,4.181 0,4.181 0,8.361 0,0 0,0 -1.988,0 0,-4.18 0,-4.18 -1.989,-4.18 9.941,4.18 9.941,4.18 7.953,4.18 1.988,0 1.988,0 0,0 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path852"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 478.107,319.386 c -1.988,0 -1.988,0 -1.988,0 0,-4.18 0,0 1.988,0 -1.988,0 -1.988,0 0,0 0,-4.18 -1.988,0 0,0 z"
                fill={hearts}
                id="path854"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 108.322,306.848 c 1.988,0 1.988,0 0,0 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path856"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 94.4049,298.488 c -1.9881,0 -1.9881,0 0,0 -1.9881,0 -3.9762,0 0,0 -1.9881,0 -1.9881,0 0,0 1.9881,0 1.9881,0 1.9881,0 0,0 0,0 1.9881,0 0,0 1.9879,0 0,4.18 -1.9881,0 -1.9881,0 -3.9762,-4.18 1.9881,4.18 1.9881,4.18 1.9881,4.18 -1.9881,0 -1.9881,0 -1.9881,-4.18 0,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path858"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 114.286,302.664 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path860"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 104.346,302.664 c 0,-4.18 -1.988,0 0,0 0,-4.18 -1.988,-4.18 0,0 z"
                fill={hearts}
                id="path862"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 132.178,302.664 c 0,-4.18 -1.988,0 0,0 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path864"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 247.488,306.848 c 0,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path866"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 128.203,298.488 c 1.988,0 1.988,0 0,0 1.988,0 1.988,4.18 0,0 z"
                fill={hearts}
                id="path868"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 426.416,323.568 h -1.988 c 1.988,0 1.988,0 1.988,0 z"
                fill={hearts}
                id="path870"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 432.381,294.306 c 0,-4.18 0,-4.18 0,0 h 1.988 c 0,0 -1.988,-4.18 -1.988,0 z"
                fill={hearts}
                id="path872"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 434.369,290.127 c -1.988,-4.18 0,-4.18 0,0 z"
                fill={hearts}
                id="path874"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 404.547,302.667 c -1.988,4.18 0,4.18 0,0 z"
                fill={hearts}
                id="path876"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 414.488,298.484 c 1.988,0 1.988,-4.18 3.976,-4.18 0,0 0,0 -1.988,0 0,0 0,0 1.988,0 0,0 0,0 0,4.18 h 1.988 c 0,0 1.988,0 3.976,0 0,0 -3.976,0 -3.976,4.18 0,0 0,0 -1.988,0 0,0 0,0 -1.988,0 0,-4.18 -1.988,-4.18 -1.988,-4.18 z"
                fill={hearts}
                id="path878"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 426.417,306.848 c 0,-4.18 0,-4.18 0,0 0,-4.18 1.988,-4.18 0,0 0,-4.18 0,-4.18 0,0 1.988,0 0,-4.18 0,0 z"
                fill={hearts}
                id="path880"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 420.452,311.026 c 1.988,0 3.976,0 3.976,0 -1.988,0 -3.976,0 -3.976,0 z"
                fill={hearts}
                id="path882"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 434.369,311.026 c 1.988,0 3.976,0 5.964,0 -1.988,0 -5.964,0 -5.964,0 z"
                fill={hearts}
                id="path884"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 448.286,311.026 h 3.976 c 1.988,0 5.964,0 7.952,0 h -1.988 c -1.988,0 -3.976,0 -5.964,0 0,4.18 -1.988,0 -3.976,0 z"
                fill={hearts}
                id="path886"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 448.286,319.389 c 1.988,0 1.988,0 1.988,0 1.988,0 0,0 -1.988,0 z"
                fill={hearts}
                id="path888"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 450.274,315.205 c 1.989,0 1.989,0 0,0 z"
                fill={hearts}
                id="path890"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 450.274,298.488 c 1.989,0 1.989,0 0,0 z"
                fill={hearts}
                id="path892"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 454.25,298.488 c 1.988,0 1.988,0 0,0 z"
                fill={hearts}
                id="path894"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 402.559,306.846 c 1.988,0 3.977,0 3.977,0 1.988,0 3.976,0 5.964,0 h -3.976 c 0,4.181 -3.977,4.181 -5.965,0 z"
                fill={hearts}
                id="path896"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m 432.381,311.026 c -1.988,0 -3.976,0 -3.976,0 1.988,0 3.976,0 3.976,0 z"
                fill={hearts}
                id="path898"
              />
            </g>
            <path
              d="m 149.599,82.5836 c -5.75,-0.4041 -11.556,2.991 -13.634,8.4166 -1.524,3.9787 -1.129,7.3649 -0.54,9.3838 0.084,0.274 0.16,0.527 0.242,0.746 -0.231,-0.059 -0.529,-0.103 -0.813,-0.16 -0.536,-0.096 -1.131,-0.169 -1.865,-0.177 -2.411,-0.023 -4.792,0.619 -7.076,1.912 -4.397,2.497 -6.845,7.552 -6.576,12.538 0.096,1.781 0.536,3.556 1.361,5.197 1.027,2.024 2.547,3.701 4.531,4.992 2.17,1.411 4.828,2.315 7.904,2.671 3.374,0.4 7.236,0.15 11.501,-0.74 4.665,-0.972 9.841,-2.729 15.389,-5.22 0.073,-0.036 0.139,-0.079 0.184,-0.141 0.052,-0.055 0.09,-0.124 0.114,-0.206 1.626,-5.86 2.574,-11.246 2.838,-16.009 0.238,-4.344 -0.099,-8.197 -1.002,-11.4712 -0.818,-2.9876 -2.111,-5.4805 -3.835,-7.4129 -1.577,-1.7692 -3.466,-3.0226 -5.618,-3.728 -1.015,-0.3347 -2.065,-0.52 -3.105,-0.5913 z"
              fill={hearts}
              id="path912"
            />
            <path
              d="m 702.001,49.7468 c -2.143,-1.8955 -5.456,-2.3108 -7.913,-0.8007 -1.801,1.1076 -2.668,2.5609 -3.046,3.5344 -0.049,0.1329 -0.096,0.2556 -0.13,0.3667 -0.073,-0.093 -0.177,-0.2 -0.272,-0.3086 -0.182,-0.1993 -0.394,-0.4079 -0.681,-0.6327 -0.943,-0.7371 -2.075,-1.2029 -3.365,-1.3835 -2.486,-0.3435 -4.977,0.9088 -6.376,2.9546 -0.5,0.7307 -0.863,1.5629 -1.033,2.4584 -0.207,1.1076 -0.114,2.2272 0.278,3.3347 0.429,1.2116 1.203,2.3698 2.307,3.439 1.209,1.1762 2.806,2.2439 4.755,3.1806 2.131,1.0258 4.701,1.8964 7.639,2.5902 0.04,0.0077 0.078,0.0108 0.115,-1e-4 0.037,-0.0063 0.073,-0.0219 0.107,-0.0468 2.41,-1.8182 4.41,-3.6537 5.952,-5.4505 1.405,-1.6397 2.436,-3.2594 3.069,-4.8219 0.579,-1.4242 0.822,-2.7967 0.727,-4.0786 -0.088,-1.1731 -0.453,-2.2372 -1.088,-3.165 -0.299,-0.4383 -0.657,-0.8283 -1.045,-1.1702 z"
              fill={hearts}
              id="path914"
            />
            <path
              d="m 108.001,451.747 c -2.143,-1.896 -5.456,-2.311 -7.913,-0.801 -1.8013,1.108 -2.6681,2.561 -3.0456,3.535 -0.0495,0.132 -0.096,0.255 -0.13,0.366 -0.0731,-0.093 -0.1773,-0.2 -0.2722,-0.308 -0.182,-0.2 -0.3943,-0.408 -0.6811,-0.633 -0.943,-0.737 -2.0751,-1.203 -3.3651,-1.384 -2.4861,-0.343 -4.9769,0.909 -6.3763,2.955 -0.4998,0.731 -0.8628,1.563 -1.0331,2.458 -0.2065,1.108 -0.114,2.228 0.2782,3.335 0.4288,1.212 1.2032,2.37 2.3074,3.439 1.2084,1.176 2.8057,2.244 4.7545,3.181 2.1315,1.026 4.7015,1.896 7.6393,2.59 0.04,0.008 0.078,0.011 0.115,0 0.037,-0.007 0.073,-0.022 0.107,-0.047 2.41,-1.818 4.41,-3.654 5.952,-5.45 1.405,-1.64 2.436,-3.26 3.069,-4.822 0.579,-1.425 0.822,-2.797 0.727,-4.079 -0.088,-1.173 -0.453,-2.237 -1.088,-3.165 -0.299,-0.438 -0.657,-0.828 -1.045,-1.17 z"
              fill={hearts}
              id="path916"
            />
            <path
              d="m 1369.52,400.042 c -0.46,-2.824 -2.76,-5.241 -5.62,-5.625 -2.1,-0.281 -3.69,0.296 -4.6,0.812 -0.12,0.071 -0.23,0.137 -0.33,0.202 0,-0.119 -0.01,-0.268 -0.01,-0.412 -0.02,-0.269 -0.05,-0.565 -0.13,-0.92 -0.27,-1.168 -0.85,-2.245 -1.73,-3.2 -1.71,-1.839 -4.43,-2.444 -6.81,-1.744 -0.85,0.249 -1.66,0.665 -2.36,1.251 -0.86,0.727 -1.49,1.653 -1.89,2.759 -0.43,1.209 -0.57,2.596 -0.39,4.123 0.2,1.675 0.76,3.512 1.67,5.47 1.01,2.143 2.45,4.443 4.28,6.838 0.03,0.031 0.06,0.058 0.09,0.073 0.04,0.018 0.07,0.029 0.12,0.031 3.01,0.116 5.72,-0.041 8.05,-0.458 2.13,-0.381 3.95,-0.984 5.43,-1.794 1.35,-0.737 2.41,-1.646 3.14,-2.699 0.68,-0.964 1.07,-2.02 1.16,-3.14 0.05,-0.528 0.02,-1.057 -0.07,-1.567 z"
              fill={hearts}
              id="path918"
            />
            <path
              d="m 1347.97,83.3144 c -1.39,-7.7034 -7.8,-14.2079 -15.64,-15.1277 -5.75,-0.6734 -10.07,0.9796 -12.53,2.4309 -0.33,0.2015 -0.64,0.3865 -0.9,0.5675 0,-0.3238 -0.04,-0.7302 -0.06,-1.1242 -0.06,-0.7364 -0.16,-1.5441 -0.39,-2.513 -0.78,-3.181 -2.42,-6.0996 -4.89,-8.6734 -4.76,-4.9502 -12.23,-6.4809 -18.7,-4.4578 -2.31,0.7225 -4.5,1.8949 -6.38,3.53 -2.33,2.0291 -4.02,4.5906 -5.06,7.634 -1.13,3.3288 -1.43,7.1285 -0.87,11.296 0.6,4.5739 2.23,9.5737 4.82,14.8879 2.84,5.8148 6.89,12.0394 12.02,18.5074 0.08,0.084 0.15,0.156 0.25,0.194 0.09,0.05 0.19,0.077 0.31,0.081 8.26,0.178 15.66,-0.375 22.02,-1.622 5.8,-1.141 10.75,-2.874 14.76,-5.157 3.66,-2.078 6.51,-4.613 8.47,-7.5288 1.8,-2.6675 2.82,-5.5727 3.03,-8.6414 0.1,-1.4477 -0.01,-2.8917 -0.26,-4.2834 z"
              fill={hearts}
              id="path920"
            />
            <path
              d="m 146.282,899.577 c -1.711,-5.672 -7.092,-10.01 -13.063,-10.027 -4.379,-0.011 -7.488,1.605 -9.212,2.908 -0.232,0.18 -0.448,0.345 -0.629,0.504 -0.027,-0.243 -0.094,-0.545 -0.143,-0.84 -0.103,-0.549 -0.25,-1.147 -0.51,-1.856 -0.858,-2.325 -2.345,-4.378 -4.42,-6.1 -4.004,-3.312 -9.753,-3.819 -14.445,-1.741 -1.676,0.743 -3.221,1.813 -4.4966,3.205 -1.5697,1.725 -2.626,3.797 -3.1413,6.175 -0.5641,2.6 -0.4617,5.483 0.3184,8.568 0.8472,3.387 2.4973,7.007 4.9095,10.779 2.636,4.127 6.214,8.458 10.632,12.879 0.061,0.057 0.126,0.105 0.202,0.125 0.073,0.03 0.152,0.041 0.24,0.034 6.223,-0.577 11.744,-1.632 16.416,-3.117 4.26,-1.357 7.838,-3.087 10.654,-5.149 2.572,-1.877 4.495,-4.029 5.722,-6.391 1.124,-2.161 1.639,-4.433 1.531,-6.758 -0.049,-1.097 -0.255,-2.173 -0.565,-3.198 z"
              fill={hearts}
              id="path922"
            />
          </g>
        </g>
      </svg>
      <div style={{ position: "relative" }}>{children}</div>
    </div>
  );
};

BackgroundHearts.propTypes = {
  bg: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  hearts: PropTypes.string
}
export default BackgroundHearts;
