import React, {useState, useEffect} from "react";
import Card from "../../../../../shared/components/card";
import { Tooltip } from 'antd';
import './votingDetails.scss';
import { LockOutlined, InfoCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import classNames from 'classnames';

const VotingDetailsCard = ({ game }) => {
	const createdAt = new Date(game?.contest?.created_at)
	const launchAt = new Date('2022-11-01')
	const [votes, setVotes] = useState([])

	useEffect(() => {
		if(createdAt < launchAt){
			if(game?.vote_counts){
				setVotes([...game?.vote_counts])
			}
		}
		else{
			if(game?.vote_counts){
				game?.vote_counts?.length >= 3 ?
				setVotes([{no_of_votes: 1, voter_first_name: "Kim", voter_last_name: "at Villie"}, ...game?.vote_counts])
				: setVotes([...game?.vote_counts])
			}
		}
		
	}, [game])
	
	return (
		<>
			{ votes?.length > 0 &&
			<Card className="votingDetailsCard">
				<div className="data">
					<p className="header">
						<span>Player</span>
						<span>Votes</span>
					</p>
					<div>
						<ul className="list">
							{votes?.map((vote, index) => {
								return (
									<li key={"list-" + index} className="item-list">
										<div>
											<span className="id">
												{!(createdAt < launchAt) && vote?.voter_first_name == "Kim" ? votes?.length < 4 ? <LockOutlined className="locked"/> : <UnlockOutlined /> : `#${index+1}`} 
											</span> 
											<div className="circle">
												<span className={classNames("text", {"locked": !(createdAt < launchAt) && vote?.voter_first_name == "Kim" && votes?.length < 4})}>{vote?.voter_first_name?.substring(0,1)}</span>
											</div> 
											<span className={classNames("name", {"locked": !(createdAt < launchAt) && vote?.voter_first_name == "Kim" && votes?.length < 4})}>
												{`${vote?.voter_first_name} ${vote?.voter_last_name}`}
												{!(createdAt < launchAt) && vote?.voter_first_name == "Kim" && 
													<><br /><small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Congratulations, now this money is yours.</small></>
													// <Tooltip placement="top" title={votes?.length < 4 ? "To keep this play from us, get 3 more!" : }>
													// 	<InfoCircleOutlined style={{cursor: "pointer"}}/>
													// </Tooltip>
												}
											</span>
										</div>
										<span className={classNames("amount", {"locked": !(createdAt < launchAt) && vote?.voter_first_name == "Kim" && votes?.length < 4})}>{vote?.no_of_votes}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</Card>
			}
		</>
	)
}

export default VotingDetailsCard;