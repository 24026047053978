import "./noData.scss"
import React from 'react';
import { Empty } from 'antd';

const NoData = ({description, image, styles}) => {
    return(
        <Empty 
            className='noData'
            image={image} 
            description={description} 
            style={{...styles}}
        />
    );
}

export default NoData;