import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import UploadImages from "../../../../../components/UploadImages/UploadImages";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import { tooltips } from "../../../../../shared/utils/constants";
import { Col, Row } from 'antd';
import Input from "../../../../../shared/components/input";
import DatePicker from "../../../../../shared/components/datePicker"
import TextArea from "../../../../../shared/components/textArea"
import DonationBtns from "./../../components/donationBtn"
import RadioField from "../../../../../components/RadioField/RadioField";
import classNames from "classnames";
import useWindowSize from "../../../../../shared/hooks/use-window-size";

const GameInformationStep = ({ data = {}, form, donationMoney, setDonationMoney, checkTerms, onChange = () => { }, onBack = () => { } }) => {
    const [gameTermsError, setGameTermsError] = useState(false);
    const {width} = useWindowSize()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <h2>Time for the Nitty Gritty!</h2>
            <Row gutter={[100, 20]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Input
                        label="Game Name"
                        name="game_name"
                        placeholder="Enter game name"
                        required
                        errorMessage={"* Not so fast! We can’t continue until you choose a game name."}
                        tooltip={tooltips.gameName.text}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Game Prize"
                        name="game_prize"
                        placeholder="Enter game prize"
                        required
                        errorMessage={"* Oh no! We can’t play until you make a choice. Please select your game prize!"}
                        tooltip={tooltips.contestPrize.text}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <DatePicker
                        label="Contest End Date"
                        name="contest_end_date"
                        required
                        errorMessage={"* Don’t forget to enter your contest end date!"}
                        tooltip={tooltips.contestEndDate.text}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <DonationBtns
                        tooltip={tooltips.costPerVote.text}
                        setDonationMoney={setDonationMoney}
                        donationMoney={donationMoney}
                        form={form}
                    />
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} xl={0}></Col>
                <Col xs={24} sm={24} md={0} lg={0} xl={0}></Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <TextArea
                        label="Share Your Story!"
                        name="share_your_story"
                        placeholder={tooltips.welcomeText.text}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <UploadImages
                        full={true}
                        name="Upload Images"
                        value={data.upload_images}
                        onChange={onChange}
                        tooltip={'Upload  a pic of your sonogram, baby bump, or a cute image of you and your family. People are more likely to play when they feel connected to YOU.'}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <RadioField
                        id="terms"
                        label={
                            <span>I agree to the&nbsp;
                                <span 
                                    style={{textDecoration: "underline"}}
                                    onClick={checkTerms}
                                >
                                    terms and conditions
                                </span> 
                            &nbsp;and opt-in to receive future emails</span>
                        }
                        value={gameTermsError}
                        onClick={()=>{
                            setGameTermsError(!gameTermsError)
                        }}
                        defaultChecked={gameTermsError}
                        checked={gameTermsError}
                        showRed
                    />
                </Col>
                { 
                    width > 450 && 
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <ButtonDefault secondary back onClick={onBack}>Back - To the Basics</ButtonDefault>
                    </Col>
                }
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <ButtonDefault
                        primary={true}
                        next={true}
                        type="submit"
                        className={classNames("gameInfoNextStepBtn", {"primaryDisabled": !gameTermsError})}
                        disabled={!gameTermsError}
                        styles={{background: "red"}}
                    >
                        Finish and Play!
                    </ButtonDefault>
                </Col>
                {
                    width < 450 && 
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <ButtonDefault secondary back onClick={onBack}>Back - To the Basics</ButtonDefault>
                    </Col>
                }
            </Row>
        </>
    )
};
GameInformationStep.propTypes = {
    data: PropTypes.object,
    onBack: PropTypes.func,
    onChange: PropTypes.func.isRequired
}
export default GameInformationStep;