import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
    },
    reducers: {
        setUser:(state,action) => {
            state.user = action.payload.user;
        },
        resetUserState: (state) => {
            state.user = null
        },
    },
});


export const getUser = (state) => state.user.user;

export const { setUser, resetUserState } = userSlice.actions;

export default userSlice.reducer;
