import "./shareGame.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import Modal from "../../../../../shared/components/modal";
import { app } from "../../../../../shared/config";
import { toast } from "react-toastify";
import Spinner from "../../../../../shared/components/spinner";
import { BaseAPI } from "../../../../../shared/services/BaseAPI";
import classNames from "classnames";
import { Typography, Col, Row, Checkbox } from 'antd';
import Card from "../../../../../shared/components/card";
import { useDispatch } from "react-redux";
import { setSeletedGame } from "../../../../../shared/redux/game/gameSlice";

const { Paragraph } = Typography;

const ShareGame = ({ game, title = "Spread the Love!", fromEndGame = false, fromDashBoard = false }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const [visible, setVisibile] = useState(false);
	const [action, setAction] = useState(0);
	const [isLoading, setLoading] = useState(false)

	const[acknowledge, setAcknowledge] = useState(false)
	const [gender, setGender] = useState(null)
	const [genderIsLoading, setGenderIsLoading] = useState(false)
	const handleAction = (action) => {
		if (!!game?.contest?.voting_ended && action === 2) {
			return moveToCashOut()
		}
		setAction(action);
		setVisibile(!visible);
	};

	const moveToCashOut = () => {
		navigate("/dashboard/end-game");
		setVisibile(false);
	};

	const handleCopyUrl = (url) => {
		if (navigator.clipboard.writeText(url)) {
			return toast.success("Url copied successfully");
		}
		return toast.error("Could not copy URL");
	};

	const handleDelete = async () => {
		setLoading(true)
		try{
			const response = await BaseAPI.del(`game/${game?.contest?.id}`)
			setLoading(false)
			setVisibile(!visible);
			if(response.status === 200){
				window.location.replace("https://www.villie.co/genderrevealgame-home")
			}
		}
		catch(err){
			setLoading(false)
			setVisibile(!visible);
			if(err?.response?.data?.message === "Game not found."){
				return toast("Opps, you cann't delete this game", {position: "top-center"})
			}
			toast(err?.response?.data?.message, {position: "top-center"})
		}
	}

	const btnsDisabled = () => {
		return !!(game?.contest?.voting_ended)
	}

	const handleAcknowlegment = (e) => {
		setAcknowledge(e.target.checked)
	};

	const handleChooseGender = async () => {
		setGenderIsLoading(true)

		const payload = {
			baby_gender: gender === "boy" ? 0 : 1
		}

		try{
			const response = await BaseAPI.post(`game/winner/${game?.contest?.id}`, payload)

			if (response.status === 200) {
				const res = await BaseAPI.get(`game/${game?.contest?.id}`)
				if(res.status === 200){
					dispatch(setSeletedGame({ selectedGame: res?.data?.data }))
					navigate("/dashboard/end-game");
				}
			}
		}
		catch(err){
			toast.error("There is an error. Please try later.", { position: "top-center" })
		}
		setGenderIsLoading(false)
	}

	return (
		<div className="buttons-box">
			{
				fromEndGame ?
					<p className="endGameTitle">Other</p>
					:
					<p className="title">
						<img alt="icon" src="../assets/heart.svg" /> {title}
					</p>
			}


			<ButtonDefault primary onClick={() => { handleAction(3) }}>Share this Game!</ButtonDefault>
			{
				fromDashBoard &&
				<>
					{!btnsDisabled() && <ButtonDefault secondary onClick={() => { navigate("/dashboard/edit-game") }}>Edit Game</ButtonDefault>}
					<ButtonDefault
						secondary
						onClick={() => { navigate("/dashboard/add-update") }}
						className={classNames({ 'width100': btnsDisabled() })}
					>
						Add Baby Update
					</ButtonDefault>
					<ButtonDefault
						secondary
						onClick={() => navigate(`/play-game?gameId=${game?.contest?.id}`)}
						className={classNames({ 'width100': btnsDisabled() })}
					>
						Guest View
					</ButtonDefault>
					{!btnsDisabled() && <ButtonDefault secondary onClick={() => { handleAction(1) }}>Delete Game</ButtonDefault>}
					<ButtonDefault primary onClick={() => { handleAction(2) }}>End Game and Start Cash Out</ButtonDefault>
				</>
			}
			{
				fromEndGame &&
				<>
					<ButtonDefault onClick={() => navigate(`/play-game?gameId=${game?.contest?.id}`)} className="bgGray">Guest View</ButtonDefault>
				</>
			}


			<Modal visible={visible} handleClose={() => setVisibile(false)}>
				{action === 1 && (
					<div>
						<p className="title">Delete Game?</p>
						<p className="description">
							Are you sure you want to delete your game? Deleted games cannot recover and all money will be returned to the players within 5-7 business days.
						</p>
						<ButtonDefault
							onClick={handleDelete}
							danger
						>
							{isLoading ? <Spinner forBtn={true} /> : "Yes, Delete This Game"}
						</ButtonDefault>
					</div>
				)}
				{action === 2 && (
					game?.vote_counts?.length === 0 ?
					<div>
						<p className="noVoteTitle">Want to cash out?</p>
						<p className="noVoteParagraph">Share your game and receive 3 additional votes to cash out Villie's ${game?.contest?.min_cost_per_vote}</p>
					</div>
					:
					<div>
						<p className="endGameModalTitle">Select the gender</p>
						<Row justify="space-around" align="middle">
							<Col span={12}>
								<Card className={classNames("imageCard", {"boySelected": gender === "boy"})} action={
									() => {
										setGender("boy")
									}
								}>
									<img src="../assets/blue-bear.svg" alt="bear" />
									<br />
									<Paragraph className="boy">Boy</Paragraph>
								</Card>
							</Col>
							<Col span={12}>
								<Card className={classNames("imageCard", {"girlSelected": gender === "girl"})} action={
									() => {
										setGender("girl")
									}
								}>
									<img src="../assets/pink-bear.svg" alt="bear" />
									<br />
									<Paragraph className="girl">Girl</Paragraph>
								</Card>
							</Col>
						</Row>
						<Checkbox style={{margin: "2% 0 5% 3%"}} onChange={handleAcknowlegment}>I acknowledge that "Once I will choose the gender, the game is over and cannot be replayed."</Checkbox>
						<ButtonDefault 
							className={!acknowledge || !gender ? "genderBtnDisabled" : ""} 
							danger 
							disabled={!acknowledge || !gender} onClick={handleChooseGender}>
								{genderIsLoading ? <Spinner forBtn={true} /> : "Yes, I’m Sure"}
						</ButtonDefault>
					</div>
				)}
				{action === 3 && (
					<div>
						<p className="title">{`${game?.contest?.name}!`}</p>
						<ButtonDefault
							primary
							onClick={() => handleCopyUrl(`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`)}
						>
							<img src="../assets/login.png" alt="" />
							Copy URL To Share
						</ButtonDefault>
					</div>
				)}
			</Modal>
		</div>
	);
};

export default ShareGame;