import "./gameCompleted.scss";
import React, {useState, useEffect} from "react";
import PieChart from "../../../../../shared/components/pieChart"
import { calculateVoterPercentage } from "../../../../../shared/utils/helper";
import { getGender } from "../../../../../shared/utils/helper";
const GameCompleted = ({ game }) => {
	const [votesCount, setVotesCount] = useState(null)
	const createdAt = new Date(game?.contest?.created_at)
	const launchAt = new Date('2022-11-01')

	useEffect(() => {
		if(createdAt < launchAt){
			if(game?.summary){
				setVotesCount({boy: game?.summary?.boy_votes, girl: game?.summary?.girl_votes})
			}
		}
		else{
			if(game?.summary){
				if(game?.summary?.boy_votes + game?.summary?.girl_votes < 3){
					setVotesCount({boy: game?.summary?.boy_votes, girl: game?.summary?.girl_votes})
				}
				else{
					const temp = getGender(game?.contest?.name) == 0 ? {boy: game?.summary?.boy_votes + 1, girl: game?.summary?.girl_votes} : {boy: game?.summary?.boy_votes, girl: game?.summary?.girl_votes + 1}
					setVotesCount(temp)
				}
			}
		}
		
	}, [game])

	return (
		<div className="gameCompleted">
			<div className="information-sidebar">
				<img
					className="decoration"
					src="../assets/pink-bear.svg"
					alt="decoration"
				/>
				<p className="title">Surprise! We’re Having A ….</p>
				<div className="box">
					<div className="details">
						<img src={game?.contest?.baby_gender == 0 ? "../assets/blue-bear.svg" : "../assets/pink-bear.svg"} alt="icon-btn" />
						<span style={{ color: game?.contest?.baby_gender == 0 ? "#5263FF" : "#CC8DFF" }}>It’s A {game?.contest?.baby_gender == 0 ? "Boy" : "Girl"}</span>
					</div>
				</div>
				<p className="title">Winner</p>
				<div className="box">
					<div className="details">
						<span className="winner">{!!game?.winner?.voter_first_name? `${game?.winner?.voter_first_name} ${(game?.winner?.voter_last_name)?.substring(0,1)}.` : 'Sorry! No one guessed the correct gender.'}</span>
					</div>
				</div>

				<p className="title">This Village Raised …</p>
				<div className="percentaje-details">
					{
						!(votesCount?.boy === 0 && votesCount?.girl === 0) &&
						<div className="chart">
							<PieChart data={[
								{ name: "Girl", value: calculateVoterPercentage(votesCount?.boy, votesCount?.girl, "girl"), color: "#D9ABFE" },
								{ name: "Boy", value: calculateVoterPercentage(votesCount?.boy, votesCount?.girl, "boy"), color: "#5263FF" },
							]}/>
						</div>
					}
					<div className="details">
						<p className="title">Plays</p>
						<p className="subtitle">{parseInt(votesCount?.boy) + parseInt(votesCount?.girl)}</p>
						<p className="description">{votesCount?.girl} Girl{votesCount?.girl > 1 && 's'} | {votesCount?.boy} Boy{votesCount?.boy > 1 && 's'} </p>
					</div>
					
				</div>

				<div className="statsBox">
					<div className="details">
						<div>
							<p className="head">Money Raised</p>
							<p className="desc">${createdAt < launchAt ? game?.summary?.money_raised : (votesCount?.boy + votesCount?.girl <= 3) ? game?.summary?.money_raised : game?.summary?.money_raised + game?.contest?.min_cost_per_vote}</p>
						</div>
						<div>
							<p className="head">Prize</p>
							<p className="desc">{game?.contest?.prize_name}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};


export default GameCompleted;
