class FacebookPixels {

    track = (eventName, params) => {
        if (typeof window !== 'undefined') {
            window.fbq('track', eventName, { ...params });
        }
    }
    
}

export const FacebookPixel = new FacebookPixels()