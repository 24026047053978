import React from "react";
import BackgroundHearts from '../../../../components/BackgroundHearts.js';
import ButtonDefault from '../../../../components/ButtonDefault/ButtonDefault.js';
import InputField from '../../../../components/InputField/InputField.js';
import './forgotPassword.scss';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Spinner from "../../../../shared/components/spinner/index.jsx";
import { toast } from "react-toastify";
import useWindowSize from "../../../../shared/hooks/use-window-size.js";
import { validateEmail } from "../../../../shared/utils/helper.js";
import { BaseAPI } from "../../../../shared/services/BaseAPI.js";
import {Helmet} from "react-helmet";

const ForgotPassword = () => {
	const [forgotPasswordData, setForgotPasswordData] = useState({ email: '' });
	const [linkSent, setLinkSent] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const navigate = useNavigate();
	const [forgotPasswordError, setForgotPasswordError] = useState(null);
	const { width } = useWindowSize()

	const sendForgotPasswordLink = async () => {
		if (!validateEmail(forgotPasswordData.email)) {
			return setForgotPasswordError('Please enter a valid email address');
		}
		setIsLoading(true)
		const payload = {
			"email": forgotPasswordData.email,
			"product_type": "grg"
		}
		try{
			const response = await BaseAPI.post(`auth/forgetPassword`, payload)
			setIsLoading(false);
			if(response.status === 200){
				setLinkSent(true)
			}
		}
		catch(err){
			setIsLoading(false);
			toast.error(err.response.data.message, { position: "top-center" });
		}
	};

	return (
		<BackgroundHearts className="bg-register" bg="#d6daff" hearts="#B7BEFD">
			<Helmet>
				<title>{`GRG - Forgot Password`}</title>
			</Helmet>
			<div className="forgot-screen">
				<div className="frame">
					<div className="forgot-password">
						<a href="https://www.villie.co/genderrevealgame-home"  target="_blank" rel="noopener noreferrer">
							<img className="logo" src={`../assets/logo.png`} alt="logo" />
						</a>
						{
							width <= 450 &&
							<p className="back-btn" onClick={() => navigate("/")}>
								<img src={`../assets/back.svg`} alt="icon" />
							</p>
						}
						{
							linkSent === false ?
								<>

									<h1>Enter your email</h1>
									<p className="subtext">We will send you an email with a link<br />to reset your password</p>
									<form style={{ maxWidth: '353px' }} onSubmit={(e) => { e.preventDefault() }}>
										<InputField
											className={!!forgotPasswordError ? "error-input-field" : "input-field"}
											name="Email address"
											hasError={!!forgotPasswordError}
											value={forgotPasswordData.email}
											icon={!!forgotPasswordError ? '../assets/profile-error.png' : '../assets/profile.png'}
											onChange={(val) => {
												setForgotPasswordError(null)
												setForgotPasswordData({ email: val.value })
											}}
											label={false}
										/>
										{!!forgotPasswordError && <div className="error-msg">
											{forgotPasswordError}
										</div>}
										<ButtonDefault onClick={sendForgotPasswordLink} primary={true}>
											{isLoading ? <Spinner forBtn={true} /> : "Send Link"}
										</ButtonDefault>
									</form>
								</>
								:
								<>
									<h1>Password reset<br />link sent!</h1>
									<p className="subtext">Now check your email address<br />and follow the link</p>
									<p className="spam-text">If you didn&apos;t recieve the e-mail<br />check your spam filter</p>
								</>
						}
						{
							width > 450 &&
							<p className="back-btn" onClick={() => navigate("/")}>
								<img src={`../assets/back.svg`} alt="icon" />
							</p>
						}
					</div>
					{
						width > 450 &&
						<div className="information">
							<img
								className="couple-img"
								src={"../assets/forgotPassword.png"}
								alt="couple"
							/>
						</div>
					}
				</div>
			</div>
		</BackgroundHearts>
	);
};

export default ForgotPassword