import React from "react";
import "./Footer.scss";

export const Footer = ({style}) => {
  return (
    <footer style={{...style}}>
      <div className="container">
        All Rights Reserved 2023 ® Gender Reveal Game | <a href="https://www.villie.co/grg-privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a href="https://www.villie.co/grg-terms" target="_blank" rel="noopener noreferrer">Terms &
        Conditions</a>
      </div>
    </footer>
  );
};
