import React from "react";
import {Routes, Route} from "react-router-dom";

import EditGame from "../modules/games/host/page/editGame";
import UpdateGame from "../modules/games/host/page/updateGame";
import EndGame from "../modules/games/host/page/endGame";
import Dashboard from "../modules/games/host/page/dashboard";
import Completed from "../modules/games/player/page/completed";

export const DashboardRouter = () => {
    return (
    <Routes>
      <Route>
        <Route index element={<Dashboard />} />
        <Route path="add-update" element={<UpdateGame />} />
        <Route path="end-game" element={<EndGame />} />
        <Route path="completed" element={<Completed/>} />
        <Route path="edit-game" element={<EditGame />} />
      </Route>
    </Routes>
  );
}