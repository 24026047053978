import React, { useEffect, useState } from 'react';
import BackgroundHearts from '../../../../shared/components/backgroundHearts';
import ButtonDefault from '../../../../components/ButtonDefault/ButtonDefault.js';
import './confirmation.scss';
import { useNavigate } from "react-router-dom";
import { useDispatch, batch } from 'react-redux';
import { Footer } from '../../../../components/Footer/Footer';
import { setSeletedGame, setShareModalVisibility } from '../../../../shared/redux/game/gameSlice.js';
import { Col, Row } from 'antd';
import GamesSlider from '../../components/gamesSlider';
import { toast } from "react-toastify";
import { BaseAPI } from '../../../../shared/services/BaseAPI';
// import { GoogleAnalytics } from '../../../../shared/services/google-analytics';
import {Helmet} from "react-helmet";

const Confirmation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const [games, setGames] = useState(null);
	const [continueGame, setContinueGame] = useState(false)

	useEffect(() => {
		getUserGames();
	}, [])
	
	const getUserGames = async () => {
		try{
			const response = await BaseAPI.get("game/")
			if(response.status === 200){
				setGames(response.data.data);
			}
		}
		catch(err){
			toast.error(err.response.data.message, { position: "top-center" });
		}
	}

	const handleEnterGame = (game) => {
		batch(() => {
			dispatch(setSeletedGame({selectedGame: {"contest": game}}))
			dispatch(setShareModalVisibility({ showModal: game?.voting_ended ? false : true }))
		})
		setContinueGame(false)
		navigate('/dashboard')
	}

	useEffect(() => {
		if(games){
			const url = new URL(window.location.href);
			const fromNavbar = url.searchParams.get("navbar");
			if(!!fromNavbar){
				if(games.length > 0){
					setContinueGame(true)
				}
			}
		}
	}, [games])

	return (
		<BackgroundHearts>
			<Helmet>
				<title>{`GRG - Confirmation`}</title>
			</Helmet>
			<div className="confirmation-screen">
				<div className="frame">
					<Row>
						{ 
							!continueGame && 
							<Col span={24}>
								<img src="../assets/bears.png" alt="" />
							</Col>
						}
						<Col span={24}>
							{
								continueGame? 
								<h1>Choose One Of The<br/>Current Game</h1>
								:
								<h1>Cue the Confetti. <br/>It’s Time To Party!</h1>
							}
						</Col>
						<Col span={24}><p>Hey parents, here’s a tip!<br/>
							Research shows that parents who share their game at least once a day for the first week raise 400% more than parents who don’t! <br/>
							</p></Col>

						{ 
							!continueGame ?
							<Col span={24} className="btns">
								{ games?.length > 0 && <ButtonDefault className="continueBtn" primary onClick={() => setContinueGame(true)}>Continue Game</ButtonDefault>}
								<ButtonDefault 
									primary={!(games?.length > 0)} 
									onClick={() => {
										navigate('/create-game');
										// GoogleAnalytics.event({
										// 	event_name: `game_creation_step_1`,
										// })
									}}
								>
									Create A New Game
								</ButtonDefault>
							</Col>
							:
							<GamesSlider games={games} handleEnterGame={handleEnterGame}/>
						}
						<Col span={24} className="footer">
							<Footer />
						</Col>
					</Row>
				</div>
			</div>
		</BackgroundHearts>
	)
}

export default Confirmation;