import "./styles.scss"
import React from 'react';
import { Form, Input } from 'antd';

const CustomTextArea = ({label, name, placeholder}) => {
    return (
        <Form.Item 
            label={label}
            className="formItem" 
            name={name}
        >
            <Input.TextArea
                className='textArea'
                rows={5}
                spellCheck={false}
                placeholder={placeholder} />
        </Form.Item>
    );
}

export default CustomTextArea;