import GameHost from '../../../host/page/editGame';
import SidebarCompleted from '../../../../../components/SidebarCompleted/SidebarCompleted';
import {Helmet} from "react-helmet";
import './completed.scss';

const Completed = () => {
  return (
    <>
      <Helmet>
        <title>{`GRG - Completed Game`}</title>
      </Helmet>
      <div className="Completed container">
        <div className="frame">
          <GameHost />
          <SidebarCompleted />
        </div>
      </div>
    </>
   
  );
};

export default Completed;