import React, {useEffect} from "react";
import BackgroundHearts from "../../../../components/BackgroundHearts.js";
import ButtonDefault from "../../../../components/ButtonDefault/ButtonDefault.js";
import RadioField from "../../../../components/RadioField/RadioField.js";
import "./register.scss";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAccessToken, setAccessToken } from "../../../../shared/redux/auth/authSlice.js";
import { setUser } from "../../../../shared/redux/user/userSlice.js";
import { toast } from "react-toastify";
import Spinner from "../../../../shared/components/spinner/index.jsx";
import useWindowSize from "../../../../shared/hooks/use-window-size.js";
import { batch, useSelector, useDispatch } from 'react-redux';
import { Form, Input } from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import { BaseAPI } from "../../../../shared/services/BaseAPI.js";
// import { GoogleAnalytics } from "../../../../shared/services/google-analytics.js";
import { setDrawerVisibility } from "../../../../shared/redux/dynamicDrawer/dynamicDrawerSlice.js";
import { setSeletedGame } from "../../../../shared/redux/game/gameSlice.js";
import { FacebookPixel } from "../../../../shared/services/facebook-pixel.js";
import {Helmet} from "react-helmet";

const Register = () => {
	const [terms, setTerms] = useState(false)
	const accesstoken = useSelector(getAccessToken)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errors, setErrors] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const { width } = useWindowSize()

	useEffect(() => {
		if (accesstoken) {
			navigate("/dashboard")
		}
	}, [])


	const handleRegister = async (values) => {
		if(values?.password?.trim() === ""){
			return setErrors({ password: "Please also add at least one character other than space" })
		}

		if(!terms){
			return setErrors({ terms: "Please accept the terms and conditions to proceed."})
		}
		const payload = {
			"email": values.email,
			"password": values.password,
			"product_type": "grg"
		}
		try{
			
			setIsLoading(true)
			const response = await BaseAPI.post(`auth/register`, payload)
			setIsLoading(false);
			if(response.status === 200){

				FacebookPixel.track("CompleteRegistration", {
					email: values?.email,
					value: 0,
      				currency: ''
				})
				const { accessToken, user } = response.data.data

				// GoogleAnalytics.event({
				// 	event_name: `sign_up`,
				// 	params: {
				// 		user_id: user?.id,
				// 		u_email: values.email,
				// 	}
				// })

				batch(() => {
					dispatch(setAccessToken({ accessToken: accessToken }))
					dispatch(setUser({ user: user}))
					dispatch(setDrawerVisibility({showSidebar: false}))
					dispatch(setSeletedGame({selectedGame: null}))
				})

				navigate("/dashboard");
			}
		}
		catch(err){
			setIsLoading(false)
			if (err.response.data.message.includes("User already has grg account.")) {
				setErrors({ accountExists: "1" })
				toast.error("User already exists.", { position: "bottom-right" });
			}
			else if (err.response.data.message.includes("User already has village account.")) {
				setErrors({ accountExists: "2" })
				toast.error("User already exists.", { position: "bottom-right" });
			}
			else{
				toast.error(err.response.data.message, { position: "bottom-right" });
			}
		}
	}

	const handleError = () => {
        if(errors){
            setErrors(null)
        }
    }

	return (
		<BackgroundHearts className="bg-register" bg="#d6daff" hearts="#B7BEFD">
			<Helmet>
				<title>{`GRG - Register`}</title>
			</Helmet>
			<div className="register-screen">
				<div className="frame">
					<div className="information">
						<div className="contain">
							<a href="https://www.villie.co/genderrevealgame-home"  target="_blank" rel="noopener noreferrer">
								<img src={`../assets/logo.png`} alt="logo" />
							</a>
							<h1>Let's Start Your Game</h1>
							<ul className="list">
								<li className="item-list">
									Ready to take your gender reveal to the next level?
								</li>
								<li className="item-list">
									Raise money for your little one's needs.
								</li>
								<li className="item-list">
									Anyone anywhere in the world can play.
								</li>
							</ul>
						</div>
						{
							width > 450 &&
							<img
								className="couple-img"
								src={"../assets/register.jpg "}
								alt="couple"
							/>
						}
					</div>
					<div className="register">
						<Form
                            name="register"
                            layout="vertical"
                            style={{ maxWidth: '353px'}}
                            onFinish={handleRegister}
                        >

							{
								width > 450 &&
								<>
								<br />
								</>
							}
							{
								errors?.accountExists === "1" &&
								<p className="details" style={{marginTop: width > 450 ? "10px" : "0px"}}>
									Hey! We already have your email on file. <Link to="/">Login</Link> to get back into your account or click here if you <Link to="/forgot-password">Forgot Your Password?</Link>
								</p>
							}
							{
								errors?.accountExists === "2" &&
								<p className="details" style={{marginTop: width > 450 ? "10px" : "0px"}}>
									Hey! You already have an account on village with this email. So no need to register again just <Link to="/">Login Here</Link> or click if you <Link to="/forgot-password">Forgot Your Password?</Link>
								</p>
							}
							<Form.Item
                                label={"Email address"}
                                name={"email"}
                                rules={[
									{required: true, type: "email", message: "Email not valid."}
								]}
                                className="formItem"
                            >
                                <Input className='input' prefix={<UserOutlined />} placeholder={"Enter email address"} onChange={handleError}/>
                            </Form.Item>

							<Form.Item
                                label="Create a password"
                                name="password"
                                className="formItem"
                                rules={[
									{
										required: true,
										min: 8,
										message: "Please make sure your password is at least 8 characters."
									},
								]}
                            >
                                <Input.Password className='input' prefix={<LockOutlined />} placeholder="Enter password" onChange={handleError}/>
                            </Form.Item>
							{!!errors?.password && <div className="error-msg"> *  {errors?.password}</div>}
							<RadioField
								id="r-terms"
								value={terms}
								label={
									<div>
										<span>I agree to the </span>
										<a target='_blank' href='https://www.villie.co/grg-terms'><u>terms and conditions</u></a>
									</div>
								}
								onClick={() => {
									setErrors({})
									setTerms(!terms);
								}}
								checked={terms}
							/>
							{!!errors?.terms && <div className="error-msg">{errors?.terms}</div>}
							<Form.Item>
								<ButtonDefault
									// disabled={!terms}
									type="submit"
									// className={classNames({ 'primaryDisabled': !terms })}
									primary
								>
									{isLoading ? <Spinner forBtn={true} /> : "Register My Account"}
								</ButtonDefault>
                            </Form.Item>

							<div style={{ textAlign: "center" }}>
								<p className="details">
									Already Have An Account? <Link to="/" style={{ textDecoration: "underline" }}>Login Here</Link>
								</p>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</BackgroundHearts>
	);
};

export default Register