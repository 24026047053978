import React from "react";
import { toast } from "react-toastify";
import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";
import "./shareUrl.scss";

const ShareUrl = ({ text = "", url = "" }) => {
  
    const handleClick = () => {
        if (navigator.clipboard.writeText(url)) {
            return toast.success("Url copied successfully");
        }
        return toast.error("Could not copy URL");
    };

  return (
    <div className="present-url">
        <p>{text}</p>
        <ButtonDefault secondary onClick={handleClick}>
            Copy URL
            <img className="icon-btn" src="../assets/login.png" alt="" />
        </ButtonDefault>
    </div>
  );
};

export default ShareUrl
