import React, { useState, useEffect } from 'react';
import { Image, Typography } from 'antd';
import { advices } from '../../utils/constants';
import "./spinner.scss";

const {Paragraph} = Typography
const Spinner = ({styles, forBtn = false, showAdvice = false}) => {
    const [currentAdvice, setCurrentAdvice] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAdvice((v) => (v === 3 ? 0 : v + 1));
        }, 5000);
        return () => clearInterval(interval);
      }, []);

    return(
        <>
            {
                forBtn?
                    <div className="spinnerForBtn" style={{...styles}}>
                        <Image preview={false} className="image" src="../assets/spinner.gif"/>
                    </div>
                    :
                    <div className="spinner" style={{...styles}}>
                        <Image preview={false} className="image" src="../assets/spinner.gif"/>
                        {
                            showAdvice && 
                            <>
                                <Paragraph className='heading'>Game Tip</Paragraph>
                                <Paragraph className='advice'>{advices[currentAdvice]}</Paragraph>
                            </>
                        }
                    </div>
            }
            
        </>
    )
}


export default Spinner;