import React from 'react'
import './ButtonDefault.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonDefault = ({ children, onClick, primary, secondary, danger, next, back, className='', disabled , type}) => {
  const btnClass = classNames({
    'button-default': true,
    'primary': primary,
    'secondary': secondary,
    'danger': danger,
    'next': next,
    'back': back
  })
  return (
    <button className={btnClass + ' ' + className} onClick={ onClick } disabled={disabled}>
      { children }
    </button>
  );
};

ButtonDefault.propTypes = {
  back: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  next: PropTypes.bool,
  onClick: PropTypes.func,

};

export default ButtonDefault;