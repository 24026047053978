import './updateGame.scss';
import React, { useState } from "react";
import TextAreaField from "../../../../../components/TextAreaField/TextAreaField";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import Layout from "../../../../../shared/components/layout";
import Card from "../../../../../shared/components/card";
import { Col, Row } from 'antd';
import {useSelector} from 'react-redux';
import { getSeletedGame } from '../../../../../shared/redux/game/gameSlice';
import { stopEventPropagation } from '../../../../../shared/utils/helper';
import Spinner from '../../../../../shared/components/spinner';
import {useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { BaseAPI } from '../../../../../shared/services/BaseAPI';
import {Helmet} from "react-helmet";

const UpdateGame = () => {
	const navigate = useNavigate()
	const game = useSelector(getSeletedGame)
	const initState = {text: '',upload_images: []}
	const [dataForm, setDataForm] = useState(initState);
	const [isLoading, setIsLoading] = useState(false);

	const changeForm = (e) => {
		setDataForm({
		...dataForm,
		[e.id]: e.value
		})
	}

	const addUpdates = async (e) => {
		stopEventPropagation(e)
		setIsLoading(true);

		const payload = {
			"message": dataForm.text,
		}
		try{
			const response = await BaseAPI.post(`game/${game?.contest?.id}/updates`, payload)
			setIsLoading(false);
			if(response.status === 200){
				navigate('/dashboard');
			}
		}
		catch(err){
			setIsLoading(false);
			toast.error(err.response.data.message, { position: "top-center" });
		}
	}

  return (
    <Layout>
		<Helmet>
			<title>{`GRG - Update Game`}</title>
		</Helmet>
      	<Card className="updateCard">
      		<Row gutter={[20,20]}>
				<Col xs={24} sm={24} md={15} lg={15} xl={15}>
					<div className="AddUpdate">
						<h1>Add Update</h1>
						<form>
						<TextAreaField id="text" name="Keep your players updated on your bundle of joy’s progress." value={dataForm.text} onChange={changeForm} placeholder="Enter welcome text to welcome players and tell them about yout story...." />
						<ButtonDefault primary 
							onClick={addUpdates}
						>
							{isLoading? <Spinner forBtn={true}/> : "Baby Update"}
						</ButtonDefault>
						</form>
					</div>
				</Col>
			</Row>
      	</Card>
    </Layout>
  )
}

export default UpdateGame;