import React, {useState} from "react";
import { Col, Row } from 'antd';
import { useStripe, useElements, CardCvcElement,
	CardExpiryElement,
	CardNumberElement, } from "@stripe/react-stripe-js";
import { stopEventPropagation } from '../../utils/helper';
import Spinner from "../spinner";
import InputField from "../../../components/InputField/InputField";
import RadioField from "../../../components/RadioField/RadioField";
import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";
import { useNavigate } from "react-router-dom";
import './stripe.scss';
import { toast } from "react-toastify";
import classNames from 'classnames';

export const CheckoutForm = ({handlePayment}) => {
    const navigate = useNavigate()
	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState(false)
	const [nameOnCard, setNameOnCard] = useState(null)
    const [nameError, setNameError] = useState(null)
    const [isChecked, setIsChecked] = useState(false)
	
	const handleSubmit = async (event) => {
		stopEventPropagation(event);

        if(!nameOnCard){
            setNameError('Name on card is required')
            return toast.error("Please add name on card", {position: "top-center"})
        }
        const  voter_first_name = nameOnCard.substring(0, nameOnCard.indexOf(' '))
        const  voter_last_name = nameOnCard.substring(nameOnCard.indexOf(' ') + 1)
		
		if(!voter_first_name || !voter_last_name){
            setNameError('Name on card is required')
            return toast.error("Please add name on card", {position: "top-center"})
        }

		setIsLoading(true)
		const {token} = await stripe.createToken(elements.getElement(CardNumberElement))


		if (token) {
            const response = await handlePayment({payment_method: "stripe", stripe_token: token.id, voter_first_name, voter_last_name})
			setIsLoading(false)
            if(response.success){
                navigate("/play-game/success")
            }
            else{
                toast.error(response.message, {position: "top-center"})
            }
		} else {
			setIsLoading(false)
			toast.error("There is an error, please try again later", {position: "top-center"})
		}

	};


	return (
		<Row>
			<Col span={24} className="stripeFormContainer">
				<InputField 
                    className={!!nameError?"error-input-field":"input-field"}
                    hasError={!!nameError}
                    icon={!!nameError?'../../assets/profile-error.png':'../../assets/profile.png'}
                    name="Name On Card" 
                    required 
                    value={nameOnCard} 
                    onChange={(e) => setNameOnCard(e.value)}
                />
				<label className="customLabel">Card Number<sup>*</sup></label> 
				<CardNumberElement options={{placeholder: "Enter card number", style: {base:{fontSize: "13px"}}}}/>
				<Row>
					<Col span={12}>
						<label className="customLabel">Expiration Date<sup>*</sup></label> 
						<CardExpiryElement options={{placeholder: "-------- ----", style: {base:{fontSize: "13px"}}}} />
					</Col>
					<Col span={12}>
						<label className="customLabel">Security Code/CVC<sup>*</sup></label> 
						<CardCvcElement options={{placeholder: "Enter security code/cvc", style: {base:{fontSize: "13px"}}}}/>
					</Col>
				</Row>
				<br />
				<br />
				<RadioField
                    value={isChecked} 
                    checked={isChecked}
                    onClick={() => setIsChecked(!isChecked)}
					label={
						<div>
						  <span>I agree to the </span>
						  <a target='_blank' href='https://www.villie.co/grg-terms'><u>terms and conditions</u></a>
						  <span> and to recieve marketing emails</span>
						</div>
					  }
                />
				<ButtonDefault 
					primary 
					onClick={handleSubmit} 
					className={classNames("submitBtn", { 'primaryDisabled': !isChecked || isLoading })} 
					disabled={!isChecked || isLoading}
				>
					{isLoading? <Spinner forBtn={true}/> : "Submit Donation"}
				</ButtonDefault>
			</Col>
		</Row>
	);
};