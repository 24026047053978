import React from 'react';
import { Typography, Drawer, Grid, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getDrawerComponent, setDrawerVisibility, setDrawerComponent } from '../../redux/dynamicDrawer/dynamicDrawerSlice';
import "./styles.scss";
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../redux/user/userSlice';
// import { GoogleAnalytics } from "../../services/google-analytics";
import MyGames from '../myGames';

const { Paragraph } = Typography;
const { useBreakpoint } = Grid;


const DynamicDrawer = ({show, handleClose, handleLogout}) => {
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { md } = useBreakpoint();
    const dynamicDrawerComponent = useSelector(getDrawerComponent)
    
    const getDrawer= (type) => {
        switch(type) {
            case "noData":
                return(
                    <>
                        {
                            user?
                            <>
                                <Paragraph className="drawerTab" 
                                    onClick={() => {
                                        dispatch(setDrawerVisibility({showSidebar: false}))
                                        navigate("/dashboard")}
                                    }
                                >   
                                    Dashboard
                                </Paragraph>
                                <Paragraph className="drawerTab" 
                                    onClick={() => {
                                        dispatch(setDrawerVisibility({showSidebar: false}))
                                        navigate("/live-games")}
                                    }
                                >   
                                    Live Games
                                </Paragraph>
                                <Paragraph className="drawerTab" 
                                    onClick={() => {
                                        dispatch(setDrawerComponent({sidebarComponent: "myGames"}))
                                        navigate("/dashboard")}
                                    }
                                >   
                                    My Games
                                </Paragraph>
                                <Paragraph className="drawerTab" 
                                    onClick={() => {
                                        dispatch(setDrawerVisibility({showSidebar: false}))
                                        window.open('https://www.villie.co/blog', '_blank');
                                    }}
                                >   
                                    Blog
                                </Paragraph>
                                <Paragraph className="drawerTab" 
                                    onClick={() => {
                                        dispatch(setDrawerVisibility({showSidebar: false}))
                                        window.open('https://www.villie.co/grg-faqs', '_blank')
                                    }}
                                >   
                                    FAQ's
                                </Paragraph>
                                <Button className="signIn" onClick={() => {
                                    // GoogleAnalytics.event({
                                    //     event_name: `game_creation_step_1`,
                                    // });
                                    navigate("/create-game")
                                }}>Create A Game</Button>
                                <Button className="signIn" onClick={handleLogout} style={{border: "1px solid #463649", background: "#463649"}}>Logout</Button>
                            </>
                            :
                            <>
                                <Paragraph className="drawerTab" 
                                    onClick={() => {
                                        dispatch(setDrawerVisibility({showSidebar: false}))
                                        window.open('https://www.villie.co/blog', '_blank');
                                    }}
                                >   
                                    Blog
                                </Paragraph>
                                <Paragraph className="drawerTab" 
                                    onClick={() => {
                                        dispatch(setDrawerVisibility({showSidebar: false}))
                                        window.open('https://www.villie.co/grg-faqs', '_blank')
                                    }}
                                >   
                                    FAQ's
                                </Paragraph>
                                <Button className="signIn" onClick={() => navigate("/login")}>Login</Button>
                            </>
                        }
                        
                    </>
                )
            case "myGames":
                return(
                    <MyGames />
                )
        }
    }
    return (
        <>
            <Drawer placement="right" 
                    onClose={handleClose} 
                    visible={show}
                    width={md ? 400 : "100%"}
                    className="drawerContainer"
                >
                {getDrawer(dynamicDrawerComponent)}
            </Drawer>
        </>
    )
}

export default DynamicDrawer;