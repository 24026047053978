import React from 'react'
import './InputField.scss';
import TooltipInput from '../../components/TooltipInput/TooltipInput.js';
import PropTypes from 'prop-types';
import classnames from 'classnames';
const InputField = ({ className="", name='', value='', tooltip, required, type='text', icon, onChange = () => {}, hasError, errorMessage, isBold=false, label=true}) => {
  const id = name.replace(/\s/g, '_').toLowerCase();
  const handleChange = (event) => {
    onChange({ id, value: event.target.value});
  }
  return (
    <div className={hasError ? "input-field " + "error-input-field " + className : "input-field " + className}>
      { label && name && <label className={hasError?"error": ""} htmlFor={id}>{ name }{ required && <sup>*</sup>}</label> }{ tooltip && <TooltipInput text={tooltip} />}
      <div className={classnames(hasError?"border-error":"border", {"removePadding": !icon})}>
        { icon && <img src={icon} alt="icon" /> }
        <input className={classnames({'bold': isBold})} id={id} type={type} name={name} value={value} onChange={handleChange} placeholder={'Enter ' + (name.toLowerCase()).replace("enter",'')} required={required} />
      </div>
      {hasError && <span className="error-msg">{errorMessage}</span>}
    </div>
  );
};

InputField.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  tooltip: PropTypes.string,
  hasError: PropTypes.bool,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  isBold: PropTypes.bool,
  label: PropTypes.bool
};

export default InputField;