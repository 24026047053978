import React from "react";
import { Col, Row } from 'antd';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./checkoutForm";
import {stripe} from "../../config";

const stripeTestPromise = loadStripe(stripe.STRIPE_PUBLIC_KEY);

const Stripe = ({handlePayment}) => {
	return (
		<>	
			<Row>
				<Col span={24}>
					<Elements stripe={stripeTestPromise}>
						<CheckoutForm handlePayment={handlePayment}/>
					</Elements>
				</Col>
			</Row>
		</>
	);
};

export default Stripe;