import { combineReducers } from 'redux'

import authSlice from './auth/authSlice';
import userSlice from './user/userSlice';
import gameSlice from './game/gameSlice';
import dynamicDrawerSlice from './dynamicDrawer/dynamicDrawerSlice';

const reducers = combineReducers({
    auth: authSlice,
    user: userSlice,
    game: gameSlice,
    dynamicDrawer: dynamicDrawerSlice,
})

export default reducers;