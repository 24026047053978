import FormPayment from "./FormPayment";
import { useSelector } from "react-redux";
import './Payment.scss';
import { getSeletedGame, getVotes } from "../../../../../shared/redux/game/gameSlice";
import { useEffect, useState } from "react";
import Layout from "../../../../../shared/components/layout";
import { calculateFeeAndFinalCost } from "../../../../../shared/utils/helper";
import {Helmet} from "react-helmet";

const Payment = () => {
	const selectedGame = useSelector(getSeletedGame)
	const votes = useSelector(getVotes)
	const [fee, setFee] = useState(null)

	useEffect(() => {
		if(selectedGame?.contest){
			setFee(calculateFeeAndFinalCost(votes?.noOfVotes, selectedGame?.contest?.min_cost_per_vote))
		}
	}, [selectedGame])

	return (
		<Layout>
			<Helmet>
				<title>{`GRG - Payment ${selectedGame?.contest?.id}`}</title>
			</Helmet>
			<div className="paymentContainer">
				<div style={{ width: '100%'}}>
					<button style={{cursor: "pointer"}} onClick={() => { window.history.back() }}><img src="../assets/arrow-left.png" alt="<" /></button>
				</div>
				<div className="Summary box">
					<h1>{selectedGame?.contest?.name}</h1>
					<h2>{selectedGame?.contest?.parent_1_first_name} {selectedGame?.contest?.parent_1_last_name} & {selectedGame?.contest?.parent_2_first_name} {selectedGame?.contest?.parent_2_last_name}</h2>
					<p>{selectedGame?.contest?.city}, {selectedGame?.contest?.country}</p>
					<h3>Let’s Tally Your Total!</h3>
					<ul>
						<li>{selectedGame?.contest?.parent_1_first_name} {selectedGame?.contest?.parent_1_last_name} & {selectedGame?.parent_2_first_name} {selectedGame?.parent_2_last_name}</li>
						<li><label>Number of Plays</label><span>{votes?.noOfVotes}</span></li>
						<li><label>Price Per Play</label><span>${selectedGame?.contest?.min_cost_per_vote}</span></li>
						<li><hr /></li>
						<li><label>Your Support</label><strong>${(parseFloat(fee?.finalCost) - parseFloat(fee?.serviceFee))?.toFixed(2)}</strong></li>
						<li><label>Fees</label><span>${fee?.serviceFee}</span></li>
						<li><hr /></li>
						<li><label>Grand Total</label><span>${fee?.finalCost}</span></li>
					</ul>
				</div>
				<FormPayment votes={votes} contest={selectedGame?.contest} contestId={selectedGame?.contest?.id} total={fee?.finalCost}/>
			</div>
		</Layout>
	);
};

export default Payment;