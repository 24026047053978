import React from 'react'
import {Helmet} from "react-helmet";

const NotFound = () => {
    return (
        <>
            <Helmet>
				<title>{`GRG - Not Found`}</title>
			</Helmet>
            <div>
                404
            </div>
        </>
    )
}

export default NotFound
