import StepLabel from './StepsLabel.js';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './StepsBar.scss';

const StepsBar = ({currentStep = 1, onChange = () => {} }) => {

  const handleChangeStep = (step) => {
    onChange(step);
  }

  return (
    <div className="steps-bar">
      <StepLabel number={1} label="The Basics" active={currentStep >= 1} complete={ currentStep > 1 } onClick={ handleChangeStep } />
      <hr className={classNames({'active': currentStep > 1})} />
      <StepLabel number={2} label="Get Creative" active={currentStep >= 2} complete={ currentStep > 2 } onClick={ handleChangeStep } />
      {/* <hr className={classNames({'active': currentStep > 2})} /> */}
      {/* <StepLabel number={3} label="Let’s Go!" active={currentStep >= 3} complete={ currentStep > 3 } onClick={ handleChangeStep } /> */}
    </div>
  );
};

StepsBar.prototype = {
  onChange: PropTypes.func
};

export default StepsBar;