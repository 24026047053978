import "./styles.scss"
import React, {useState, useEffect} from 'react';
import { Row, Col, Typography, Image } from 'antd';
import { batch, useDispatch } from "react-redux";
import { setDrawerComponent, setDrawerVisibility } from "../../redux/dynamicDrawer/dynamicDrawerSlice";
import { ArrowLeftOutlined } from '@ant-design/icons';
import GameCard from "../gameCard";
import { BaseAPI } from "../../services/BaseAPI";
import { toast } from "react-toastify";
import { setSeletedGame, setShareModalVisibility, setFetchGame } from "../../redux/game/gameSlice";
import { useNavigate } from "react-router-dom";
import NoData from "../noData";

const {Paragraph} = Typography

const MyGames = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
	const [games, setGames] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getUserGames = async () => {
            setIsLoading(true)
            try{
                const response = await BaseAPI.get("game/")
                if(response.status === 200){
                    setGames(response.data.data);
                }
            }
            catch(err){
                toast.error(err.response.data.message, { position: "top-center" });
            }
            setIsLoading(false)
        }

		getUserGames();
	}, [])
	
	
    const handleEnterGame = (game) => {
		batch(() => {
			dispatch(setSeletedGame({selectedGame: {"contest": game}}))
			dispatch(setShareModalVisibility({ showModal: game?.voting_ended ? false : true }))
            dispatch(setDrawerVisibility({showSidebar: false}))
            dispatch(setDrawerComponent({sidebarComponent: "noData"}))
            dispatch(setFetchGame({fetchGame: true}))
		})
		navigate('/dashboard')
	}

    return(
        <div className="myGamesContainer">
            <Row>
                <Col span={24} className="backBtn" 
                onClick={() => dispatch(setDrawerComponent({sidebarComponent: "noData"}))}>
                    <ArrowLeftOutlined/> back
                </Col>
            </Row>
            <Row justify="center" align="middle">
                <Col span={24}>
                    <Paragraph className="heading">My Games {games && `(${games?.length})`}</Paragraph>
                </Col>
                {isLoading ?
                    <div className="spinnerForBtn" style={{marginTop: "10%"}}>
                        <Image preview={false} style={{height: "70px", width: "70px"}} src="../assets/spinner.gif"/>
                    </div>
                    :
                    games?.length > 0 ?
                    games?.map((game, i) => {
                        return (
                            <div class="gamesList" key={i}>
                                <GameCard  game={game} handleEnterGame={() => handleEnterGame(game)} />
                            </div>
                        )})
                    :
                    <NoData description={"You don't have any game yet. Create a game please!"}/>
                }   
            </Row>
        </div>
    );
}

export default MyGames;