const Separator = ({children}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', margin: '20px 0', padding: '0 25px', color: '#463649', fontSize: '10.28px', fontFamily: 'Inter'}}>
      <hr style={{width: '50%', marginRight: '25px', borderWidth: '1px 0 0 0', opacity: '0.22', borderColor: '#463649'}} />
        { children }
      <hr style={{width: '50%', marginLeft: '25px', borderWidth: '1px 0 0 0', opacity: '0.22', borderColor: '#463649'}} />
    </div>
  )
}

export default Separator;