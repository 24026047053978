import React from "react";
import './RadioField.scss';
import PropTypes from 'prop-types';


const RadioField = ({id, label, value, children, onClick, onChange= () => {}, checked, showRed = false}) => {
  return (
    <label className="radio-field" style={{color: showRed && !checked && "#E03D32"}}>
      {label || children}
      <input type="radio" name={id} value={value}
             checked={!checked} onClick={onClick} onChange={onChange}/>
      <span className="checkmark" style={{backgroundColor: showRed && !checked && "#E03D32"}}/>
    </label>
  );
};

RadioField.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func
};

export default RadioField;