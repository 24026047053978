import React from "react";
import './card.scss';

const Card = ({children, className, action}) => {
  return (
    <>
        <div onClick={action} className={`card ${className}`}>
            {children}
        </div>
    </>
  )
}

export default Card;