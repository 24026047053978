import './playsAndDays.scss';
import React from 'react';
import { daysLeft } from '../../../../../shared/utils/helper';
import useWindowSize from '../../../../../shared/hooks/use-window-size';

const PlaysAndDays = ({game}) => {
  const {width} = useWindowSize()

  return (
    <div className="PlaysAndDays">
      <div className="box">
        <label>Plays</label>
        <strong>{parseInt(game?.summary?.boy_votes) + parseInt(game?.summary?.girl_votes)}</strong>
      </div>
      <div className="box">
        {
          game?.contest?.contest_stage === "Pause Stage" ?
          <>
          <strong style={{color: "red", fontSize: width < 450 && "26px"}}>Game Paused</strong>
          </>
          :
          <>
            <label>Days Left</label>
            <strong>{daysLeft(game?.contest?.finish_date)}</strong>
          </>
        }
        
      </div>
    </div>
  )
}

export default PlaysAndDays;