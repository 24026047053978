import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss"
import { batch, useDispatch, useSelector } from 'react-redux';
import { getAccessToken, resetAuthState } from "../../redux/auth/authSlice";
import { getUser, resetUserState } from "../../redux/user/userSlice";
import { toast } from "react-toastify";
import { BaseAPI } from "../../services/BaseAPI";
import { Row, Col, Button, Avatar } from 'antd';
import useWindowSize from '../../hooks/use-window-size';
import { getDrawerVisibility, setDrawerComponent, setDrawerVisibility } from "../../redux/dynamicDrawer/dynamicDrawerSlice";
import DynamicDrawer from '../dynamicDrawer';
import { UserOutlined } from '@ant-design/icons';
import { resetGameState } from "../../redux/game/gameSlice";
import { getCurrentPath } from "../../utils/helper";

const Navbar = () => {
	const accessToken = useSelector(getAccessToken)
	const dispatch = useDispatch()
	const user = useSelector(getUser)
	const [menuOpen, setMenuOpen] = useState(false);
	const navigate = useNavigate();
	const {width} = useWindowSize()
	const showDrawer = useSelector(getDrawerVisibility)
	const handleClickMenu = () => {
		setMenuOpen(!menuOpen);
	};
	
	const handleLogout = async () => {
		batch(() => {
			dispatch(resetAuthState())
			dispatch(resetUserState())
			dispatch(resetGameState())
		})
		const payload = {
			"email": user.email,
			"product_type": "grg"
		}
		try{
			const response = await BaseAPI.post(`auth/logout`, payload)
			if(response.status === 200){
				sessionStorage.clear()
				localStorage.clear()
				navigate("/");
			}
		}
		catch(err){
			toast.error("There is an error.", { position: "top-center" });
		}
	};

	return (
		<>
            <Row className="navbarContainer" justify="space-between" align="middle">
                <Col className='left'>
					<a href="https://www.villie.co/genderrevealgame-home"  target="_blank" rel="noopener noreferrer">
						<img className="logo" src={`../assets/logo.png`} alt="logo" />
					</a>
                </Col>
                {
					!getCurrentPath()?.includes("predictor") && 
					<Col className='right'>
                                {   width > 450 ?
                                    user ? 
                                        <>
                                            {/* <span className='tab' onClick={() => navigate("/dashboard")}>Blog</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
											<Avatar 
												size="large" 
												className="profileImage" 
												icon={<UserOutlined />} 
												onClick={() => {
													dispatch(setDrawerVisibility({showSidebar: true}))
												}}
												style={{ backgroundColor: '#cc8dff' }}
											/>
                                        </>
                                        :
                                        <>
											<a
												className="tab"
												href="https://www.villie.co/blog"
												onClick={handleClickMenu}
												target='_blank'
												rel="noopener noreferrer"
											>
												Blog
											</a>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											<a
												className="tab"
												href="https://www.villie.co/grg-faqs"
												onClick={handleClickMenu}
												target='_blank'
												rel="noopener noreferrer"
											>
												FAQ's
											</a>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Button className="signIn" onClick={() => navigate("/")}>Sign in</Button>
                                        </>
                                    
                                    :
                                    <>
                                        <img id="btn-menu" src="./assets/burger.svg" alt="burger"
                                            className='menuBtn'
                                            onClick={() => dispatch(setDrawerVisibility({showSidebar: true}))}
                                        />
                                    </>
                                }
                    
                	</Col>
				}
            </Row>
            <DynamicDrawer 
                    show={showDrawer} 
                    handleClose={() => {
                        batch(() => {
                            dispatch(setDrawerVisibility({showSidebar: false}))
                            dispatch(setDrawerComponent({sidebarComponent: "noData"}))
                        })}
                    } 
                    handleLogout={handleLogout}
                />
        </>
		
	);
};

export default Navbar;
