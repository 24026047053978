import "./playGame.scss";
import React, { useEffect, useState } from "react";
import Layout from '../../../../../shared/components/layout';
import { Col, Row, Alert } from 'antd';
import Card from "../../../../../shared/components/card";
import GameDetails from "../../../shared/components/gameDetails";
import ShareGame from '../../../shared/components/shareGame';
import VotingDetails from "../../../shared/components/votingDetails";
import GameCompleted from "../../../shared/components/gameCompleted";
import { getQueryParams } from "../../../../../shared/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setSeletedGame } from "../../../../../shared/redux/game/gameSlice";
import NoData from "../../../../../shared/components/noData";
import { getUser } from "../../../../../shared/redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../../../shared/components/spinner";
import { BaseAPI } from "../../../../../shared/services/BaseAPI";
import {Helmet} from "react-helmet";
import { BuyAGiftSection } from "../../../shared/components/buyAGiftSection";

const PlayGame = () => {
	const dispatch = useDispatch()
	const user = useSelector(getUser)
	const navigate = useNavigate();
	const gameId = getQueryParams().get('gameId')
	const [game, setGame] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [updateDeleted, setUpdateDeleted] = useState(false)

	const handleBoyVoting = () => {
		if(!!game?.contest?.voting_ended){
			return 0
		}
		if (user?.id === game?.contest?.user_id) {
			return toast.error("You cannot vote on your own game", { position: "top-center" })
		}
		navigate("/play-game/voting-boy");
	}

	const handleGirlVoting = () => {
		if(!!game?.contest?.voting_ended){
			return 0
		}
		if (user?.id === game?.contest?.user_id) {
			return toast.error("You cannot vote on your own game", { position: "top-center" })
		}
		navigate("/play-game/voting-girl");
	}

	const fetchGame = async () => {
		try{
			setIsLoading(true)
			const response = await BaseAPI.get(`/game/${gameId}`)
			setIsLoading(false)
			if(response.status === 200){
				dispatch(setSeletedGame({ selectedGame: response?.data?.data }))
				setGame(response?.data?.data)
			}
		}
		catch(err){
			setIsLoading(false)
			if(err?.response?.status === 400){
				window.location.replace("https://www.villie.co/genderrevealgame-home")
			}
		}
	}

	useEffect(() => {
		if (gameId) {
			fetchGame()
		}
	}, [gameId, updateDeleted])

	return (
		<>
			<Layout>
				<Helmet>
					<title>{`GRG - Play Game ${gameId}`}</title>
				</Helmet>
				<Card>
					{
						isLoading ?
							<Spinner />
							:
							game ?
								<Row gutter={[20,20]}>
									{game?.contest?.contest_stage === "Pause Stage" && 
										<>
											<Alert message={
												<>This game is paused</>
											} type="success" className="banner mb-30"/>
										</>
									}
									<Col s={24} sm={24} md={14} lg={15} xl={15}>
										<GameDetails game={game} user={user} uploadImage={false} setUpdateDeleted={() => setUpdateDeleted(!updateDeleted)}/>
									</Col>
									<Col xs={24} sm={24} md={10} lg={9} xl={9}>
										<Row gutter={[16, 30]}>
											{
												false && 
												<Col span={24}>
													<BuyAGiftSection game={game}/>
												</Col>
											}
											<Col span={24}>
												{
													!!game?.contest?.voting_ended ?
													<GameCompleted game={game}/>
													:
													<VotingDetails
														game={game}
														handleBoyVoting={handleBoyVoting}
														handleGirlVoting={handleGirlVoting}
													/>
													
												}
											</Col>
											{!game?.contest?.closeout_complete && <Col span={24}><ShareGame game={game}/></Col>}
										</Row>
									</Col>
								</Row>
							:
								<NoData
									image={"../assets/pink-bear.svg"}
									description={"No game exist's."}
									styles={{padding: "20% 0"}}
								/>
					}
				</Card>
			</Layout>
		</>

	);
};

export default PlayGame;
