import Separator from "../../../../../components/Separator";
import StripeContainer from "../../../../../shared/components/stripe/stripeContainer";
import Paypal from "../../../../../shared/components/paypal";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypal } from "../../../../../shared/config";
import { BaseAPI } from "../../../../../shared/services/BaseAPI";
import { setVotes } from "../../../../../shared/redux/game/gameSlice";
import { useDispatch } from "react-redux";
// import { GoogleAnalytics } from "../../../../../shared/services/google-analytics";
import { LockFilled } from '@ant-design/icons';

const paypalScriptOptions = { "client-id": paypal.PAYPAL_KEY, currency: "USD" };

const FormPayment = ({ votes, contest, contestId, total }) => {
	const dispatch = useDispatch()


	const handlePayment = async (data) => {
		const payload = {
			boy_or_girl: votes?.for === 'boy' ? 0 : 1,
			no_of_votes: votes?.noOfVotes,
			is_anonymous: votes?.isAnonymous,
			contest_id: contestId,
			...data
		}
		try{
			const response = await BaseAPI.post(`vote/cast`, payload)

			if(response.status === 200){
				// GoogleAnalytics.ecommerceEvent({
				// 	event_name: `game_created`,
				// 	ecommerce: {
				// 		transaction_id: response?.data?.data.transactionId,
				// 		value: total,
				// 		currency: "USD",
				// 		items: [
				// 			{
				// 				item_id: "Vote",
				// 				item_name: "Vote",
				// 				price: contest?.min_cost_per_vote,
				// 				quantity: votes?.noOfVotes
				// 			}
				// 		]
				// 	},
				// 	params: {
				// 		game_id: contest?.id,
				// 	}
				// })
				dispatch(setVotes({ votes: { for: null, noOfVotes: null } }))
				return {success: true, data: response?.data?.data}
			}
		}
		catch(err){
			return {
				success: false,
				message: err.response.data.message
			}
		}
	}

	return (
		<div className="FormPayment box">
			<h2>Payment</h2>
			<PayPalScriptProvider options={paypalScriptOptions}>
				<Paypal handlePayment={handlePayment} value={total} />
			</PayPalScriptProvider>
			<Separator>or</Separator>
			<StripeContainer handlePayment={handlePayment} />
			<p className="cards">
				<LockFilled style={{color: "#fece30", fontSize: "23px"}}/> &nbsp; Cards Accepted
				<img src="../assets/mastercard.png" alt="Mastercard" width="35px" height="24px" />
				<img src="../assets/visa.png" alt="Visa" width="35px" height="24px" />
				<img src="../assets/discover.png" alt="Discover" width="35px" height="24px" />
			</p>
		</div>
	);
};

export default FormPayment;