import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {GoogleAnalytics} from './../shared/services/google-analytics'

const PageTracker = () => {
    const location = useLocation();

    useEffect(() => {
        GoogleAnalytics.pageview(location.pathname + location.search)
    }, [location]);

    return null;
};

export default PageTracker;