import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from "react-toastify";
import { store } from './shared/redux/store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import './styles/global.scss';
import App from './App';

let persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
    <ToastContainer
      position="bottom-right"
    />
    <App />
    </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

