import "./styles.scss"
import React from 'react';
import { Form, DatePicker } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import moment from "moment";

const CustomDatePicker = ({label, name, format="MM-DD-YYYY", picker, mode, renderExtraFooter, disabledDate, tooltip=false, required=false, errorMessage}) => {

    const rules = () => {
        const rule = []

        if(required){
            rule.push({ required: true, message: errorMessage })
        }

        return rule
    }

    const defaultDisabledDate = (current) => {
        return current && current < moment().endOf('day');
    }

    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules()}
            className="formItem"
            tooltip={tooltip && {
                title: tooltip,
                icon: <InfoCircleFilled style={{color: "#463649"}}/>,
            }}

        >
            <DatePicker 
                allowClear={false} 
                format={format} 
                disabledDate={!!disabledDate? disabledDate : defaultDisabledDate} 
                className='datePicker' 
                placeholder={format.replaceAll("-", "/")} 
                picker={picker}
                mode={mode}
                renderExtraFooter={renderExtraFooter}
            />
        </Form.Item>
    );
}

export default CustomDatePicker;