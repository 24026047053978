import React from "react";

export const SliderArrowRight = ({onClick}) => {
  return (
    <div className="customArrowRight" onClick={onClick}>
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.83503 6.00661L7.83656 6.3607C7.84872 7.76518 7.93338 9.01826 8.07939 9.81237C8.07939 9.82669 8.23866 10.6143 8.34012 10.8764C8.49939 11.2554 8.78738 11.577 9.14847 11.7808C9.43756 11.9262 9.74083 12 10.0583 12C10.3078 11.9884 10.7194 11.8629 11.0131 11.7576L11.2572 11.664C12.8739 11.0218 15.9644 8.92344 17.148 7.64017L17.2353 7.55047L17.6247 7.13016C17.8702 6.80852 18 6.41527 18 5.99229C18 5.61337 17.8844 5.23444 17.6531 4.92822C17.5839 4.82901 17.4723 4.70173 17.3731 4.59419L16.994 4.19739C15.6895 2.87572 12.8652 1.02183 11.4012 0.407564C11.4012 0.394346 10.4914 0.0143198 10.0583 0H10.0005C9.33611 0 8.71539 0.378924 8.39793 0.991371C8.31121 1.1588 8.22803 1.48678 8.16476 1.77483L8.05103 2.31871C7.92121 3.19332 7.83503 4.53497 7.83503 6.00661ZM1.50325 4.48166C0.673083 4.48166 0 5.1613 0 5.99956C0 6.83782 0.673083 7.51746 1.50325 7.51746L5.20248 7.19031C5.85375 7.19031 6.38174 6.65827 6.38174 5.99956C6.38174 5.34195 5.85375 4.80881 5.20248 4.80881L1.50325 4.48166Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
