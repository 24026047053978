// import "./gameCard.scss";
// import React from 'react';
// import { Row, Col } from 'antd';
// import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";
// import { daysLeft } from "../../utils/helper.js";

// const GameCard = ({game, handleEnterGame}) => {

//     const getImagePlaceholder = () => {
//         const images = ['../assets/blue-heart.png', '../assets/pink-heart.png']
//         const colors = ['blue', 'pink']

//         const random = Math.floor(Math.random() * images.length);

//         return (<div className={`imageCircle ${colors[random]}`}>
//             <img alt="placeholder" src={images[random]} />
//         </div>)
//     }

//     return(
//         <>
//             <div className="gameCard">
//                 <Col span={24} className="top">
//                     <Row>
//                         <Col span={8}>
//                             {getImagePlaceholder()}
//                         </Col>
//                         <Col span={16} className='right'>
//                             <div><p className='daysLeft'>DAYS LEFT</p></div>
//                             <div><p className="days">{daysLeft(game?.finish_date)}</p></div>
//                         </Col>

//                     </Row>
                    
//                 </Col>
//                 <Col span={24}>
//                     <p className='title'>{game?.name}</p>
//                 </Col>
//                 <Col span={24}>
//                     <ButtonDefault primary className='btn' 
//                         onClick={handleEnterGame}>
//                             Enter The Game
//                     </ButtonDefault>
//                 </Col>
//             </div>
//         </>
//     )
// }


// export default GameCard;



import "./gameCard.scss";
import React from 'react';
import { daysLeft } from "../../utils/helper.js";
import { Avatar, Card, Button } from 'antd';

const { Meta } = Card;
const colors = ['blue', 'pink']

const GameCard = ({game, handleEnterGame}) => {
    return(
        <>
        <Card
            className="gameCard"
            cover={
                <img
                    alt="example"
                    src={game.img_url ? game.img_url : "../assets/boy_or_girl.jpg"}
                />
            }
        >
            <Meta
                avatar={<Avatar style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] == 'blue'? "#F3F4FF" : "#F8EAFF" }}/>}
                title={game?.name}
                description={`Days Left: ${daysLeft(game?.finish_date)}`}
            />
            <Button primary className='btn' 
                onClick={handleEnterGame}>
                Enter The Game
            </Button>
        </Card>
        </>
    )
}


export default GameCard;