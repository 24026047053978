import { Routes, Route } from "react-router-dom";
import VotingBoy from "../modules/games/player/page/votingBoy";
import VotingGirl from "../modules/games/player/page/votingGirl";
import Game from "../modules/games/player/page/playGame";
import Payment from "../modules/games/player/page/payment";
import Success from "../modules/games/player/page/success";
import Completed from "../modules/games/player/page/completed";

export const PlayGameRouter = () => {
  return (
    <Routes>
      <Route>
        <Route index element={<Game />} />
        <Route path="voting-boy" element={<VotingBoy />} />
        <Route path="voting-girl" element={<VotingGirl />} />
        <Route path="payment" element={<Payment />} />
        <Route path="success" element={<Success />} />
        <Route path="completed" element={<Completed />} />
      </Route>
    </Routes>
  );
}