import React, { useEffect, useState } from 'react';
import ParentInfoStep from "./parentInfoStep";
import './editGame.scss';
import GameInfoStep from "./gameInfoStep";
import { useNavigate } from "react-router-dom";
import { convertFromPayload, convertToPayload, blobToBase64 } from "../../../../../shared/utils/helper";
import { useSelector, useDispatch } from 'react-redux';
import { getUser, setUser } from "../../../../../shared/redux/user/userSlice"
import Layout from '../../../../../shared/components/layout/index.jsx';
import Steps from '../../components/steps';
import { getSeletedGame } from '../../../../../shared/redux/game/gameSlice.js';
import { Footer } from '../../../../../components/Footer/Footer';
import { Form } from 'antd';
import moment from "moment";
import {toast} from "react-toastify";
import { BaseAPI } from '../../../../../shared/services/BaseAPI';
import {Helmet} from "react-helmet";

const donationsAmount = [
  {id: 0, value: "10"},
  {id: 1, value: "15"},
  {id: 2, value: "20"},
]

const EditGame = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const user = useSelector(getUser)
  const game = useSelector(getSeletedGame)
  const [step, setStep] = useState(1);
  const [dataForm, setDataForm] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [donationMoney, setDonationMoney] = useState(null)

  const changeForm = (e) => {
    setDataForm({
      ...dataForm,
      [e.id]: e.value
    })
  }

  const convertBlobToBase64 = async (images) => {
    if (!images) {
      return []
    }
    return await Promise.all(images?.map(async (image) => {
      if (!image?.blob) {
        return image
      }
      const img = await blobToBase64(image?.blob)
      return { delete_flag: null, image: img.replace("application/zip", "image/jpeg"), type: "jpeg" }
    }))
  }

  const handleDeleteImage = (payloadImages, gameImaes) => {
    const images = payloadImages
    const imageIds = payloadImages?.map(p => p.id)

    gameImaes?.map(g => {
      if (!imageIds.includes(g.id)) {
        images.push({ ...g, delete_flag: true })
      }
    })

  }

  const onFinish = async (data) => {
    let payload = convertToPayload(data)
    setIsLoading(true);

    if(step === 2){
      payload["finish_date"] = moment(data.contest_end_date).format('YYYY-MM-DD');
      payload["contest_photos"] = await convertBlobToBase64(dataForm?.upload_images);
      payload["min_cost_per_vote"] = !data.donation_per_vote ? donationMoney?.value : data.donation_per_vote;
      handleDeleteImage(payload["contest_photos"], game.contest.photos)
    }

    const response = await updateGame(payload)
    setIsLoading(false);

    if (response.success) {
      if (step === 1) {
        await getUserFromApi()
        setIsLoading(false);
        setStep(2)
      }
      else {
        setIsLoading(false);
        navigate('/dashboard');
      }
    }
    else{
      toast(response.message, {position: "top-center"})
    }
  }

  const updateGame = async (payload) => {
		try{
			const response = await BaseAPI.patch(`game/${game?.contest?.id}`, payload)
			if(response.status === 200){
				return {success: true, data: response?.data?.data}
			}
		}
		catch(err){
			return {
        success: false,
        message: err.response.data.message
      }
		}
  }

  const getUserFromApi = async () => {
    try{
			const response = await BaseAPI.get(`user/me`)
			if(response.status === 200){
				dispatch(setUser({ user: response?.data?.data }))
			}
		}
		catch(err){
			toast("There is an error, try again later", { position: "top-center" })
		}
	}

  useEffect(() => {
    if (game) {

      if (!!(game?.contest?.voting_ended)) {
        navigate('/dashboard');
      }
      setDataForm(convertFromPayload(game, user))
      
      if(game?.contest?.min_cost_per_vote === 10){
        setDonationMoney(donationsAmount[0])
      }
      if(game?.contest?.min_cost_per_vote === 15){
        setDonationMoney(donationsAmount[1])
      }
      if(game?.contest?.min_cost_per_vote === 20){
        setDonationMoney(donationsAmount[2])
      }

      const url = new URL(window.location.href);
      const fromNavbar = url.searchParams.get("uploadImage");
      if (!!fromNavbar) {
        setStep(2)
      }
    }
  }, [game, user])

  return (
    <Layout>
      <Helmet>
        <title>{`GRG - Edit Game`}</title>
      </Helmet>
      <main className="gray">
        <div style={{ maxWidth: '1440px', margin: 'auto' }}>
          <div className="editGameContainer">
            <h1>Edit Game</h1>
            <Steps currentStep={step} onChange={setStep} />
            <Form
						form={form}
						name="editGame"
						layout="vertical"
						onFinish={onFinish}
						autoComplete="off"
						className="frame"
					>
              {step === 1 &&
                <ParentInfoStep
                  form={form}
                  data={dataForm}
                  isLoading={isLoading}
                />
              }
              {step === 2 &&
                <GameInfoStep
                  form={form}
                  data={dataForm}
                  isLoading={isLoading}
                  onChange={changeForm}
                  donationMoney={donationMoney}
								  setDonationMoney={setDonationMoney}
                />
              }
          </Form>
          </div>
        </div>
        <Footer style={{ background: "#e5e5e5" }} />
      </main>
    </Layout>
  )
}

export default EditGame