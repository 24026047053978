import { createSlice } from '@reduxjs/toolkit';

export const dynamicDrawerSlice = createSlice({
    name: 'dynamicDrawer',
    initialState: {
        showSidebar: false,
        sidebarComponent: "noData"
    },
    reducers: {
        setDrawerVisibility:(state, action) => {
            state.showSidebar = action.payload.showSidebar;
        },
        setDrawerComponent:(state, action) => {
            state.sidebarComponent = action.payload.sidebarComponent;
        },
    },
});

export const getDrawerComponent = (state) => state.dynamicDrawer.sidebarComponent;
export const getDrawerVisibility = (state) => state.dynamicDrawer.showSidebar;

export const { setDrawerComponent, setDrawerVisibility } = dynamicDrawerSlice.actions;

export default dynamicDrawerSlice.reducer;