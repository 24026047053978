import React from "react";
import "./modal.scss";

const Modal = ({visible, handleClose, children}) => {
	return (
		<div className={`modal-small ${visible ? "active" : ""} `}>
			<div className="background" onClick={handleClose} />
			<div className="body-modal">
				<img
					className="close-btn"
					alt="icon"
					src="../assets/close-square.svg"
					onClick={handleClose}
				/>
				{children}
			</div>
		</div>
	);
};

export default Modal