import React, { useEffect, useState } from "react";
import BackgroundHearts from "../../../../components/BackgroundHearts.js";
import ButtonDefault from '../../../../components/ButtonDefault/ButtonDefault.js';
import './login.scss';
import { Link, useNavigate } from 'react-router-dom';
import { batch, useSelector, useDispatch } from 'react-redux';
import { getAccessToken, setAccessToken } from "../../../../shared/redux/auth/authSlice.js";
import { setUser } from "../../../../shared/redux/user/userSlice.js";
import Spinner from "../../../../shared/components/spinner/index.jsx";
import useWindowSize from "../../../../shared/hooks/use-window-size.js";
import { Form, Input } from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import { toast } from "react-toastify";
import { BaseAPI } from "../../../../shared/services/BaseAPI.js";
import { setDrawerVisibility } from "../../../../shared/redux/dynamicDrawer/dynamicDrawerSlice.js";
import { setSeletedGame } from "../../../../shared/redux/game/gameSlice.js";
import {Helmet} from "react-helmet";

const Login = () => {
    const [form] = Form.useForm();
    const accesstoken = useSelector(getAccessToken)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null)
    const { width } = useWindowSize()

    useEffect(() => {
        if (accesstoken) {
            navigate("/dashboard")
        }
    }, [])

    const handleLogin = async (values) => {
        setIsLoading(true)
        const payload = {
			"email": values.email,
            "password": values.password,
            "product_type": "grg"
		}
		try{
			const response = await BaseAPI.post(`auth/login`, payload)
			setIsLoading(false);
			if(response.status === 200){
				const { accessToken, user } = response.data.data

                batch(() => {
                    dispatch(setAccessToken({ accessToken: accessToken }))
                    dispatch(setUser({ user: user }))
                    dispatch(setDrawerVisibility({showSidebar: false}))
                    dispatch(setSeletedGame({selectedGame: null}))
                })

                navigate("/dashboard");
			}
		}
		catch(err){
			setIsLoading(false)
            if (err.response.data.message === 'User not found.') {
                return setErrors({ email: 'Uh oh. Looks like we don’t have this email on file. Why don’t you try a different email or create a new account' });
            }
            if (err.response.data.message === 'Email or Password is incorrect.') {
                return setErrors({ password: 'Oops! That didn’t work. Let’s try a different password' });
            }
            toast.error(err.response.data.message, { position: "bottom-right" });
		}
    };

    const handleError = () => {
        if(errors){
            setErrors(null)
        }
    }

    return (
        <BackgroundHearts className="bg-register" bg="#d6daff" hearts="#B7BEFD">
            <Helmet>
				<title>{`GRG - Login`}</title>
			</Helmet>
            <div className="login-screen">
                <div className="frame" style={{ borderRadius: width <= 450 && "0" }}>
                    <div className="login">
                        <a href="https://www.villie.co/genderrevealgame-home" target="_blank" rel="noopener noreferrer">
                            <img className="logo" src={`../assets/logo.png`} alt="logo" />
                        </a>
                        <h1>Let’s Get This Party Started!</h1><br />
                        <Form
                            form={form}
                            name="login"
                            layout="vertical"
                            style={{ maxWidth: '353px'}}
                            onFinish={handleLogin}
                        >
                            <Form.Item
                                label={"Email address"}
                                name={"email"}
                                rules={[{required: true, type: "email", message: "* Please enter a valid email address"}]}
                                className="formItem"
                            >
                                <Input className='input' placeholder={"Enter email address"} prefix={<UserOutlined />} onChange={handleError}/>
                            </Form.Item>
                            {!!errors?.email && <div className="error-msg"> *  {errors?.email}</div>}

                            <Form.Item
                                label="Password"
                                name="password"
                                className="formItem"
                                rules={[{required: true, message: '* Wrong password!'}]}
                            >
                                
                                <Input.Password className='input' prefix={<LockOutlined />} placeholder="Enter password"  onChange={handleError}/>
                            </Form.Item>
                            {!!errors?.password && <div className="error-msg"> *  {errors?.password}</div>}

                            <Form.Item>
                                <ButtonDefault primary type="submit">
                                    {isLoading ? <Spinner forBtn={true} /> : "Login To Account"}
                                </ButtonDefault>

                            </Form.Item>
                        </Form>
                        <div style={{ textAlign: 'center' }} className="bottomDiv">
                            <Link to="/forgot-password" style={{ textDecoration: "underline"}}>Forgot Your Password?</Link>
                            <p>Don’t have an account? <Link to="/register" style={{ textDecoration: "underline", }}>Register Here</Link></p>
                        </div>
                    </div>
                    {width > 450 &&
                        <div className="information">
                            <img
                                className="couple-img"
                                src={"../assets/login-couple-kiss.png"}
                                alt="couple"
                            />
                        </div>
                    }

                </div>
            </div>
        </BackgroundHearts>
    );
};

export default Login