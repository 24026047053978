import moment from "moment"
import {app} from "../config"

export const stopEventPropagation = (e) => {
    e.preventDefault();
}

export const blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const getQueryParams = () => {
    return new URLSearchParams(window.location.search);
}

export const daysLeft = (endDate) => {
    const todaysDate = moment()
    const end = moment(endDate)

    const daysLeft = end.diff(todaysDate, 'days');
    if (daysLeft < 0) {
        return '0'
    }
    else {
        return `${daysLeft}`
    }
}

export const calculateVoterPercentage = (boysVotes, girlVotes, gender) => {
    const totalVotes = parseInt(boysVotes) + parseInt(girlVotes)

    return gender === "boy" ?
        (parseInt(boysVotes) / totalVotes) * 100
        :
        (parseInt(girlVotes) / totalVotes) * 100
}

export const convertFromPayload = (game , user) => {
    const object = {
        game_name: game.contest.name,
        share_your_story: game.contest.description,
        parent_one_first_name: game.contest.parent_1_first_name,
        parent_one_last_name: game.contest.parent_1_last_name,
        parent_two_first_name: game.contest.parent_2_first_name,
        parent_two_last_name: game.contest.parent_2_last_name,
        game_prize: game.contest.prize_name,
        donation_per_vote: game.contest.min_cost_per_vote,
        contest_end_date: moment(game.contest.finish_date),
        city: game.contest.city,
        state_or_province: game.contest.state,
        country: game.contest.country,
        vote_counts: game.vote_counts.length,
        phone: user.phone,
        upload_images: game.contest.photos?.map((p) => ({...p, src: p.img_url}))
    }
    return removeNull(object)
}

export const convertToPayload = (data) => {
    const object = {
        "name": data.game_name,
        "description": data.share_your_story,
        "phone": data.phone,
        "parent_1_first_name": data.parent_one_first_name,
        "parent_1_last_name": data.parent_one_last_name,
        "parent_2_first_name": data.parent_two_first_name,
        "parent_2_last_name": data.parent_two_last_name,
        "prize_name": data.game_prize,
        "min_cost_per_vote": data.donation_per_vote,
        "city": data.city,
        "state": data.state_or_province,
        "country": data.country,
    }
    return removeUndefined(object)
}

export const removeNull = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const removeUndefined = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != undefined));
}

export const countDecimals = (value) => {
    if (Math.floor(value) !== value)
        return value?.toString()?.split(".")[1]?.length || 0;
    return 0;
}

export const getGender = (gameName) => {
    // 0 boy, 1 girl
    return gameName?.length % 2
}

export const calculateFeeAndFinalCost = (noOfVotes, minCostPerVote) => {
    let cost = parseFloat(noOfVotes) * parseFloat(minCostPerVote)
    const serviceFee = (cost * 0.19)?.toFixed(2)

    const finalCost = parseFloat(cost) + parseFloat(serviceFee)

    return {serviceFee, finalCost}
} 

export const isInt = (n) => {
    return n % 1 === 0;
}

export const getCurrentPath = () => {
    return (window.location.href).replace(`${app.APP_PUBLIC_URL}`, "")
} 