import React from "react";
import { AppRouter } from "./routers/AppRouter";
import './app.scss';

const App = () => {

  return <AppRouter />;

};

export default App;