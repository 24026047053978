import React, { useEffect, useState } from 'react';
import StepsBar from '../../../../../components/StepsBar/StepsBar.js';
import YourInformationStep from "./YourInformationStep";
import ReviewAndSubmitStep from './ReviewAndSubmitStep.js';
import './CreateGameScrenn.scss';
import GameInformationStep from "./GameInformationStep";
import { useNavigate } from "react-router-dom";
import { isAuth } from '../../../../../shared/api/Auth';
import { blobToBase64 } from "../../../../../shared/utils/helper";
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUser } from "../../../../../shared/redux/user/userSlice"
import { toast } from 'react-toastify';
import Spinner from '../../../../../shared/components/spinner/index.jsx';
import { stopEventPropagation } from "../../../../../shared/utils/helper";
import { Form } from 'antd';
import moment from "moment";
import { BaseAPI } from '../../../../../shared/services/BaseAPI.js';
import { FacebookPixel } from '../../../../../shared/services/facebook-pixel.js';
// import { GoogleAnalytics } from '../../../../../shared/services/google-analytics.js';
import {Helmet} from "react-helmet";

const CreateGame = () => {
	const dispatch = useDispatch()
	const [form] = Form.useForm();
	const user = useSelector(getUser)
	const [step, setStep] = useState(1);
	const [dataForm, setDataForm] = useState({});
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [donationMoney, setDonationMoney] = useState(null)

	useEffect(async () => {
		!isAuth && navigate('/');
		setDataForm({ "email_opt_in_text": "I agree to the terms and conditions and opt-in to receive future emails" })
	}, [isAuth, navigate])
	const changeForm = (e) => {
		if (e.id == "country") {
			delete dataForm["state_or_province"]
		}
		if (e.id == 'share_your_story!') {
			setDataForm({
				...dataForm,
				'share_your_story': e.value
			})
		}
		else {
			setDataForm({
				...dataForm,
				[e.id]: e.value
			})
		}
	}

	const convertBlobToBase64 = async (images) => {
		if (!images) {
			return []
		}
		let i = -1
		return await Promise.all(images?.map(async (image) => {
			const img = await blobToBase64(image?.blob)
			i += 1
			return { delete_flag: null, image: img.replace("application/zip", "image/jpeg"), type: "jpeg" }
		}))
	}

	const submitForm = async (data) => {
		let payload = {
			"user_id": user?.id,
			"name": data.game_name,
			"description": data.share_your_story,
      		"phone": data.phone_number,
			"parent_1_first_name": data.parent_one_first_name,
			"parent_1_last_name": data.parent_one_last_name,
			"parent_2_first_name": data.parent_two_first_name,
			"parent_2_last_name": data.parent_two_last_name,
			"prize_name": data.game_prize,
			"min_cost_per_vote": !data.donation_per_vote ? donationMoney?.value : data.donation_per_vote,
			"contest_photos": await convertBlobToBase64(dataForm?.upload_images),
			"finish_date": moment(data.contest_end_date).format('YYYY-MM-DD'),
			"city": data.city,
			"state": data.state_or_province,
			"country": data.country,
			"email_opt_in_text": dataForm.email_opt_in_text ? dataForm.email_opt_in_text : '',
		}
		setIsLoading(true);
 
		try {
			const game = await handleCreateGame(payload)

			if (game.success) {
				FacebookPixel.track("SubmitApplication", {gameId: game?.data?.gameId, email: user?.email})

				// GoogleAnalytics.event({
				// 	event_name: `game_created`,
				// 	params: {
				// 		user_id: user?.id,
				// 		u_email: user?.email,
				// 		game_id: game?.data?.gameId,
				// 		creation_date: moment().format('YYYY-MM-DD')
				// 	}
				// })
				await getUserFromApi()
				setIsLoading(false);
				toast.success("Game created successfully", { position: "top-center" })

				if(user?.has_grg === 0){
					const userResponse = await BaseAPI.patch(`user/`, {has_grg: true})
	
					if(userResponse?.status === "success"){
						dispatch(setUser({user: userResponse?.data?.user}))
					}
				}

				setTimeout(
					() => navigate("/dashboard"),
					1500
				);
			}
			else {
				setIsLoading(false);
				toast.error("Unable to create game. Try again later", { position: "top-center" })
			}
		} catch (error) {
			setIsLoading(false);
			toast.error("Unable to create game. Try again later", { position: "top-center" })
		}

	}
	// const getEmailOptInText = (payload) => {
	// 	return Api.get(`/email_permission_text/${payload.version}`).then(
	// 		(res) => {
	// 			if (res.data) {
	// 				return res.data.text
	// 			}
	// 		}
	// 	)
	// }
	const handleCreateGame = async (payload) => {
		try{
			const response = await BaseAPI.post(`game/`, payload)
			if(response.status === 200){
				return {success: true, data: response?.data?.data}
			}
		}
		catch(err){
			return {
				success: false,
				message: err.response.data.message
			}
		}
	}

	const onFinish = (values) => {
		setDataForm({ ...dataForm, ...values })
		if (step === 1){
			// GoogleAnalytics.event({
			// 	event_name: `game_creation_step_2`,
			// })
		}
		else if (step === 2){
			// GoogleAnalytics.event({
			// 	event_name: `game_creation_step_3`,
			// })
			return submitForm({ ...dataForm, ...values })
		}
		setStep(step + 1)
	};

	const getUserFromApi = async () => {
		try{
			const response = await BaseAPI.get(`user/me`)
			if(response.status === 200){
				dispatch(setUser({ user: response?.data?.data }))
			}
		}
		catch(err){
		}
	}

	return (
		<main className="gray">
			<Helmet>
				<title>{`GRG - Create Game`}</title>
			</Helmet>
			<div style={{ maxWidth: '1440px', margin: 'auto' }}>
				<div className="create-game-screen">
					<h1>Create A Game</h1>
					{/*  onChange={ setStep } */}
					<StepsBar currentStep={step} />
					<Form
						form={form}
						name="createGame"
						layout="vertical"
						onFinish={onFinish}
						autoComplete="off"
						className="frame"
					>
						{step === 1 && <YourInformationStep form={form}/>}
						{step === 2 &&
							<GameInformationStep
								data={dataForm}
								onChange={changeForm}
								onBack={(e) => { stopEventPropagation(e); setStep(1) }}
								donationMoney={donationMoney}
								setDonationMoney={setDonationMoney}
								form={form}
								checkTerms={() => setStep(3)}
							/>
						}
						{step === 3 && <ReviewAndSubmitStep data={dataForm} onBack={(e) => { stopEventPropagation(e); setStep(2) }} />}
					</Form>
				</div>
			</div>
			{isLoading && <Spinner showAdvice={true} />}
		</main>
	)
}

export default CreateGame