import React, {useEffect, useState} from "react";
import {STATES, PROVIDENCES} from "../../../../../shared/utils/lists";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import { Col, Row } from 'antd';
import {useNavigate} from "react-router-dom";
import Spinner from "../../../../../shared/components/spinner";
import Input from "../../../../../shared/components/input";
import CustomSelect from "../../../../../shared/components/select";

const ParentInfoStep = ({form, data = {}, isLoading}) => {
    const [country, setCountry] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        if(data?.donation_per_vote === 10){
            delete data['donation_per_vote']
        }
        if(data?.donation_per_vote === 15){
            delete data['donation_per_vote']
        }
        if(data?.donation_per_vote === 20){
            delete data['donation_per_vote']
        }
        form.setFieldsValue(data)
        setCountry(data.country)
    }, [data])
    return (
        <>
            <h2>Parents Info</h2>
            <Row gutter={[100, 20]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent One First Name"
                        name="parent_one_first_name"
                        placeholder="Enter parent one first name"
                        required
                        errorMessage={"* Required information is missing."}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent One Last Name"
                        name="parent_one_last_name"
                        placeholder="Enter parent one last name"
                        required
                        errorMessage={"* Required information is missing."}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent Two First Name"
                        name="parent_two_first_name"
                        placeholder="Enter parent two first name"
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Parent Two Last Name"
                        name="parent_two_last_name"
                        placeholder="Enter parent two last name"
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                        label="Country"
                        name="country"
                        placeholder="Select"
                        required
                        errorMessage={"* Required information is missing."}
                        options={[
                            { value: 'Canada', label: 'Canada' },
                            { value: 'USA', label: 'USA' },
                        ]}
                        onChange={(value) => {setCountry(value);form.resetFields(["state_or_province"])}}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="City"
                        name="city"
                        placeholder="Enter city"
                        required
                        errorMessage={"* Required information is missing."}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                        label="State or Province"
                        name="state_or_province"
                        placeholder="Select"
                        required
                        errorMessage={"* Required information is missing."}
                        options={
                            !country ?
                            []
                            :
                            country === "USA" ? STATES : PROVIDENCES
                        }

                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Input
                        label="Phone Number"
                        name="phone"
                        placeholder="Enter phone number"
                        required
                        errorMessage={"* Required information is missing."}
                        minLength={7}
                    />
                </Col>
                <Col xs={0} sm={0} md={24} lg={24} xl={24}></Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row gutter={[8, 0]} align="bottom">
                        <Col span={12}>
                            <ButtonDefault
                                primary={true}
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading? <Spinner forBtn={true}/> : "Save"}
                            </ButtonDefault>
                        </Col>
                        <Col span={12}>
                            <ButtonDefault
                                onClick={(e) => {
                                    navigate("/dashboard")
                                }}
                            >
                                Don't Save
                            </ButtonDefault>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
};

export default ParentInfoStep;