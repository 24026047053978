import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import "./PieChartResults.scss";

export const PieChartResults = ({
  data = [
    { name: "Girl", value: 70, color: "#D9ABFE" },
    { name: "Boy", value: 30, color: "#5263FF" },
  ],
}) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${(percent * 100)?.toFixed(0)}%`}
        </text>
        <text
          x={x}
          y={y + 15}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {` ${data[index].name} `}
        </text>
      </>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%" className="pieChart">
      <PieChart width={145} height={145}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-270}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={72}
          fill="#8884d8"
          stroke="none"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
