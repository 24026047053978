import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Layout from '../../../../../shared/components/layout';
import VoteButtons from "../../../shared/components/VoteButtons";
import ButtonDefault from "../../../../../components/ButtonDefault/ButtonDefault";
import { getSeletedGame, getVotes, setVotes } from '../../../../../shared/redux/game/gameSlice';
import { useSelector, useDispatch } from "react-redux";
import Card from '../../../../../shared/components/card';
import { Checkbox } from 'antd';
import {Helmet} from "react-helmet";
import './votingGirl.scss';

const VotingGirl = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const game = useSelector(getSeletedGame)
	const votes = useSelector(getVotes)
	const[isAnonymous, setIsAnonymous] = useState(false)
	const [vote, setVote] = useState(
		votes?.for != 'boy' && votes?.noOfVotes !== null ? votes?.noOfVotes : 1
	);

	const handlePayment = () => {
		dispatch(setVotes({ votes: { for: "girl", noOfVotes: vote, isAnonymous: isAnonymous } }))
		navigate("/play-game/payment")
	}

	const handleAcknowlegment = (e) => {
		setIsAnonymous(e.target.checked)
	};

	return (
		<Layout>
			<Helmet>
				<title>{`GRG - Voting Girl ${game?.contest?.id}`}</title>
			</Helmet>
			<Card>
				<div className="VotingGirl">
					<Link to={`/play-game?gameId=${game?.contest?.id}`}  className="backBtn"><img src="../assets/arrow-left.png" alt="<" /></Link>
					<h1>{game?.contest?.name}</h1>
					<div className="sub-heading">
						<strong>It’s a Girl! </strong>
						{/* <img src="../assets/girl.png" alt="" /> */}
					</div>
					<VoteButtons price={game?.contest?.min_cost_per_vote} vote={vote} setVote={setVote} gender={"girl"} />
					<ButtonDefault primary onClick={handlePayment}>Let’s Get This Party Started!</ButtonDefault>
					<Checkbox className="anonymousVote" onChange={handleAcknowlegment}>Do you want to cast your vote anonymously to the public? Only the parents of the game will be able to see your name.</Checkbox>
					<p className='terms'>By Continuing You Agree To Our <a href="https://www.villie.co/grg-terms" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></p>
				</div>
			</Card>
		</Layout>
	)
};

export default VotingGirl;