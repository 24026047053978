import React from "react";
import { Col, Row } from 'antd';
import "./steps.scss";

const Steps = ({onChange, currentStep = 1}) => {

    const dot = () => <div className="dot"></div>

    return (
        <>
            <Row className="stepsContainer">
                <Col span={9} className="step" onClick={() => onChange(1)}>
                    Parents Info {currentStep === 1 && dot()}
                </Col>
                <Col span={9} className="step" onClick={() => onChange(2)}>
                    Game Info {currentStep === 2 && dot()}
                </Col>
                <Col span={8} className="step">
                </Col>
            </Row>
        </>
  )
}

export default Steps;