import { PieChartResults } from "../PieChartResults/PieChartResults";

import './SidebarCompleted.scss';

const SidebarCompleted = () => {
  return (
    <div className="SidebarCompleted">
      <h2>Hooray!</h2>
      <div className="white-box one">
        <img src="../assets/blue-bear.svg" alt="bear" /> It’s A Boy
      </div>
      <h2>Winner</h2>
      <div className="white-box two">
        John Amanda
      </div>
      <h2>Stats</h2>
      <div className="col">
        <div className="pie">
          <PieChartResults />
        </div>
        <div className="white-box three">
          <label>Plays</label>
          <strong>12</strong>
          <span>8 Girls | 4 Boys</span>
        </div>
      </div>
      <div className="white-box four">
        <div>
          <label>Money Raised</label>
          <strong>$400</strong>
        </div>
        <div>
          <label>Money Raised</label>
          <strong>$400</strong>
        </div>
      </div>
    </div>
  );
};

export default SidebarCompleted;