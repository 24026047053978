import axios from 'axios';
import { resetAuthState } from '../redux/auth/authSlice';
import { resetUserState } from '../redux/user/userSlice';
import { app } from '../config';
import {store} from "../redux/store"

class BASEAPI {
    constructor(){
        axios.defaults.baseURL = app.API_BASE_URL;
        this.axiosInstance = axios.create({})
        this.axiosInstance.interceptors.request.use(function (config) {
            const state = store.getState()
            if (state.auth.accessToken) {
                config.headers["x-auth-token"] = state.auth.accessToken
            }
            return config;
        });

        const getSuccessResponseHandler = () => {
            return (response) => {
                return response;
            }
        }
        const getErrorResponseHandler = () => {
            return async (error) => {
                if (error?.config && error?.response && error?.response?.status === 401) {
                    store.dispatch(resetAuthState())
                    store.dispatch(resetUserState())
                }
                return Promise.reject({ ...error })
            }
        }
        this.axiosInstance.interceptors.response.use(
            getSuccessResponseHandler(),
            getErrorResponseHandler()
        )
    }
    
    get = (url, params= {}, headers= {}) => {
        return this.axiosInstance({
            method: 'GET',
            url: url,
            params: params,
            headers: headers
        })
    }
    
    post = (url, data, params= {}, headers= {}) => {
        return this.axiosInstance({
            method: 'POST',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }
    
    del = (url, data, params= {}, headers= {}) => {
        return this.axiosInstance({
            method: 'DELETE',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }
    
    put = (url, data, params= {}, headers= {}) => {
        return this.axiosInstance({
            method: 'PUT',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }

    patch = (url, data, params= {}, headers= {}) => {
        return this.axiosInstance({
            method: 'PATCH',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }
}

export const BaseAPI = new BASEAPI()
