
import './dashboard.scss';
import React, {useEffect, useState} from 'react';
import Layout from '../../../../../shared/components/layout';
import { Col, Row, Button, Alert } from 'antd';
import GameDetails from "../../../shared/components/gameDetails";
import PlaysAndDays from "../../../shared/components/playsAndDays";
import DonationDetails from "../../../shared/components/donationDetails";
import ShareGame from '../../../shared/components/shareGame';
import ShareBtns from '../../../shared/components/shareBtns';
import ShareUrl from '../../../../../shared/components/shareUrl';
import Card from "../../../../../shared/components/card";
import { useSelector, batch } from 'react-redux';
import { getFetchGame, getSeletedGame, getShareModalVisibility, setFetchGame, setSeletedGame, setShareModalVisibility } from '../../../../../shared/redux/game/gameSlice';
import { app } from '../../../../../shared/config';
import { useDispatch } from "react-redux";
import Spinner from '../../../../../shared/components/spinner';
import { useNavigate } from 'react-router-dom';
import { getUser } from "../../../../../shared/redux/user/userSlice";
import Modal from '../../../../../shared/components/modal';
import { BaseAPI } from '../../../../../shared/services/BaseAPI';
import {toast} from "react-toastify";
import moment from "moment";
import {
	WhatsappShareButton,
	FacebookShareButton,
	FacebookMessengerShareButton,
	TwitterShareButton,

	WhatsappIcon,
	FacebookIcon,
	FacebookMessengerIcon,
	TwitterIcon,
} from "react-share";
import { setDrawerVisibility, setDrawerComponent } from '../../../../../shared/redux/dynamicDrawer/dynamicDrawerSlice';
import NoData from '../../../../../shared/components/noData';
import { LoadingOutlined } from '@ant-design/icons';
import {Helmet} from "react-helmet";

const Dashboard = () => {
	const user = useSelector(getUser)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const game = useSelector(getSeletedGame)
	const fetchGame = useSelector(getFetchGame)
	const [isLoading, setIsLoading] = useState(false)
	const [updateDeleted, setUpdateDeleted] = useState(false)
	const isModalVisible = useSelector(getShareModalVisibility)
	const [isVisible, setIsVisible] = useState(false)
	const [isResumeLoading, setIsResumeLoading] = useState(false)
	const [fetchAgain, setFetchAgain] = useState(false)

	async function fetchData() {
		try{
			setIsLoading(true)
			const response = await BaseAPI.get(`game/mom/${game?.contest?.id}`)
			setIsLoading(false)

			if(response.status === 200){
				dispatch(setSeletedGame({ selectedGame: response?.data?.data }))
			}

		}
		catch(err){
			if(err?.response?.data?.message === "Game not found."){
				dispatch(setSeletedGame({ selectedGame: null}))
			}
			setIsLoading(false)
			batch(() => {
            	dispatch(setDrawerComponent({sidebarComponent: "myGames"}))
				dispatch(setDrawerVisibility({showSidebar: true}))
			})
		}
	}

	useEffect(() => {
		const fetchAllGames = async () => {
			const response = await BaseAPI.get("game/")
			if(response.status === 200){
				const game = response.data.data[0]
				if(game){
					dispatch(setSeletedGame({selectedGame: {"contest": game}}))
					dispatch(setShareModalVisibility({ showModal: game?.voting_ended ? false : true }))
					dispatch(setFetchGame({fetchGame: true}))
				}
			}
		}
		if(!game){
			fetchAllGames()
		}
	}, [])

	useEffect(() => {
		if (game?.contest?.id) {
			fetchData()
		}
	}, [updateDeleted, fetchAgain])

	useEffect(() => {
		if (fetchGame) {
			dispatch(setFetchGame({fetchGame: false}))
			fetchData()
		}
	}, [fetchGame])

	const handleResume = async () => {
		setIsResumeLoading(true)
		const payload = {
			contest_stage: game?.vote_counts?.length > 0 ? "Active Stage" : "Initial Stage",
			latest_stage_date: moment().format('YYYY-MM-DD')
		}

		try{
			const response = await BaseAPI.patch(`game/${game?.contest?.id}`, payload)
			setIsResumeLoading(false)
			if(response.status === 200){
				setFetchAgain(!fetchAgain)
				// return {success: true, data: response?.data?.data}
			}
		}
		catch(err){
			setIsResumeLoading(false)
			toast(err.response.data.message, {position: "top-center"})
		}
	}

	return (
		<>
			<Layout>
				<Helmet>
					<title>{`GRG - Dashboard ${game?.contest?.id}`}</title>
				</Helmet>
				<Card>
					{
						isLoading ?
							<Spinner />
							:
							!!game ? 
								<Row gutter={[20,20]}>
									{game?.contest?.contest_stage === "Pause Stage" && 
										<>
											<Alert message={
												<>This game is paused &nbsp;
													{
														isResumeLoading ?
														<LoadingOutlined />
														:
														<span 
															style={{textDecoration: "underline", cursor: "pointer"}} 
															onClick={handleResume}>Click here to resume
														</span>
													}
												</>
											} type="success" className="banner mb-30"/>
										</>
									}

									<Col xs={24} sm={24} md={14} lg={15} xl={15}>
										<GameDetails game={game} user={user} setUpdateDeleted={() => setUpdateDeleted(!updateDeleted)}/>
									</Col>
									<Col xs={24} sm={24} md={10} lg={9} xl={9}>
										<Row gutter={[16, 30]}>
											<Col span={24}><PlaysAndDays game={game} /></Col>
											<Col span={24}>
												<ShareBtns 
													game={game}
													url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`}
												/>
											</Col>
											{game?.contest?.contest_stage === "Pause Stage" && 
												<Col span={24}> 
													<Alert message={
														<>This game is paused &nbsp;
															{
																isResumeLoading ?
																<LoadingOutlined />
																:
																<span 
																	style={{textDecoration: "underline", cursor: "pointer"}} 
																	onClick={handleResume}>Click here to resume
																</span>
															}
														</>
													} type="success" className="banner"/>
												</Col>
											}
											<Col span={24}>
												<DonationDetails 
													title="Total Raised" 
													tooltipText="Winning Prize" 
													game={game}
												/>
											</Col>
											<Col span={24}>
												<ShareUrl text="The more you share, the more money you’ll raise!" 
												url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`}
												/>
											</Col>
											<Col span={24}><ShareGame game={game} fromDashBoard={true}/></Col>
										</Row>
									</Col>
								</Row>
								:
								<NoData description={
									<>No games yet! 
									<br />
										<Button 
											className='createGameBtn'
											onClick={() => navigate("/create-game")}
										>
											Create a Game
										</Button>
									</>}
								/>
					}
				</Card>
			</Layout>
			<Modal visible={isVisible} handleClose={() => {dispatch(setShareModalVisibility({ showModal: false })); setIsVisible(false)}}>
					<div>
						<p className="title">Share your game!</p>
						<div className="socialIconsBox">
							<WhatsappShareButton url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} title={`#genderrevealgame\nPlay my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
								<WhatsappIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
							</WhatsappShareButton>
							<FacebookShareButton url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} hashtag={"#genderrevealgame"} quote={`Play my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
								<FacebookIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
							</FacebookShareButton>
							<FacebookMessengerShareButton appId="1111927622279359" url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} quote={`Play my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
								<FacebookMessengerIcon size={"2.5rem"} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
							</FacebookMessengerShareButton>
							<TwitterShareButton url={`${app.APP_PUBLIC_URL}play-game?gameId=${game?.contest?.id}`} hashtag={"#genderrevealgame"} title={`#genderrevealgame\nPlay my Gender Reveal Game! Guess if we are having a boy or girl with a $${game?.contest?.min_cost_per_vote} play. One winner who guesses right will win a prize! Play now`}>
								<TwitterIcon size={"2.5rem"} round={true} />
							</TwitterShareButton>
						</div>
						
					</div>
			</Modal>
		</>
	)
}

export default Dashboard;