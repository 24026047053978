import React from "react";
import PropTypes from "prop-types";

import "./TooltipInput.scss";

const TooltipInput = ({ text }) => {
  return (
    <div className="tooltip-icon">
      <img src="../assets/info-circle.png" alt="icon-info"/>
      <img src="../assets/polygon.png" alt=">"/>
      <div className="tooltip">{text}</div>
    </div>
  );
};

TooltipInput.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TooltipInput;
