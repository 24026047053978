import "./styles.scss"
import React from 'react';
import { Form, Select  } from 'antd';

const CustomSelect = ({label, name, placeholder, options, required=false, errorMessage, onChange}) => {
    return(
        <Form.Item 
            label={label} 
            className="formItem"
            name={name}
            rules={required && [{ required: true, message: errorMessage }]}
        >
            <Select placeholder={placeholder} onChange={onChange}>
                {
                    options?.map((o,i) => (
                        <Select.Option key={i} value={o?.value}>{o?.label}</Select.Option>
                    ))
                }
            </Select>
        </Form.Item>
    );
}

export default CustomSelect;